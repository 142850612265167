/**
 * CC DCRP API
 * Connected Claims Damaged Container Recovery API's.
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { WorkOrderAndLineItemsDtoIEnumerableResponse } from '../../models/workOrderAndLineItemsDtoIEnumerableResponse';
import { environment } from '../../../../environments/environment';
import { WorkOrderDtoIEnumerableResponse } from '../../models/workOrderDtoIEnumerableResponse';
import { CustomHttpUrlEncodingCodec } from '../encoder';

@Injectable()
export class WorkOrderAndLineItemsService {
  protected basePath = environment.dcrp_api_base_url;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {}

  /**
   * Get line items for the work order based on case number.
   *  Sample request:         Get work-order/{caseNumber}/line-items    Response containing IEnumerable of WorkOrderAndLineItemsDto
   * @param caseNumber Recovery case number
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public workOrderCaseNumberLineItemsGet(
    caseNumber: string,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<WorkOrderAndLineItemsDtoIEnumerableResponse>;
  public workOrderCaseNumberLineItemsGet(
    caseNumber: string,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<WorkOrderAndLineItemsDtoIEnumerableResponse>>;
  public workOrderCaseNumberLineItemsGet(
    caseNumber: string,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<WorkOrderAndLineItemsDtoIEnumerableResponse>>;
  public workOrderCaseNumberLineItemsGet(
    caseNumber: string,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (caseNumber === null || caseNumber === undefined) {
      throw new Error(
        'Required parameter caseNumber was null or undefined when calling workOrderCaseNumberLineItemsGet.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<WorkOrderAndLineItemsDtoIEnumerableResponse>(
      'get',
      `${this.basePath}/work-order/${encodeURIComponent(String(caseNumber))}/line-items`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get line items for the work order based on work order number, container number, work order from and to date.
   * @param workOrderNumber 
   * @param containerNumber 
   * @param fromDateTime 
   * @param toDateTime 
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public workOrdersGet(
    workOrderNumber?: number,
    containerNumber?: string,
    fromDateTime?: Date,
    toDateTime?: Date,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<WorkOrderDtoIEnumerableResponse>;
  public workOrdersGet(
    workOrderNumber?: number,
    containerNumber?: string,
    fromDateTime?: Date,
    toDateTime?: Date,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<WorkOrderDtoIEnumerableResponse>>;
  public workOrdersGet(
    workOrderNumber?: number,
    containerNumber?: string,
    fromDateTime?: Date,
    toDateTime?: Date,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<WorkOrderDtoIEnumerableResponse>>;
  public workOrdersGet(
    workOrderNumber?: number,
    containerNumber?: string,
    fromDateTime?: Date,
    toDateTime?: Date,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (workOrderNumber !== undefined && workOrderNumber !== null) {
      queryParameters = queryParameters.set(
        'WorkOrderNumber',
        <any>workOrderNumber
      );
    }
    if (containerNumber !== undefined && containerNumber !== null) {
      queryParameters = queryParameters.set(
        'ContainerNumber',
        <any>containerNumber
      );
    }
    if (fromDateTime !== undefined && fromDateTime !== null) {
      queryParameters = queryParameters.set(
        'DateRange.FromDateTime',
        <any>fromDateTime.toISOString()
      );
    }
    if (toDateTime !== undefined && toDateTime !== null) {
      queryParameters = queryParameters.set(
        'DateRange.ToDateTime',
        <any>toDateTime.toISOString()
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }    

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<WorkOrderAndLineItemsDtoIEnumerableResponse>(
      'get',
      `${this.basePath}/work-orders`,
      {
        params: queryParameters,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
