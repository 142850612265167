<ng-container *ngIf="customerRecoveryData$ | async">
  <div
    class="liability-details"
    *ngIf="manualEstimates && manualEstimates.length > 0">
    <app-grid
      [schema]="gridSchema"
      [data]="costData"
      [footerData]="costFooter"
      [footerSchema]="footerSchema"
      [showPanel]="false"
      [showHeader]="true"
      [showFooter]="true"
      [showRowSelector]="false"
      [showPagination]="false"></app-grid>
  </div>
</ng-container>
