import { InvoiceStatusEnum } from '../models/invoiceStatusEnum';
import { KeyValue } from '../models/keyValue';

/**
 * Values to be fetched from API
 * API needs to provide -
 * 1. value: number
 * 2. label: string
 * 3. isCompleted: boolean
 * These values will be mapped to ClaimStatusMaster entity
 */
export const statusTypeMaster = [
  { value: 1, label: 'New', isCompleted: false },
  { value: 2, label: 'Damage Estimation', isCompleted: false },
  { value: 3, label: 'Liability', isCompleted: false },
  { value: 4, label: 'Invoice', isCompleted: false },
  { value: 5, label: 'Complete', isCompleted: true, hide: true },
  { value: 6, label: 'Closed', isCompleted: true },
  { value: 7, label: 'Auto Closed', isCompleted: true },
];

export const aboveCoverType = [
  { value: '0-10', label: '$0-$10' },
  { value: '10-20', label: '$10-$20' },
  { value: '20-50', label: '$20-$50' },
  { value: '50-100', label: '$50-$100' },
  { value: '100-200', label: '$100-$200' },
  { value: '200-500', label: '$200-$500' },
  { value: '500-1000', label: '$500-$1000' },
  { value: '1000-99999', label: '> $1000' },
];

export const workOrderStatusType = [
  { value: 0, label: 'Draft' },
  { value: 100, label: 'Rejected' },
  { value: 130, label: 'Rejected to Approver' },
  { value: 140, label: 'Rejected to Specialist' },
  { value: 150, label: 'Total Loss' },
  { value: 200, label: 'Pending Approver Approval' },
  { value: 310, label: 'Suspended to Approver' },
  { value: 320, label: 'Suspended to App/fwd to Specialist' },
  { value: 330, label: 'Suspend to EMR Specialist' },
  { value: 340, label: 'Suspend to CENEQULOS/CPH' },
  { value: 390, label: 'Approved Estimate' },
  { value: 400, label: 'Completed' },
  { value: 500, label: 'RRIS Transmitted' },
  { value: 550, label: 'RRIS Rejected' },
  { value: 600, label: 'RRIS Accepted' },
  { value: 800, label: 'Paid' },
  { value: 900, label: 'Processed' },
  { value: 9999, label: 'Deleted' },
];

export const workOrderMode = [
  { value: '02', label: '02' },
  { value: '03', label: '03' },
  { value: '04', label: '04' },
  { value: '05', label: '05' },
  { value: '22', label: '22' },
  { value: '23', label: '23' },
  { value: '26', label: '26' },
  { value: '31', label: '31' },
  { value: '33', label: '33' },
  { value: '40', label: '40' },
  { value: '41', label: '41' },
  { value: '43', label: '43' },
  { value: '44', label: '44' },
  { value: '45', label: '45' },
  { value: '46', label: '46' },
  { value: '48', label: '48' },
  { value: '49', label: '49' },
  { value: '70', label: '70' },
  { value: '82', label: '82' },
  { value: '83', label: '83' },
  { value: '85', label: '85' },
];

// TODO: This needs to be removed and proper API call to load master data needs to be implemented.
export const CancelReasonsList = [
  'No Dert case available',
  'Insufficient image proofs',
  'Time elapsed',
  'Cost too low for recovery',
  'Incorrect booking',
  'Incorrect Cp Identification',
  'Others',
  'Cabotage',
  'W&T',
  'NOT A 3P',
  'Cannot confidently identify 3p',
  'Handling - below threshold',
  'MAC Non recoverable',
  'Coverage fee paid',
  'CP Available for BOL and not booking no.',
];

/**
 * TODO: We need to save this default filter state in the user preference API. This is the by-default filters we show for every user.
 */
export const defaultFilters: KeyValue[] = [
  {
    key: 'importReturn',
    value: JSON.stringify(['yes']),
  },
  {
    key: 'claimStatusId',
    value: JSON.stringify([1]),
  },
];

export function getStatusTextFromStatusCode(
  invoiceStatusCode: InvoiceStatusEnum | undefined
) {
  switch (invoiceStatusCode) {
    case InvoiceStatusEnum.CancellationSubmitted:
      return 'Cancellation request submitted to NFTP';
    case InvoiceStatusEnum.InvoiceCancellationRequestFailed:
      return 'Cancellation request failed';
    case InvoiceStatusEnum.InvoiceCancellationRequested:
      return 'Cancellation request submitted to NFTP';
    case InvoiceStatusEnum.InvoiceCancelled:
      return 'Invoice cancelled';
    case InvoiceStatusEnum.InvoiceNotIssued:
      return 'Invoice could not be issued';
    case InvoiceStatusEnum.InvoiceIssued:
      return 'Invoice issued';
    case InvoiceStatusEnum.InvoiceRequestFailed:
      return 'Invoice request could not be sent to NFTP';
    case InvoiceStatusEnum.InvoiceRequested:
      return 'Invoice request submitted to NFTP';
    case InvoiceStatusEnum.InvoiceSubmitted:
      return 'Invoice request submitted to NFTP';
    case InvoiceStatusEnum.PaymentReceived:
      return 'Payment received';
    default:
      return '-';
  }
}

export enum workflowStages {
  'New' = 1,
  'DamageEstimation' = 2,
  'Liability' = 3,
  'Invoice' = 4,
  'Complete' = 5,
}

export enum CaseTypeEnum {
  'CaseWithWorkOrder' = 1,
  'CaseWithOutWorkOrder' = 2,
}

export enum Components {
  'AttachmentComponent' = 'AttachmentComponent',
  'DamageDetailsComponent' = 'DamageDetailsComponent',
  'LiablePartyDetailsComponent' = 'LiablePartyDetailsComponent',
  'OverviewComponent' = 'OverviewComponent',
  'TabComponent' = 'TabComponent',
  'InvoiceLetterComponent' = 'InvoiceLetterComponent',
  'LiabilityLetterComponent' = 'LiabilityLetterComponent',
  'LiabilityLettersLogComponent' = 'LiabilityLettersLogComponent',
  'CustomerRecoveryDataManager' = 'CustomerRecoveryDataManager',
}
