import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  CellClickEvent,
  DropDownOption,
  GridCellData,
  GridColumnSchema,
  GridComponent,
  GridRowData,
  PanelComponent,
  TemplateModel,
  NoDataComponent,
  CustomFormValidators,
  TableSkeletonLoaderComponent,
} from '@maersk-global/angular-shared-library';
import { Router } from '@angular/router';
import {
  catchError,
  firstValueFrom,
  map,
  Observable,
  of,
  shareReplay,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { CaseService } from '../../../common/services/case/case.service';
import * as gridSchema from './grid-schema';
import { WorkOrder } from '../../../common/models/workOrder';
import * as template from '../../../../assets/json/dcrp-customer-workflow.json';
import { CountryDto } from '../../../common/models/countryDto';
import { environment } from '../../../../environments/environment';
import { SharedDataService } from '../../../shared-data-service';

@Component({
  selector: 'create-manual-case',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    GridComponent,
    CommonModule,
    NoDataComponent,
    PanelComponent,
    TableSkeletonLoaderComponent,
  ],
  templateUrl: './create-manual-case.component.html',
  styleUrl: './create-manual-case.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CreateManualCaseComponent {
  @Output() onModalCancel = new EventEmitter<boolean>();
  userId: string = sessionStorage.getItem('userId') || '';
  searchToDateUTC = new Date();
  searchFromDateUTC = new Date();
  selectedWorkOrder: number | undefined;
  containerNumberSubject$$: Subject<string> = new Subject<string>();
  searchedContainerNumber?: string;
  caseCreationWithoutWorkOrder = false;
  areWorkOrdersLoading = false;
  workOrderSearchInterval = environment.searchIntervalForManualCase;

  get containerNumberInput() {
    return this.newCaseForm.controls['containerNumberInput'];
  }

  get disableCaseCreation() {
    return this.caseCreationWithoutWorkOrder ? false : !this.selectedWorkOrder;
  }

  containerMovesItem: TemplateModel = template.stages[0].items[0].items[0].items
    .find((item) => item.name === 'bookingInformation')
    ?.items?.find((item) => item.name === 'containerMoves') as TemplateModel;

  newCaseForm: FormGroup = new FormGroup({
    containerNumberInput: new FormControl('', {
      validators: [
        CustomFormValidators.ContainerNumberValidator,
        CustomFormValidators.AlphanumericValidator,
      ],
      updateOn: 'change',
    }),
  });

  //Setting schema for work order grid
  woGridSchema = gridSchema.default.workOrderSchema.map((schema) => {
    if (schema.isClickable) {
      schema = {
        ...schema,
        onClick: this.onGridColumnClicked.bind(this),
      } as GridColumnSchema;
    }
    return schema;
  });

  //Populating list countries for dropdown
  countries$: Observable<DropDownOption[]> =
    this._sharedDataService.countries$.pipe(
      map((response: CountryDto[] | undefined) => {
        if (!response || response.length < 1) return [];
        return response.map((country) => ({
          value: country.code,
          label: country.name,
          name: country.name,
          sublabel: country.code,
        }));
      }),
      shareReplay(1)
    );

  //Getting list of work orders based on searched container
  workOrders$: Observable<WorkOrder[]> = this.containerNumberSubject$$
    .asObservable()
    .pipe(
      switchMap((containerNumber) => {
        return this._caseService
          .customerRecoveryClaimsContainerNumberWorkOrdersGet(
            containerNumber,
            this.searchFromDateUTC,
            this.searchToDateUTC,
            '1.0'
          )
          .pipe(
            map(
              (response) =>
                response.data?.sort(
                  (wo1, wo2) =>
                    this._sharedDataService.getTimeInMilliseconds(
                      wo2.workOrderCreatedDateTime
                    ) -
                    this._sharedDataService.getTimeInMilliseconds(
                      wo1.workOrderCreatedDateTime
                    )
                ) ?? []
            ),
            catchError((error) => {
              if (error?.error?.detailedErrors?.errorCode == 4002) {
                this.containerNumberInput.setErrors({
                  containerNumberInValid: true,
                });
              }
              return of([]);
            })
          );
      }),
      shareReplay(1),
      tap(() => {
        this.areWorkOrdersLoading = false;
        this.caseCreationWithoutWorkOrder = false;
      })
    );

  //Populating work order grid data
  workOrderGridData$: Observable<GridRowData[]> = this.workOrders$.pipe(
    map((workOrders) => {
      this.selectedWorkOrder = workOrders.find(
        (wo) => (wo.recoveryCaseNumber ?? '') == ''
      )?.workOrderNumber;
      return workOrders.reduce(
        (groupedWorkOrders: GridRowData[], currentWorkOrder: WorkOrder) => {
          const currentGridRow =
            this.generateGridCellDataForAllRowCells(currentWorkOrder);

          const matchingGroupIndex = groupedWorkOrders.findIndex(
            (x) => x.row['groupId'].value === currentWorkOrder.groupId
          );

          //If first record of the grid or If work order does not belong to the any of the previous groups, create new row.
          if (matchingGroupIndex === -1) {
            groupedWorkOrders.push({
              row: currentGridRow,
              isMasterRow: false,
              childRows: [],
              showChildRowData: false,
              isRowSelected:
                currentWorkOrder.workOrderNumber === this.selectedWorkOrder,
              hideRowSelector: !!currentWorkOrder.recoveryCaseNumber,
            });
            return groupedWorkOrders;
          }

          //If record belongs to the previous group, append it.
          if (matchingGroupIndex > -1) {
            groupedWorkOrders[matchingGroupIndex].childRows?.push(
              currentGridRow
            );
            groupedWorkOrders[matchingGroupIndex].isMasterRow = true;
            return groupedWorkOrders;
          }
          return groupedWorkOrders;
        },
        [] as GridRowData[]
      );
    })
  );

  constructor(
    private _caseService: CaseService,
    private _router: Router,
    private _sharedDataService: SharedDataService
  ) {
    //Setting from date for searching work order data
    this.searchFromDateUTC.setUTCDate(
      this.searchToDateUTC.getUTCDate() - this.workOrderSearchInterval
    );
    this.searchFromDateUTC = this._sharedDataService.changeUtcTime(
      this.searchFromDateUTC
    );
  }

  /**
   * Creates recovery case based on user inputs
   */
  async createCase() {
    const request: WorkOrder | undefined = !this.caseCreationWithoutWorkOrder
      ? await this.generateCreateCaseRequestFromWorkOrder()
      : ({
          assignedDateTime: new Date(),
          assignedTo: this.userId,
          containerNumber: this.searchedContainerNumber,
          userId: this.userId,
          cpCoverageCurrency: 'USD',
        } as WorkOrder);

    const response = await firstValueFrom(
      this._caseService.customerRecoveryClaimsRecoveryCasePost(request)
    );

    this.navigateToRecoveryCaseWorkFlow(
      response.data?.caseNumber ?? '',
      this.containerNumberInput.value
    );
  }

  /**
   * Generates case creation request based on selected work order from grid
   * @returns Case creation request
   */
  async generateCreateCaseRequestFromWorkOrder() {
    const workOrder = await firstValueFrom(this.workOrders$);
    const request = workOrder.find(
      (workOrder) => workOrder.workOrderNumber === this.selectedWorkOrder
    )!;
    request.assignedDateTime = new Date();
    request.assignedTo = this.userId;
    request.userId = this.userId;
    return request;
  }

  /**
   * Searches work order data based on entered container number
   */
  searchContainerNumber() {
    this.searchedContainerNumber = (
      this.containerNumberInput?.value as string
    ).toUpperCase();

    //Setting form control values
    this.containerNumberInput.setValue(this.searchedContainerNumber);

    //Setting flags
    this.areWorkOrdersLoading = true;
    this.caseCreationWithoutWorkOrder = false;

    //Triggering search
    this.containerNumberSubject$$.next(this.searchedContainerNumber);
  }

  /**
   * This method does a transformation on the api response object and creates grid row object from it.
   * @param claim - Work order entity received from Web API response
   * @returns Grid row object
   */
  private generateGridCellDataForAllRowCells(claim: WorkOrder): {
    [key: string]: GridCellData;
  } {
    const claimKeyValue = claim as unknown as {
      [key: string]: unknown;
    };
    const gridRowObject: { [key: string]: GridCellData } = {};

    Object.keys(claim).map((key) => {
      gridRowObject[key] = {
        value: claimKeyValue[key],
      } as GridCellData;
    });
    return gridRowObject;
  }

  /**
   * Handling modal close event
   */
  onModelClose(_: any) {
    this.newCaseForm.reset();
    this.onModalCancel.emit(true);
  }

  /**
   * Sets selected work order number based on selected row
   * @param selectedRow - Selected work order row from grid
   */
  woSelectionChanged(selectedRow: { [key: string]: unknown }[]) {
    if (!selectedRow || selectedRow.length == 0 || selectedRow.length > 1) {
      return;
    }

    if (selectedRow && selectedRow.length == 1) {
      this.selectedWorkOrder = (
        selectedRow[0]['workOrderNumber'] as GridCellData
      ).value as number;
    }
  }

  /**
   * This method is triggered when grid column click event is triggered. We are writing various actions on this.
   * @param event - Grid Column Cell Clicked Event
   * @returns void
   */
  onGridColumnClicked(cellData: CellClickEvent) {
    if (!cellData?.column) return;
    const { column, rowData } = cellData;
    if (column === 'recoveryCaseNumber') {
      this.navigateToRecoveryCaseWorkFlow(
        rowData!['recoveryCaseNumber'].value as string,
        rowData!['containerNumber'].value as string
      );
    }
  }

  createCaseWithoutWorkOrderCheckBoxChange() {
    this.caseCreationWithoutWorkOrder = !this.caseCreationWithoutWorkOrder;
  }

  /**
   * Navigates to case workflow page based on case number and container number
   * @param caseNumber - Case number for which workflow needs to be displayed
   * @param containerNumber - Container number for which workflow needs to be displayed
   */
  private navigateToRecoveryCaseWorkFlow(
    caseNumber: string,
    containerNumber: string
  ) {
    this._router.navigate(['customer-recovery/workflow/'], {
      queryParams: {
        caseNumber: caseNumber,
        containerNumber: containerNumber,
      },
    });
  }
}
