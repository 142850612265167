import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { WorkOrderListComponent } from '../../custom-workflow/work-order-list/work-order-list.component';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { AttachmentComponent } from '../../custom-workflow/attachment/attachment.component';
import { OverviewComponent } from '../../custom-workflow/overview/overview.component';
import { CustomerRecoveryClaimService } from '../../../common/services/customer-recovery/customer-recovery-claim.service';
import {
  Observable,
  catchError,
  firstValueFrom,
  lastValueFrom,
  map,
  of,
  tap,
} from 'rxjs';
import { LiabilityDetailsComponent } from '../../custom-workflow/liability-details/liability-details.component';
import { DamageDetailsComponent } from '../../custom-workflow/damage-details/damage-details.component';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { CaseDetailsDto } from '../../../common/models/caseDetailsDto';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { Router } from '@angular/router';
import { ActivityLogComponent } from '../../custom-workflow/activity-log/activity-log.component';
import {
  GridCellData,
  Loader,
  LoaderService,
  PageSkeletonLoaderComponent,
} from '@maersk-global/angular-shared-library';
import { WorkOrderAndLineItemsDto } from '../../../common/models/workOrderAndLineItemsDto';
import { ClaimStatusRequest } from '../../../common/models/claim-status-request';
import { CompletedComponent } from '../../custom-workflow/completed/completed.component';
import { CaseAssignmentStatusResponse } from '../../../common/models/caseAssignmentStatusResponse';
import {
  CancelReasonsList,
  workflowStages,
} from '../../../common/constants/temporary-constant';
import { ClaimStatusResponse } from '../../../common/models/claim-status-response';
import { StepperComponent } from '../../custom-workflow/stepper/stepper.component';
import { recoveryTab } from '../../../common/enum/recovery-tab';
import { SharedVendorRecoveryCaseService } from '../shared-vendor-recovery-case.service';
import { SharedDataService } from '../../../shared-data-service';
import * as template from '../../../../assets/json/dcrp-vendor-workflow.json';
import { VendorRecoverableCostComponent } from '../../custom-workflow/vendor-recoverable-cost/vendor-recoverable-cost.component';
@Component({
  selector: 'vendor-recovery-orchestrator',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    WorkOrderListComponent,
    StepperComponent,
    AttachmentComponent,
    OverviewComponent,
    LiabilityDetailsComponent,
    DamageDetailsComponent,
    ActivityLogComponent,
    CompletedComponent,
    PageSkeletonLoaderComponent,
    VendorRecoverableCostComponent,
  ],
  templateUrl: './vendor-recovery-orchestrator.component.html',
  styleUrl: './vendor-recovery-orchestrator.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
})
export class VendorRecoveryOrchestratorComponent {
  stages: TemplateModel[] | undefined;
  currentStageId: number = 1;
  caseDetails!: CustomerRecoveryCaseDto;
  apiVersion: string = '1.0';
  caseNumber: string = '';
  currentTabIndex: number = 0;
  tabItems?: TemplateModel[];
  nextStageId: number = 0;
  userId: string = sessionStorage.getItem('userId') || '';
  loadTab: boolean = true;
  closeCase: boolean = false;
  cancelReasons: string[] = CancelReasonsList;
  currentStageItem: TemplateModel[] = [];
  closeCaseForm: FormGroup = new FormGroup({
    closeReason: new FormControl('', { updateOn: 'change' }),
    comments: new FormControl('', { updateOn: 'change' }),
  });
  closedStatusId: number = 6;
  isSaveOperationInProgress: boolean = false;
  OTHERS: string = 'Others';
  closeReason: string = '';
  workOrders: WorkOrderAndLineItemsDto[] = [];
  finalRecoverableCostUsd = 0.0;
  finalRecoverableCostLocal = 0.0;

  tabName$: Observable<string> =
    this._sharedRecoveryCaseService.currentTabIndex$.pipe(
      map((index) => recoveryTab[index])
    );

  constructor(
    private _customerRecoveryService: CustomerRecoveryClaimService,
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _loader: LoaderService,
    private _router: Router,
    private _sharedDataService: SharedDataService,
    private _vendorRecoverySharedService: SharedVendorRecoveryCaseService
  ) {}

  disableForm$: Observable<boolean> =
    this._vendorRecoverySharedService.disableForm$;
  enableCloseBtn$: Observable<boolean> =
    this._vendorRecoverySharedService.enableCloseBtn$;
  enableNextBtn$: Observable<boolean> =
    this._vendorRecoverySharedService.enableNextBtn$;
  hideNextBtn$: Observable<boolean> =
    this._vendorRecoverySharedService.hideNextBtn$;

  vendorRecoveryData$ = this._sharedRecoveryCaseService.recoveryCaseData$.pipe(
    tap((recoveryData) => {
      //updating Current StageId on initial load only once.
      this.currentStageId =
        recoveryData?.recoveryStatusId == 6 ||
        recoveryData?.recoveryStatusId == 7
          ? !recoveryData.workFlowStage
            ? 1
            : workflowStages[recoveryData?.workFlowStage]
          : recoveryData?.recoveryStatusId ?? 1;
      this._vendorRecoverySharedService.updateCurrentStageId(
        this.currentStageId as number
      );

      this.caseDetails = recoveryData!;
      this.caseNumber = recoveryData?.recoveryCaseNumber ?? '';
      this.loadTabInitialData(this.currentStageId);
    })
  );

  currentStageId$ = this._sharedRecoveryCaseService.currentStageId$.pipe(
    tap((newStageId) => {
      if (newStageId && this.currentStageId != newStageId)
        this.loadTabInitialData(newStageId);
    })
  );

  onNavigateBackToCaseList() {
    this._router.navigate(['vendor-recovery']);
  }

  loadTabInitialData(stageId: number): void {
    this.bindVendorRecoveryTemplate();
    this.currentTabIndex = 0;
    const caseStageTemplate = this.stages?.filter(
      (i) => i.id === stageId
    )[0] as TemplateModel;
    this.currentStageItem =
      caseStageTemplate.items?.filter((i) => i.name === 'tab')[0].items ?? [];
    if (this.currentStageItem)
      this.tabItems = this.currentStageItem[this.currentTabIndex].items;
    const currentStageSequence =
      this.stages?.filter((i) => i.id == stageId)[0].sequence ?? 0;
    if (
      this.stages &&
      (this.stages[this.stages.length - 1].sequence ?? 0) > currentStageSequence
    )
      this.nextStageId =
        this.stages?.filter((i) => i.sequence == currentStageSequence + 1)[0]
          .id ?? 1;
  }

  async onNextClick() {
    const disableForm = await firstValueFrom(
      this._vendorRecoverySharedService.disableForm$
    );
    if (disableForm) {
      this.loadNextStage();
      return;
    }

    this.isSaveOperationInProgress = true;
    this._loader.showLoader({
      label: 'Loading',
      size: 'medium',
    } as Loader);

    // Adding this try catch to make sure we will always have the next button enabled if save operation fails.
    // TODO: The error handling needs to be re-looked for all the API calls.
    try {
      if (this.currentStageId === 1) {
        await this.stageOverviewSaveAndSubmit();
      }
    } catch (error) {
      console.error('Error occurred while saving data');
    } finally {
      this.isSaveOperationInProgress = false;
      this._loader.hideLoader();
    }
  }

  async stageOverviewSaveAndSubmit() {
    //overview details update
    const vendorRecoveryCaseDto = await firstValueFrom(
      this._sharedRecoveryCaseService.recoveryCaseData$
    );

    if (!vendorRecoveryCaseDto) return;

    await this.assignCurrentCaseToTheCurrentUser(vendorRecoveryCaseDto);

    if (this.caseDetails?.recoveryCaseNumber)
      vendorRecoveryCaseDto.recoveryCaseNumber =
        this.caseDetails?.recoveryCaseNumber;
    vendorRecoveryCaseDto.workFlowVersion = 1; //temp version hard coded. When we get template this should be removed.

    this._sharedRecoveryCaseService.reloadRecoveryCaseData();

    this.loadNextStage();
  }

  /**
   * Assign this case to the current user if it is not already assigned.
   * @param vendorRecoveryCaseDto CustomerRecoveryCaseDto
   * @returns Promise
   */
  async assignCurrentCaseToTheCurrentUser(
    vendorRecoveryCaseDto: CustomerRecoveryCaseDto
  ) {
    if (
      vendorRecoveryCaseDto.assignedToUID === this.userId ||
      !vendorRecoveryCaseDto.recoveryCaseNumber ||
      !this.caseDetails
    )
      return;

    const cases = [];
    const groupedCases = await lastValueFrom(
      this._customerRecoveryService.customerRecoveryClaimsListByGroupIdPost(
        [vendorRecoveryCaseDto.groupId ?? ''],
        this.apiVersion
      )
    );
    if (groupedCases && !!groupedCases.customerRecoveryDetails) {
      cases.push(
        ...groupedCases.customerRecoveryDetails.map(
          (recoveryCase: CustomerRecoveryCaseDto) =>
            recoveryCase.recoveryCaseNumber
        )
      );
    }
    await lastValueFrom(
      this._customerRecoveryService
        .customerRecoveryClaimsCaseAssignmentPost(
          {
            assignedToUid: this.userId,
            recoveryCaseNumbers: cases,
            updatedBy: this.userId,
            updatedDttm: new Date(),
          },
          this.apiVersion
        )
        .pipe(
          tap((response: CaseAssignmentStatusResponse) => {
            if (
              response &&
              response.status?.statusCode == 200 &&
              response.caseAssignmentStatus?.isUpdateSuccessful &&
              this.caseDetails
            ) {
              this.caseDetails.assignedToUID = this.userId;
            }
          }),
          catchError(() => {
            //returning true as we need to proceed other execution flow.
            return of(true);
          })
        )
    );
  }

  async claimStatusUpdate(
    request: ClaimStatusRequest,
    close: boolean,
    newCase: boolean
  ) {
    const closedResponse: ClaimStatusResponse = await lastValueFrom(
      this._customerRecoveryService.customerRecoveryClaimsClaimStatusCaseNumberPut(
        request,
        this.caseNumber,
        this.apiVersion
      )
    );
    if (!closedResponse.claimStatusChange.isClaimStatusChangeSuccessful) return;
    this.caseDetails.recoveryStatusId = request.claimStatusId;
    this.caseDetails.comments = request.comment;
    this.caseDetails.cancellationReason = request.cancelationReason;
    this.caseDetails.workFlowStage = request.workFlowStage;
    this._sharedRecoveryCaseService.reloadRecoveryCaseData();
    if (close) {
      this._sharedRecoveryCaseService.updateTabSelected(1);
      if (newCase) {
        //update work_flow_version for new case --
        this.updateWorkFlowVersionForNewCase();
      }
      this._router.navigate(['vendor-recovery']);
    }
  }
  updateWorkFlowVersionForNewCase() {
    if (!this.caseDetails) return;
    this.caseDetails.workFlowVersion = 1;
    const caseRequest = {
      customerRecoveryCaseDto: this.caseDetails,
    } as CaseDetailsDto;
    firstValueFrom(
      this._customerRecoveryService.customerRecoveryClaimsUpdatePost(
        caseRequest,
        this.apiVersion
      )
    );
  }

  onTabChange(event: any) {
    this.currentTabIndex = event.detail;
    this.tabItems = this.currentStageItem[event.detail].items;
  }

  onBackClick() {
    this._loader.showLoader({
      label: 'Loading',
      size: 'medium',
    } as Loader);
    this.loadTab = false;
    const currentStageSequence =
      this.stages?.filter((i) => i.id == this.currentStageId)[0].sequence ?? 0;
    const prevStage = this.stages?.filter(
      (i) => i.sequence == currentStageSequence - 1
    )[0];

    if (prevStage) {
      this.currentStageItem[this.currentTabIndex].items?.forEach(
        (i) => (i.loaded = false)
      );
      if (prevStage.id)
        this._vendorRecoverySharedService.updateCurrentStageId(prevStage.id);
    }
    this.loadTab = true;
    this._loader.hideLoader();
  }

  async updateRecoveryClaimId(request: ClaimStatusRequest) {
    const statusResponse = await lastValueFrom(
      this._customerRecoveryService
        .customerRecoveryClaimsClaimStatusCaseNumberPut(
          request,
          this.caseNumber,
          this.apiVersion
        )
        .pipe(map((response) => response))
    );
    if (
      statusResponse.claimStatusChange &&
      statusResponse.claimStatusChange.isClaimStatusChangeSuccessful
    ) {
      this.caseDetails.workFlowStage =
        this._vendorRecoverySharedService.getWorkFlowStageById(
          this.nextStageId
        );
      this.caseDetails.recoveryStatusId = request.claimStatusId;
      this._sharedRecoveryCaseService.reloadRecoveryCaseData();
      this.loadNextStage();
    }
  }

  loadNextStage() {
    const newStage = this.stages?.filter(
      (i: any) => i.id == this.nextStageId
    )[0];
    if (newStage) {
      this.currentStageItem[this.currentTabIndex].items?.forEach(
        (i) => (i.loaded = false)
      );
      this._vendorRecoverySharedService.updateCurrentStageId(this.nextStageId);
    }
  }

  stageLoad($event: any) {
    if (!($event as boolean)) return;
  }

  bindVendorRecoveryTemplate() {
    //local template to be used as anchor integration is not required for vendor recovery
    const wfTemplate = (template as any).default;
    let locTem = JSON.parse(JSON.stringify(wfTemplate));
    this.stages = locTem.stages;
  }

  async closingCase() {
    const { closeReason, comments } = this.closeCaseForm.value;
    const closeRequest = {
      userName: this.userId,
      cancelationReason: closeReason,
      comment:
        closeReason != this.OTHERS
          ? this.caseDetails.comments ?? ''
          : comments ?? '',
      claimStatusId: this.closedStatusId,
      updatedDttm: new Date(),
      workFlowStage: this._vendorRecoverySharedService.getWorkFlowStageById(
        this.caseDetails.recoveryStatusId ?? 1
      ),
    };
    this.claimStatusUpdate(
      closeRequest,
      true,
      this.caseDetails.recoveryStatusId === 1 || false
    );
  }

  /**
   * Calculates recoverable cost based on selected line items
   * @param recoverableLineItems Work order line items selected for recovery
   */
  calculateFinalRecoverableCostUsd(
    recoverableLineItems: { [key: string]: unknown }[]
  ) {
    this.finalRecoverableCostUsd = 0.0;
    this.finalRecoverableCostLocal = 0.0;
    recoverableLineItems.forEach((row) => {
      this.finalRecoverableCostUsd += (
        row['repairTotalCostUSD'] as GridCellData
      ).value as number;
      this.finalRecoverableCostLocal += (
        row['repairTotalCostLocalCurrency'] as GridCellData
      ).value as number;
    });
    this.finalRecoverableCostUsd = this._sharedDataService.formatDecimal(
      this.finalRecoverableCostUsd
    );
    this.finalRecoverableCostLocal = this._sharedDataService.formatDecimal(
      this.finalRecoverableCostLocal
    );

    console.log(this.finalRecoverableCostUsd);
    console.log(this.finalRecoverableCostLocal);
  }
}
