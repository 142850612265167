import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IMenuProvider, Menu } from '@maersk-global/angular-shared-library';

@Injectable()
export class SideNavMenuService implements IMenuProvider {
  constructor(private _router: Router) {} // Inject User Auth Service to figure out which menus are accessible to the logged in user

  getMenu(): Menu[] {
    return [
      {
        displayName: 'Dashboard',
        icon: 'layout-2x2',
        showLabel: true,
        disabled: true,
      },
      {
        displayName: 'Customer',
        icon: 'user',
        showLabel: true,
        isSelected: true,
        onClick: () => {
          this._router.navigate(['customer-recovery']);
        },
      },
      {
        displayName: 'Vendor',
        icon: 'wrench',
        showLabel: true,
        onClick: () => {
          this._router.navigate(['vendor-recovery']);
        },
      },
      {
        displayName: 'Insurance',
        icon: 'shield-check',
        showLabel: true,
        disabled: true,
      },
    ];
  }
}
