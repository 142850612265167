<lib-panel [hideHeader]="true">
  <content *ngIf="customerRecoveryData$ | async; else sectionloading">
    <app-lib-form
      [items]="items"
      (isValidChange)="formValidityChanged($event)"></app-lib-form>
  </content>
  <ng-template #sectionloading>
    <lib-section-skeleton-loader></lib-section-skeleton-loader>
  </ng-template>
</lib-panel>
