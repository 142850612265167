<ng-container *ngIf="customerRecoveryData$ | async">
  <div class="work-orders">
    <header class="header">Current work order</header>
    <ng-container *ngIf="currentWorkOrder$ | async as currentWorkOrder">
      <lib-panel>
        <header>
          <div style="display: flex">
            <mc-radio
              style="margin-right: 10px"
              fit="small"
              value="0"
              hiddenlabel
              [checked]="selectedIndex == 0"
              (change)="workOrderSelected($event)"></mc-radio>
            WO-{{ currentWorkOrder.workOrderNumber }}
          </div>
        </header>
        <left-corner-menu>
          <a
            (click)="
              showHideWorkOrderItemsLink(
                0,
                currentWorkOrder.workOrderLineItemDto
              )
            "
            class="header-link">
            {{ showWorkOrderItems[0] ? 'Hide line items' : 'View line items' }}
          </a>
        </left-corner-menu>
        <content>
          <app-lib-form [items]="workOrdersConverted[0]"></app-lib-form>
          <div
            [style.display]="showWorkOrderItems[0] ? 'block' : 'none'"
            class="work-order-line-items">
            <app-grid
              [schema]="lineItemsGridSchema!"
              [data]="lineItemsGridData[0]"
              [showPanel]="false"
              [showPagination]="false"
              [showRowSelector]="true"
              [rowSelectorType]="'checkbox'"
              [enableSorting]="false"
              [disabled]="selectedIndex != 0"
              (rowSelectionChange)="
                lineItemSelectionChanged($event)
              "></app-grid>
          </div>
        </content>
      </lib-panel>
    </ng-container>
  </div>
  <div class="work-orders">
    <header class="header">Previous work orders</header>
    <div class="work-order-filter">
      <mc-input-date
        ngDefaultControl
        formControlName="from-date"
        label=""
        startofweek="1"
        format="DD/MM/YYYY"
        locale="en-IN"
        nextlabel="Next month"
        previouslabel="Previous month"
        hiddenlabel
        placeholder="DD/MM/YYYY"
        fit="small"
        width="232px"
        [value]="selectedFromDate"
        (input)="dateSelected('from', $event)"></mc-input-date>

      <mc-input-date
        ngDefaultControl
        formControlName="to-date"
        label=""
        startofweek="1"
        format="DD/MM/YYYY"
        locale="en-IN"
        nextlabel="Next month"
        previouslabel="Previous month"
        hiddenlabel
        placeholder="DD/MM/YYYY"
        fit="small"
        width="232px"
        [value]="selectedToDate"
        (input)="dateSelected('to', $event)"></mc-input-date>

      <mc-button
        label="Get work orders"
        fit="small"
        (click)="searchWorkOrders()"></mc-button>
      <mc-button
        label="Cancel"
        fit="small"
        appearance="neutral"
        (click)="setInitialDateRangeForWorkOrders()"></mc-button>

      <span
        class="errorMessage"
        [style.display]="invalidDateSelected ? 'block' : 'none'"
        style="color: red">
        Invalid date format selected
      </span>
    </div>
    <ng-container *ngIf="workOrderList$ | async as workOrderList">
      <app-no-data
        *ngIf="workOrderList.length === 0"
        [height]="{ size: 300, unit: 'px' }">
        No work order found
      </app-no-data>
      <ng-container
        *ngFor="let workOrder of workOrderList; let i = &quot;index + 1&quot;">
        <lib-panel>
          <header>
            <div style="display: flex">
              <mc-radio
                style="margin-right: 10px"
                fit="small"
                value="{{ i }}"
                hiddenlabel
                [checked]="selectedIndex == i"
                (change)="workOrderSelected($event)"></mc-radio>
              WO-{{ workOrder.workOrderNumber }}
            </div>
          </header>
          <left-corner-menu>
            <a
              (click)="
                showHideWorkOrderItemsLink(i, workOrder.workOrderLineItemDto)
              "
              class="header-link">
              {{
                showWorkOrderItems[i] ? 'Hide line items' : 'View line items'
              }}
            </a>
          </left-corner-menu>
          <content>
            <app-lib-form [items]="workOrdersConverted[i]"></app-lib-form>
            <div [style.display]="showWorkOrderItems[i] ? 'block' : 'none'">
              <div>
                <app-grid
                  [schema]="lineItemsGridSchema!"
                  [data]="lineItemsGridData[i]"
                  [showPanel]="false"
                  [showPagination]="false"
                  [showRowSelector]="true"
                  [rowSelectorType]="'checkbox'"
                  [enableSorting]="false"
                  [disabled]="selectedIndex != i"
                  (rowSelectionChange)="
                    lineItemSelectionChanged($event)
                  "></app-grid>
              </div>
            </div>
          </content>
        </lib-panel>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
