import { Injectable } from '@angular/core';
import { Observable, map, tap } from 'rxjs';
//import { faro } from '@grafana/faro-web-sdk'; //Not Working
import { CustomerRecoveryCaseDto } from './common/models/customerRecoveryCaseDto';
import { loggerType } from './common/models/logger-type';
import { faro } from '../main'; // alternate approach for import { faro } from '@grafana/faro-web-sdk'

@Injectable()
export class logger {
  recoveryData!: CustomerRecoveryCaseDto;

  apiLogger<T>(object: Observable<T>, loggerDetail: loggerType): Observable<T> {
    const startTime: Date = new Date();

    return object.pipe(
      tap((res) => {
        const totalTime = (new Date().valueOf() - startTime.valueOf()) / 1000;
        if (faro && Object.keys(faro).length != 0)
          faro.api.pushLog([
            `Time Taken to load - ${loggerDetail.moduleName} ${this.recoveryData ? `for Case ${this.recoveryData.recoveryCaseNumber}` : ''} is ${totalTime}`,
          ]);
      })
    );
  }

  actionLogger(loggerDetail: loggerType) {
    if (faro && Object.keys(faro).length != 0)
      faro.api.pushLog([
        `${loggerDetail.message} ${this.recoveryData ? `for Case ${this.recoveryData.recoveryCaseNumber} is` : 'is'} ${loggerDetail.count}`,
      ]);
  }
}
