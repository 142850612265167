{
  "stages": [
    {
      "name": "new",
      "type": "",
      "hidden": false,
      "sequence": 0,
      "label": "New",
      "id": 1,
      "items": [
        {
          "name": "tab",
          "type": "tab",
          "valueType": "tab",
          "hidden": false,
          "items": [
            {
              "label": "Overview",
              "items": [
                {
                  "name": "overview",
                  "type": "lib-Form",
                  "label": "",
                  "items": [
                    {
                      "label": "Responsible Cluster",
                      "name": "podCountryClusterCode",
                      "type": "select",
                      "valueType": "string",
                      "hidden": false,
                      "disabled": false,
                      "width": {
                        "size": 33,
                        "unit": "%"
                      },
                      "sequence": 1,
                      "isMandatory": true,
                      "validationMessage": "Please select valid value"
                    },
                    {
                      "label": "Responsible Country",
                      "name": "podCountryCode",
                      "type": "select",
                      "valueType": "string",
                      "hidden": false,
                      "disabled": false,
                      "width": {
                        "size": 33,
                        "unit": "%"
                      },
                      "sequence": 2,
                      "isMandatory": true,
                      "validationMessage": "Please select valid value"
                    },
                    {
                      "label": "Operator",
                      "name": "operatorCode",
                      "type": "select",
                      "valueType": "string",
                      "width": {
                        "size": 33,
                        "unit": "%"
                      },
                      "hidden": false,
                      "disabled": false,
                      "sequence": 3,
                      "isMandatory": true,
                      "validationMessage": "Please select valid value"
                    },
                    {
                      "label": "Currency to Use",
                      "name": "podLocalCurrency",
                      "type": "select",
                      "valueType": "string",
                      "width": {
                        "size": 33,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 4,
                      "isMandatory": true,
                      "validationMessage": "Please select valid value"
                    },
                    {
                      "label": "Place of Incident (optional)",
                      "name": "placeOfIncident",
                      "type": "inputText",
                      "valueType": "string",
                      "width": {
                        "size": 33,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 5,
                      "placeHolder": "Enter Place of Incident"
                    },
                    {
                      "label": "Date of Incident (optional)",
                      "name": "dateOfIncident",
                      "type": "inputDate",
                      "valueType": "date",
                      "width": {
                        "size": 33,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 6
                    },
                    {
                      "label": "Exchange Rate",
                      "name": "exchangerate",
                      "type": "label",
                      "valueType": "string",
                      "width": {
                        "size": 33,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 7
                    }
                  ]
                },
                {
                  "name": "containerInformation",
                  "label": "Container Information",
                  "type": "lib-Form",
                  "items": [
                    {
                      "label": "WO Number",
                      "name": "workOrderNumber",
                      "type": "label",
                      "valueType": "string",
                      "hidden": false,
                      "sequence": 1,
                      "width": {
                        "size": 15,
                        "unit": "%"
                      }
                    },
                    {
                      "label": "Equipment Number",
                      "name": "containerNumber",
                      "type": "label",
                      "valueType": "string",
                      "hidden": false,
                      "width": {
                        "size": 17,
                        "unit": "%"
                      },
                      "sequence": 2
                    },
                    {
                      "label": "Equipment Type",
                      "name": "equipmentSubType",
                      "type": "label",
                      "valueType": "string",
                      "hidden": false,
                      "width": {
                        "size": 17,
                        "unit": "%"
                      },
                      "sequence": 3,
                      "value": "Dry"
                    },
                    {
                      "label": "Equipment Size",
                      "name": "containerSize",
                      "type": "label",
                      "valueType": "string",
                      "width": {
                        "size": 17,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 4
                    },
                    {
                      "label": "Container Empty/Full",
                      "name": "containerEmptyFull",
                      "type": "label",
                      "valueType": "string",
                      "width": {
                        "size": 17,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 5,
                      "value": "Empty"
                    },
                    {
                      "label": "Production year",
                      "name": "containerProdYear",
                      "type": "label",
                      "valueType": "string",
                      "width": {
                        "size": 15,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 6,
                      "value": "1997"
                    }
                  ]
                },
                {
                  "label": "Booking Information",
                  "type": "lib-Form",
                  "name": "bookingInformation",
                  "items": [
                    {
                      "label": "Equipment Number",
                      "name": "equipmentNumber",
                      "type": "label",
                      "valueType": "string",
                      "hidden": false,
                      "sequence": 1,
                      "width": {
                        "size": 20,
                        "unit": "%"
                      }
                    },
                    {
                      "label": "Booking Number",
                      "name": "bookingNumber",
                      "type": "label",
                      "valueType": "string",
                      "hidden": false,
                      "sequence": 2,
                      "width": {
                        "size": 20,
                        "unit": "%"
                      }
                    },
                    {
                      "label": "Invoice B/L no.",
                      "name": "invoiceNo",
                      "type": "label",
                      "valueType": "string",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 3
                    },
                    {
                      "label": "POL",
                      "name": "polSiteName",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "type": "label",
                      "valueType": "string",
                      "hidden": false,
                      "sequence": 4
                    },
                    {
                      "label": "POD",
                      "name": "podSiteName",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "type": "label",
                      "valueType": "string",
                      "hidden": false,
                      "sequence": 5
                    },
                    {
                      "label": "Service Mode",
                      "name": "servRecDel",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "type": "label",
                      "valueType": "string",
                      "hidden": false,
                      "sequence": 6
                    },
                    {
                      "name": "containerMoves",
                      "type": "containerMoves",
                      "items": [
                        {
                          "name": "activityDateTime",
                          "label": "Activity Date",
                          "valueType": "date",
                          "sequence": 1,
                          "width": {
                            "size": 20,
                            "unit": "%"
                          }
                        },
                        {
                          "name": "activity",
                          "label": "Activity",
                          "valueType": "string",
                          "sequence": 2,
                          "width": {
                            "size": 20,
                            "unit": "%"
                          }
                        },
                        {
                          "name": "bookingNumber",
                          "label": "Booking number",
                          "valueType": "string",
                          "sequence": 3,
                          "width": {
                            "size": 20,
                            "unit": "%"
                          }
                        },
                        {
                          "name": "activityReason",
                          "label": "Activity Reason",
                          "valueType": "string",
                          "sequence": 4,
                          "width": {
                            "size": 20,
                            "unit": "%"
                          }
                        },
                        {
                          "name": "activityLocation",
                          "label": "Activity Location",
                          "valueType": "string",
                          "sequence": 5,
                          "width": {
                            "size": 20,
                            "unit": "%"
                          }
                        },
                        {
                          "name": "placeOfReceipt",
                          "label": "Place of Receipt",
                          "valueType": "string",
                          "sequence": 6,
                          "width": {
                            "size": 20,
                            "unit": "%"
                          }
                        },
                        {
                          "name": "placeOfDelivery",
                          "label": "Place of Delivery",
                          "valueType": "string",
                          "sequence": 7,
                          "width": {
                            "size": 20,
                            "unit": "%"
                          }
                        },
                        {
                          "name": "emptyStatus",
                          "label": "Empty Status",
                          "valueType": "string",
                          "sequence": 8,
                          "width": {
                            "size": 20,
                            "unit": "%"
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  "label": "Cargo Details For Container",
                  "type": "lib-Form",
                  "name": "cargoDetails",
                  "items": [
                    {
                      "label": "Commodity Code",
                      "name": "commodityCode",
                      "type": "label",
                      "valueType": "string",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 1
                    },
                    {
                      "label": "Stuffed Weight",
                      "name": "stuffingWeight",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "type": "label",
                      "valueType": "string",
                      "hidden": false,
                      "sequence": 2
                    },
                    {
                      "label": "Total Commodity Volumn",
                      "name": "totalCommodityVolume",
                      "type": "label",
                      "valueType": "string",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 3
                    },
                    {
                      "label": "Stuffed Volumn",
                      "name": "stuffingVolume",
                      "type": "label",
                      "valueType": "string",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 4
                    },
                    {
                      "label": "Total Commodity Weight",
                      "name": "totalCommodityWeight",
                      "type": "label",
                      "valueType": "string",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 4
                    },
                    {
                      "label": "Commodity Description",
                      "name": "commodityDescription",
                      "type": "label",
                      "valueType": "string",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 4
                    },
                    {
                      "label": "Package",
                      "name": "package",
                      "type": "label",
                      "valueType": "string",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 4
                    },
                    {
                      "label": "Cargo Documentation Description",
                      "name": "cargoDocumentationDescription",
                      "type": "label",
                      "valueType": "string",
                      "width": {
                        "size": 100,
                        "unit": "%"
                      },
                      "disableValueTextOverflow": true,
                      "hidden": false,
                      "sequence": 4
                    }
                  ]
                },
                {
                  "name": "attachments",
                  "label": "Attachments",
                  "subLabel": "Images and damage codes for the selected eEIR are shown below",
                  "type": "Attachments",
                  "items": [
                    {
                      "name": "sourceName",
                      "label": "Source",
                      "valueType": "string",
                      "sequence": 1
                    },
                    {
                      "name": "inspectionId",
                      "label": "EIR No",
                      "valueType": "string",
                      "sequence": 1
                    },
                    {
                      "name": "createdDate",
                      "label": "Created On",
                      "valueType": "date",
                      "sequence": 2
                    },
                    {
                      "name": "shopCode",
                      "label": "Shop Code",
                      "valueType": "string",
                      "sequence": 3
                    },
                    {
                      "name": "createdBy",
                      "label": "Created By",
                      "valueType": "string",
                      "sequence": 4
                    },
                    {
                      "name": "moveType",
                      "label": "Move Type",
                      "valueType": "string",
                      "sequence": 5
                    },
                    {
                      "name": "imageCount",
                      "label": "Photos",
                      "valueType": "string",
                      "sequence": 6
                    }
                  ]
                }
              ]
            },
            {
              "label": "Activity Log",
              "items": [
                {
                  "name": "activityLog",
                  "type": "activity",
                  "items": [
                    {
                      "name": "logDate",
                      "label": "Date",
                      "valueType": "date",
                      "sequence": 1
                    },
                    {
                      "name": "userId",
                      "label": "User ID",
                      "valueType": "string",
                      "sequence": 2
                    },
                    {
                      "name": "eventDescription",
                      "label": "Event",
                      "valueType": "string",
                      "sequence": 3
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "name": "damageEstimation",
      "type": "",
      "hidden": false,
      "sequence": 1,
      "label": "Damage Estimation",
      "id": 2,
      "items": [
        {
          "name": "tab",
          "type": "tab",
          "valueType": "tab",
          "hidden": false,
          "items": [
            {
              "label": "Overview",
              "items": [
                {
                  "label": "Manual Estimate",
                  "type": "lib-Form",
                  "name": "manual-estimates",
                  "items": [
                    {
                      "label": "Damage Type",
                      "name": "damageType",
                      "type": "select",
                      "valueType": "string",
                      "width": {
                        "size": 22,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 1,
                      "hideLabel": false,
                      "placeHolder": "Select",
                      "isMandatory": true
                    },
                    {
                      "label": "Area",
                      "name": "area",
                      "type": "select",
                      "valueType": "string",
                      "hidden": false,
                      "width": {
                        "size": 22,
                        "unit": "%"
                      },
                      "sequence": 2,
                      "hideLabel": false,
                      "placeHolder": "Select",
                      "isMandatory": true
                    },
                    {
                      "label": "Damage Size",
                      "name": "damageSize",
                      "type": "select",
                      "valueType": "string",
                      "hidden": false,
                      "width": {
                        "size": 21,
                        "unit": "%"
                      },
                      "sequence": 3,
                      "hideLabel": false,
                      "isMandatory": true
                    },
                    {
                      "label": "Quantity",
                      "name": "quantity",
                      "type": "inputText",
                      "valueType": "numeric",
                      "width": {
                        "size": 14,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 4,
                      "hideLabel": false,
                      "isMandatory": true,
                      "placeHolder": "Enter quantity"
                    },
                    {
                      "label": "Unit Repair Cost ($currency)",
                      "name": "unitRepairCost",
                      "type": "inputText",
                      "valueType": "numeric",
                      "width": {
                        "size": 18,
                        "unit": "%"
                      },
                      "hidden": false,
                      "labelPosition": "above",
                      "sequence": 5,
                      "isMandatory": true,
                      "placeHolder": "Enter repair cost"
                    },
                    {
                      "name": "manualEstimatesLineItems",
                      "type": "grid",
                      "label": "",
                      "items": [
                        {
                          "name": "repairLineItemId",
                          "label": "Item Id",
                          "valueType": "numeric",
                          "sequence": 1,
                          "hidden": true
                        },
                        {
                          "name": "damageType",
                          "label": "Damage Type",
                          "valueType": "string",
                          "sequence": 2
                        },
                        {
                          "name": "area",
                          "label": "Area",
                          "valueType": "string",
                          "sequence": 3
                        },
                        {
                          "name": "size",
                          "label": "Damage Size",
                          "valueType": "string",
                          "sequence": 4
                        },
                        {
                          "name": "quantity",
                          "label": "Qty",
                          "valueType": "numeric",
                          "sequence": 5
                        },
                        {
                          "name": "unitRepairCostLocal",
                          "label": "Unit Repair Cost ($currency)",
                          "valueType": "numeric",
                          "sequence": 7
                        },
                        {
                          "name": "lineTotalLocal",
                          "label": "Line Total ($currency)",
                          "valueType": "numeric",
                          "sequence": 7
                        },
                        {
                          "name": "lineTotalUSD",
                          "label": "Line Total (USD)",
                          "valueType": "numeric",
                          "sequence": 8,
                          "icon": "trash"
                        }
                      ]
                    }
                  ]
                },
                {
                  "label": "Container Protect Information",
                  "type": "lib-Form",
                  "name": "cpInformation",
                  "items": [
                    {
                      "label": "CP Purchased",
                      "name": "cpPurchased",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "type": "label",
                      "valueType": "string",
                      "hidden": false,
                      "sequence": 6
                    },
                    {
                      "label": "Coverage Cost",
                      "name": "cpCoverageAmount",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "type": "label",
                      "valueType": "string",
                      "hidden": false,
                      "sequence": 7
                    },
                    {
                      "label": "Recoverable Cost",
                      "name": "recoverableCostUSD",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "type": "label",
                      "valueType": "string",
                      "hidden": false,
                      "sequence": 8
                    },
                    {
                      "label": "Above Coverage Cost",
                      "name": "aboveCoverageCostUSD",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "type": "label",
                      "valueType": "string",
                      "hidden": false,
                      "sequence": 8
                    }
                  ]
                },
                {
                  "label": "Booking Information",
                  "type": "lib-Form",
                  "name": "bookingInformation",
                  "items": [
                    {
                      "label": "Equipment Number",
                      "name": "equipmentNumber",
                      "type": "label",
                      "valueType": "string",
                      "hidden": false,
                      "sequence": 1,
                      "width": {
                        "size": 20,
                        "unit": "%"
                      }
                    },
                    {
                      "label": "Booking Number",
                      "name": "bookingNumber",
                      "type": "label",
                      "valueType": "string",
                      "hidden": false,
                      "sequence": 2,
                      "width": {
                        "size": 20,
                        "unit": "%"
                      }
                    },
                    {
                      "label": "Invoice B/L no.",
                      "name": "invoiceNo",
                      "type": "label",
                      "valueType": "string",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 3
                    },
                    {
                      "label": "POL",
                      "name": "polSiteName",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "type": "label",
                      "valueType": "string",
                      "hidden": false,
                      "sequence": 4
                    },
                    {
                      "label": "POD",
                      "name": "podSiteName",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "type": "label",
                      "valueType": "string",
                      "hidden": false,
                      "sequence": 5
                    },
                    {
                      "label": "Service Mode",
                      "name": "servRecDel",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "type": "label",
                      "valueType": "string",
                      "hidden": false,
                      "sequence": 6
                    }
                  ]
                },
                {
                  "name": "liabilityDetailsManualEstimates",
                  "type": "liabilityDetailsManualEstimates",
                  "items": [
                    {
                      "name": "gridSchema",
                      "label": "Grid Schema",
                      "items": [
                        {
                          "name": "costType",
                          "label": "  ",
                          "valueType": "string",
                          "sequence": 1
                        },
                        {
                          "name": "costLocal",
                          "label": "Cost (Local)",
                          "valueType": "numeric",
                          "sequence": 2,
                          "align": "RIGHT"
                        },
                        {
                          "name": "costUSD",
                          "label": "Cost (USD)",
                          "valueType": "numeric",
                          "sequence": 3,
                          "align": "RIGHT"
                        }
                      ]
                    },
                    {
                      "name": "footerSchema",
                      "label": "Footer Schema",
                      "items": [
                        {
                          "name": "costType",
                          "label": "Cost Type",
                          "valueType": "string",
                          "sequence": 1,
                          "colspan": 1
                        },
                        {
                          "name": "costLocal",
                          "label": "Cost (Local)",
                          "valueType": "numeric",
                          "sequence": 2,
                          "colspan": 1
                        },
                        {
                          "name": "costUSD",
                          "label": "Cost (USD)",
                          "valueType": "numeric",
                          "sequence": 3,
                          "colspan": 1
                        }
                      ]
                    },
                    {
                      "name": "damageCost",
                      "label": "Damage Cost (Manual Estimate)",
                      "valueType": "numeric",
                      "sequence": 1
                    },
                    {
                      "name": "recoverableCostUSD",
                      "label": "Recoverable Cost",
                      "valueType": "numeric",
                      "sequence": 2
                    },
                    {
                      "name": "withInCoverageCost",
                      "label": "Within Coverage Cost",
                      "valueType": "numeric",
                      "sequence": 3
                    },
                    {
                      "name": "totalLiability",
                      "label": "Total Liability",
                      "valueType": "numeric",
                      "sequence": 4
                    }
                  ]
                },
                {
                  "label": "Damage Details",
                  "type": "lib-Form",
                  "name": "damage-Details",
                  "items": [
                    {
                      "label": "id",
                      "name": "id",
                      "hidden": true
                    },
                    {
                      "label": "Description",
                      "name": "description",
                      "type": "inputText",
                      "valueType": "string",
                      "width": {
                        "size": 25,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 1,
                      "hideLabel": true,
                      "placeHolder": "Enter Description here",
                      "isMandatory": true
                    },
                    {
                      "label": "Material Code",
                      "name": "materialCode",
                      "type": "select",
                      "valueType": "string",
                      "hidden": false,
                      "width": {
                        "size": 25,
                        "unit": "%"
                      },
                      "sequence": 2,
                      "hideLabel": true,
                      "placeHolder": "Select an option",
                      "isMandatory": true
                    },
                    {
                      "label": "Amount (Local)",
                      "name": "amountInCaseCurrency",
                      "type": "inputText",
                      "valueType": "numeric",
                      "width": {
                        "size": 25,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 3,
                      "hideLabel": true,
                      "placeHolder": "Enter Amount here",
                      "isMandatory": true
                    },
                    {
                      "label": "Amount (USD)",
                      "name": "amount",
                      "type": "label",
                      "valueType": "numeric",
                      "width": {
                        "size": 25,
                        "unit": "%"
                      },
                      "hidden": false,
                      "labelPosition": "above",
                      "sequence": 4,
                      "isMandatory": true
                    }
                  ]
                }
              ]
            },
            {
              "label": "Activity Log",
              "items": [
                {
                  "name": "activityLog",
                  "type": "activity",
                  "items": [
                    {
                      "name": "logDate",
                      "label": "Date",
                      "valueType": "date",
                      "sequence": 1
                    },
                    {
                      "name": "userId",
                      "label": "User ID",
                      "valueType": "string",
                      "sequence": 2
                    },
                    {
                      "name": "eventDescription",
                      "label": "Event",
                      "valueType": "string",
                      "sequence": 3
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "name": "liability",
      "type": "",
      "hidden": false,
      "sequence": 2,
      "label": "Liability",
      "id": 3,
      "items": [
        {
          "name": "tab",
          "type": "tab",
          "valueType": "tab",
          "hidden": false,
          "items": [
            {
              "label": "Overview",
              "items": [
                {
                  "label": "Booking Information",
                  "type": "lib-Form",
                  "name": "bookingInformation",
                  "hidden": false,
                  "items": [
                    {
                      "label": "Booking Number",
                      "name": "bookingNumber",
                      "type": "label",
                      "valueType": "string",
                      "hidden": false,
                      "sequence": 1,
                      "width": {
                        "size": 20,
                        "unit": "%"
                      }
                    },
                    {
                      "label": "Invoice B/L no.",
                      "name": "invoiceNo",
                      "type": "label",
                      "valueType": "string",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 2
                    },
                    {
                      "label": "POL",
                      "name": "polSiteName",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "type": "label",
                      "valueType": "string",
                      "hidden": false,
                      "sequence": 3
                    },
                    {
                      "label": "POD",
                      "name": "podSiteName",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "type": "label",
                      "valueType": "string",
                      "hidden": false,
                      "sequence": 4
                    }
                  ]
                },
                {
                  "label": "Cargo Details For Container",
                  "type": "lib-Form",
                  "name": "cargoDetails",
                  "hidden": false,
                  "items": [
                    {
                      "label": "Commodity Code",
                      "name": "commodityCode",
                      "type": "label",
                      "valueType": "string",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 1
                    },
                    {
                      "label": "Stuffed Weight",
                      "name": "stuffingWeight",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "type": "label",
                      "valueType": "string",
                      "hidden": false,
                      "sequence": 2
                    },
                    {
                      "label": "Total Commodity Volume",
                      "name": "totalCommodityVolume",
                      "type": "label",
                      "valueType": "string",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 3
                    },
                    {
                      "label": "Stuffed Volume",
                      "name": "stuffingVolume",
                      "type": "label",
                      "valueType": "string",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 4
                    },
                    {
                      "label": "Total Commodity Weight",
                      "name": "totalCommodityWeight",
                      "type": "label",
                      "valueType": "string",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 4
                    },
                    {
                      "label": "Commodity Description",
                      "name": "commodityDescription",
                      "type": "label",
                      "valueType": "string",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 4
                    },
                    {
                      "label": "Package",
                      "name": "package",
                      "type": "label",
                      "valueType": "string",
                      "width": {
                        "size": 20,
                        "unit": "%"
                      },
                      "hidden": false,
                      "sequence": 4
                    },
                    {
                      "label": "Cargo Documentation Description",
                      "name": "cargoDocumentationDescription",
                      "type": "label",
                      "valueType": "string",
                      "width": {
                        "size": 100,
                        "unit": "%"
                      },
                      "disableValueTextOverflow": true,
                      "hidden": false,
                      "sequence": 4
                    }
                  ]
                },
                {
                  "type": "lib-Form",
                  "name": "liable-party-details",
                  "hidden": false,
                  "items": [
                    {
                      "type": "section-1",
                      "name": "section-1",
                      "items": [
                        {
                          "label": "Liable Party Type",
                          "name": "liablePartyType",
                          "type": "select",
                          "valueType": "string",
                          "hidden": false,
                          "width": {
                            "size": 33,
                            "unit": "%"
                          },
                          "sequence": 1,
                          "isMandatory": true,
                          "validationMessage": "Please select valid value"
                        },
                        {
                          "label": "Liable Party Name",
                          "name": "liablePartyName",
                          "type": "select",
                          "valueType": "string",
                          "hidden": true,
                          "width": {
                            "size": 33,
                            "unit": "%"
                          },
                          "sequence": 2,
                          "isMandatory": true,
                          "validationMessage": "Please select valid value"
                        },
                        {
                          "label": "SCV Code",
                          "name": "scvCode",
                          "type": "inputText",
                          "valueType": "string",
                          "width": {
                            "size": 33,
                            "unit": "%"
                          },
                          "hidden": true,
                          "sequence": 3,
                          "placeHolder": "Type and search SCV code",
                          "isMandatory": true
                        },
                        {
                          "label": "FACT Code",
                          "name": "factCode",
                          "type": "inputText",
                          "valueType": "string",
                          "width": {
                            "size": 33,
                            "unit": "%"
                          },
                          "hidden": true,
                          "placeHolder": "Type and search FACT code",
                          "sequence": 4,
                          "isMandatory": true
                        },
                        {
                          "label": "Contact Email Address",
                          "name": "primaryContactEmail",
                          "type": "label",
                          "valueType": "string",
                          "width": {
                            "size": 33,
                            "unit": "%"
                          },
                          "hidden": true,
                          "sequence": 5
                        }
                      ]
                    },
                    {
                      "type": "section-2",
                      "hidden": true,
                      "items": [
                        {
                          "label": "FACT Code",
                          "name": "factCode",
                          "type": "label",
                          "valueType": "string",
                          "width": {
                            "size": 20,
                            "unit": "%"
                          },
                          "sequence": 1
                        },
                        {
                          "label": "SCV Code",
                          "name": "scvCode",
                          "type": "label",
                          "valueType": "string",
                          "width": {
                            "size": 20,
                            "unit": "%"
                          },
                          "sequence": 2
                        },
                        {
                          "label": "Phone Number",
                          "name": "phoneNumber",
                          "type": "label",
                          "valueType": "string",
                          "width": {
                            "size": 20,
                            "unit": "%"
                          },
                          "sequence": 1
                        },
                        {
                          "label": "Contact Name",
                          "name": "contactName",
                          "type": "label",
                          "valueType": "string",
                          "width": {
                            "size": 20,
                            "unit": "%"
                          },
                          "sequence": 3
                        },

                        {
                          "label": "Company Name",
                          "name": "companyName",
                          "type": "label",
                          "valueType": "string",
                          "width": {
                            "size": 20,
                            "unit": "%"
                          },
                          "sequence": 1
                        },
                        {
                          "label": "Address",
                          "name": "address",
                          "type": "label",
                          "valueType": "string",
                          "width": {
                            "size": 20,
                            "unit": "%"
                          },
                          "sequence": 1
                        },
                        {
                          "label": "Country",
                          "name": "countryId",
                          "type": "label",
                          "valueType": "string",
                          "width": {
                            "size": 20,
                            "unit": "%"
                          },
                          "sequence": 1
                        },
                        {
                          "label": "ZIP Code",
                          "name": "zipCode",
                          "type": "label",
                          "valueType": "string",
                          "width": {
                            "size": 20,
                            "unit": "%"
                          },
                          "sequence": 1
                        }
                      ]
                    },
                    {
                      "type": "section-3",
                      "name": "section_3",
                      "hidden": true,
                      "items": [
                        {
                          "label": "Place of Delivery",
                          "name": "placeOfDelivery",
                          "type": "typeAhead",
                          "valueType": "string",
                          "width": {
                            "size": 33,
                            "unit": "%"
                          },
                          "sequence": 1,
                          "isMandatory": true,
                          "validationMessage": "Please select valid value"
                        },
                        {
                          "label": "Place of Receipt",
                          "name": "placeOfReceipt",
                          "type": "typeAhead",
                          "valueType": "string",
                          "width": {
                            "size": 33,
                            "unit": "%"
                          },
                          "sequence": 2,
                          "isMandatory": true,
                          "validationMessage": "Please select valid value"
                        },
                        {
                          "label": "Payers",
                          "name": "payers",
                          "type": "inputText",
                          "valueType": "string",
                          "width": {
                            "size": 33,
                            "unit": "%"
                          },
                          "sequence": 3,
                          "isMandatory": true
                        },
                        {
                          "label": "Bill to Party",
                          "name": "billTo",
                          "type": "inputText",
                          "valueType": "string",
                          "width": {
                            "size": 33,
                            "unit": "%"
                          },
                          "sequence": 4,
                          "isMandatory": true
                        },
                        {
                          "label": "Ship to Party",
                          "name": "shipTo",
                          "type": "inputText",
                          "valueType": "string",
                          "width": {
                            "size": 33,
                            "unit": "%"
                          },
                          "sequence": 5,
                          "isMandatory": true
                        },
                        {
                          "label": "Sold to Party",
                          "name": "soldTo",
                          "type": "inputText",
                          "valueType": "string",
                          "width": {
                            "size": 33,
                            "unit": "%"
                          },
                          "sequence": 6,
                          "isMandatory": true
                        }
                      ]
                    }
                  ]
                },
                {
                  "label": "New Letter",
                  "type": "letter-section",
                  "name": "liability-letter",
                  "hidden": false
                },
                {
                  "label": "Previously Issued Letters",
                  "type": "liability-letters-log",
                  "name": "liability-letters-log",
                  "hidden": true
                }
              ]
            },
            {
              "label": "Activity Log",
              "items": [
                {
                  "name": "activityLog",
                  "type": "activity",
                  "items": [
                    {
                      "name": "logDate",
                      "label": "Date",
                      "valueType": "date",
                      "sequence": 1
                    },
                    {
                      "name": "userId",
                      "label": "User ID",
                      "valueType": "string",
                      "sequence": 2
                    },
                    {
                      "name": "eventDescription",
                      "label": "Event",
                      "valueType": "string",
                      "sequence": 3
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "name": "invoice",
      "label": "Invoice",
      "sequence": 3,
      "id": 4,
      "items": [
        {
          "name": "tab",
          "type": "tab",
          "valueType": "tab",
          "hidden": false,
          "items": [
            {
              "label": "Overview",
              "items": [
                {
                  "label": "Invoice Summary",
                  "type": "invoice-summary",
                  "name": "invoice-summary",
                  "items": [
                    {
                      "name": "materialCode",
                      "label": "Material Code",
                      "valueType": "string",
                      "align": "LEFT",
                      "sequence": 1
                    },
                    {
                      "name": "description",
                      "label": "Description",
                      "valueType": "string",
                      "align": "LEFT",
                      "sequence": 2
                    },
                    {
                      "name": "amount",
                      "label": "Amount",
                      "valueType": "numeric",
                      "align": "RIGHT",
                      "sequence": 3
                    }
                  ]
                },
                {
                  "label": "invoice letter",
                  "type": "invoice-letter",
                  "name": "invoice-letter",
                  "hidden": false
                },
                {
                  "label": "Previously Issued Invoices",
                  "type": "invoice-letters-log",
                  "name": "invoice-letters-log",
                  "hidden": false
                }
              ]
            },
            {
              "label": "Activity Log",
              "items": [
                {
                  "name": "activityLog",
                  "type": "activity",
                  "items": [
                    {
                      "name": "logDate",
                      "label": "Date",
                      "valueType": "date",
                      "sequence": 1
                    },
                    {
                      "name": "userId",
                      "label": "User ID",
                      "valueType": "string",
                      "sequence": 2
                    },
                    {
                      "name": "eventDescription",
                      "label": "Event",
                      "valueType": "string",
                      "sequence": 3
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "label": "Complete",
      "name": "complete",
      "sequence": 4,
      "id": 5,
      "items": [
        {
          "name": "tab",
          "type": "tab",
          "valueType": "tab",
          "hidden": false,
          "items": [
            {
              "label": "Overview",
              "items": [
                {
                  "label": "Workflow Completed",
                  "type": "workflow-completed",
                  "name": "workflow-completed",
                  "hidden": false
                }
              ]
            },
            {
              "label": "Activity Log",
              "items": [
                {
                  "name": "activityLog",
                  "type": "activity",
                  "items": [
                    {
                      "name": "logDate",
                      "label": "Date",
                      "valueType": "date",
                      "sequence": 1
                    },
                    {
                      "name": "userId",
                      "label": "User ID",
                      "valueType": "string",
                      "sequence": 2
                    },
                    {
                      "name": "eventDescription",
                      "label": "Event",
                      "valueType": "string",
                      "sequence": 3
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "stagesHeader": [
    {
      "label": "New",
      "name": "new",
      "StageId": "new_stage",
      "id": 1,
      "sequence": 0
    },
    {
      "label": "Damage Estimation",
      "name": "damageEstimation",
      "stageId": "damage_estimation_stage",
      "sequence": 1,
      "id": 2
    },
    {
      "label": "Liability",
      "name": "liability",
      "stageId": "liability_stage",
      "id": 3,
      "sequence": 2
    },
    {
      "label": "Invoice",
      "name": "invoice",
      "id": 4,
      "StageId": "invoice_stage",
      "sequence": 3
    },
    {
      "label": "Complete",
      "name": "complete",
      "id": 5,
      "stageId": "complete_stage",
      "sequence": 4
    }
  ]
}
