import {
  Component,
  Input,
  CUSTOM_ELEMENTS_SCHEMA,
  Output,
  EventEmitter,
} from '@angular/core';
import { WorkOrderAndLineItemsService } from '../../../common/services/customer-recovery/work-order-and-line-items.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { WorkOrderAndLineItemsDto } from '../../../common/models/workOrderAndLineItemsDto';
import { CommonModule } from '@angular/common';
import {
  ALIGN,
  GridComponent,
  GridRowData,
  GridCellData,
  GridColumnSchema,
  LibFormComponent,
  PanelComponent,
} from '@maersk-global/angular-shared-library';
import { WorkOrderLineItemDto } from '../../../common/models/workOrderLineItemDto';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { workOrderStatusType } from '../../../common/constants/temporary-constant';
import {
  DOLLAR,
  HYPHEN,
  ZERO_STRING,
} from '../../../common/constants/app.constants';
import { SharedDataService } from '../../../shared-data-service';

const WORK_ORDER_STATUS_CODE = 'workorderStatusCode';
const ALL_COST_FIELDS_ARRAY = [
  'workOrderCostUSD',
  'recoverableCostUSD',
  'withinCoverageCostUSD',
  'aboveCoverageCostUSD',
];

@Component({
  selector: 'work-orders',
  standalone: true,
  imports: [CommonModule, LibFormComponent, GridComponent, PanelComponent],
  templateUrl: './work-orders.component.html',
  styleUrl: './work-orders.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class WorkOrdersComponent {
  @Input() item!: TemplateModel;
  @Output() workOrdersLoaded = new EventEmitter<WorkOrderAndLineItemsDto[]>();
  items: TemplateModel[] = [];
  libItems: TemplateModel[] = [];
  apiVersion = '1.0';
  workOrders$: Observable<WorkOrderAndLineItemsDto[] | null> | undefined;
  lineItemsGridSchema: GridColumnSchema[] | undefined = [];
  lineItemsGridData: any[] = [];
  orders: any[] = [];
  lineItems: any[] = [];
  showWorkOrderItems: boolean[] = [];
  workOrdersConverted: any[] = [];

  constructor(
    private _workOrderService: WorkOrderAndLineItemsService,
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _sharedDataService: SharedDataService
  ) {}

  /**
   * This method is triggered when the component initializes.
   * It initializes items.
   */

  customerRecoveryData$ =
    this._sharedRecoveryCaseService.recoveryCaseData$.pipe(
      tap((customerData) => {
        this.initializeItems();
        this.fetchWorkOrders(customerData?.recoveryCaseNumber ?? '');
      })
    );

  /**
   * Initializes items based on the input data.
   */
  initializeItems() {
    this.loadLineItemsGridSchema();
    this.items =
      this.item?.items?.filter((o) => o.name == 'workOrders')[0]?.items || [];
  }

  loadLineItemsGridSchema() {
    this.lineItemsGridSchema = this.item?.items
      ?.filter((o) => o.name == 'workOrderLineItems')[0]
      .items?.map((y: TemplateModel) => {
        const column = {
          column: y.name,
          displayName: y.label,
          align: ALIGN.LEFT,
          hidden: false,
          sequence: y.sequence,
          columnType: y.valueType?.toUpperCase(),
          disableSort: true,
        } as GridColumnSchema;
        return column;
      });
  }

  /**
   * Fetches work orders from the service.
   */
  fetchWorkOrders(caseNumber: string) {
    this.workOrders$ = this._workOrderService
      .workOrderCaseNumberLineItemsGet(caseNumber, this.apiVersion)
      .pipe(
        map((response) => (response?.data ? response.data : null)),
        tap((response) => {
          response?.forEach((workorder, index) => {
            this.mapWorkOrderToAlignWithUI(workorder, index);
          });
          this.workOrdersLoaded.emit(response!);
        })
      );
  }

  /**
   * Updates the values of items based on the provided work order data.
   * @param workOrder The work order data containing item values.
   */
  private updateItemValues(workOrder: WorkOrderAndLineItemsDto) {
    // Iterate over each item in the items array
    this.items.forEach((item: TemplateModel) => {
      // Cast the workOrder to a generic object for dynamic property access
      const customerRecoveryKeyValue = workOrder as unknown as {
        [key: string]: unknown;
      };

      // Update item value based on the item's name
      if (item.name == WORK_ORDER_STATUS_CODE) {
        // If the item's name is the work order status code, find the corresponding status
        const workOrderStatus = workOrderStatusType.find(
          (o) => o.value === customerRecoveryKeyValue[item.name]
        );

        // Set item value to the status label or '-' if not found
        item.value = workOrderStatus?.label ?? HYPHEN;
      } else {
        // For other items, set item value directly from work order data
        item.value = customerRecoveryKeyValue[item.name || ''];

        // If item value is falsy, set it to '-' or '0' if it's a number
        if (!item.value) {
          item.value = item.value === 0 ? DOLLAR + ZERO_STRING : HYPHEN;
        } else if (ALL_COST_FIELDS_ARRAY.includes(item.name)) {
          // If item name is in cost fields array, prepend '$' to the value
          item.value = DOLLAR + this.formatDecimal(item.value);
        }
      }
    });
  }

  /**
   * This method transforms an array of line items received from the API response into an array of grid row objects.
   * @param lineItems Array of original objects received from the web API response
   * @returns Array of grid row objects created
   */
  generateGridDataFromLineItems(
    lineItems: WorkOrderLineItemDto[] | undefined
  ): GridRowData[] {
    if (!lineItems || lineItems.length < 1) return [];
    return lineItems.map((lineItem) => {
      const lineItemKeyValue = lineItem as unknown as {
        [key: string]: unknown;
      };
      const gridRowObject: { [key: string]: GridCellData } = {};
      Object.keys(lineItem).map((key) => {
        if (key == 'isRecoverable') {
          gridRowObject[key] = {
            value: lineItemKeyValue[key] ? 'Y' : 'N',
          } as GridCellData;
        } else {
          gridRowObject[key] = {
            value: lineItemKeyValue[key],
          } as GridCellData;
        }
      });
      return {
        row: gridRowObject,
        isRowSelectionDisabled: true,
      } as GridRowData;
    });
  }

  showHideWorkOrderItemsLink(
    index: number,
    lineItems: WorkOrderLineItemDto[] | undefined
  ) {
    this.showWorkOrderItems[index] = !this.showWorkOrderItems[index];

    if (this.showWorkOrderItems[index]) {
      this.lineItemsGridData[index] =
        this.generateGridDataFromLineItems(lineItems);
    }
  }

  mapWorkOrderToAlignWithUI(
    workOrder: WorkOrderAndLineItemsDto,
    index: number
  ) {
    // Update item values based on workOrder properties
    this.updateItemValues(workOrder);

    // Deep clone and assign updated items to orders
    this.workOrdersConverted[index] = this._sharedDataService.deepClone(
      this.items
    );
  }

  private formatDecimal(value: number): number {
    return Math.round(value * 100) / 100;
  }
}
