/**
 * CC DCRP API
 * Connected Claims Damaged Container Recovery API's.
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */
 
 import { Inject, Injectable, Optional }                      from '@angular/core';
 import { HttpClient, HttpHeaders, HttpParams,
          HttpResponse, HttpEvent }                           from '@angular/common/http';
 import { CustomHttpUrlEncodingCodec }                        from '../encoder';
  
 import { Observable }                                        from 'rxjs';
import { environment } from '../../../../environments/environment';
import { MaterialCodeDtoIEnumerableResponse } from '../../models/materialCodeDtoIEnumerableResponse';
  
 @Injectable()
 export class MaterialCodeService {
  
    protected basePath = environment.dcrp_api_base_url;
    public defaultHeaders = new HttpHeaders();
  
    constructor(protected httpClient: HttpClient) {}
  
     /**
      * @param consumes string[] mime-types
      * @return true: consumes contains 'multipart/form-data', false: otherwise
      */
     private canConsumeForm(consumes: string[]): boolean {
         const form = 'multipart/form-data';
         for (const consume of consumes) {
             if (form === consume) {
                 return true;
             }
         }
         return false;
     }
  
  
     /**
      * Retrieves material codes based on the active status.
      * Sample request:                    GET /material-codes/false                If onlyActive is set to &#x60;true&#x60;, the API returns only active material codes.   If set to &#x60;false&#x60;, it returns all material codes, regardless of their active status.                The response will include:  - **200 OK**: Successful retrieval of material codes.  - **404 Not Found**: If no material codes are found.  - **400 Bad Request**: If the request is malformed or invalid.  - **500 Internal Server Error**: For unexpected server errors.
      * @param onlyActive A boolean flag indicating whether to return only active material codes. Defaults to &#x60;false&#x60;.
      * @param aPIVersion
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public materialCodesGet(onlyActive?: boolean, aPIVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<MaterialCodeDtoIEnumerableResponse>;
     public materialCodesGet(onlyActive?: boolean, aPIVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MaterialCodeDtoIEnumerableResponse>>;
     public materialCodesGet(onlyActive?: boolean, aPIVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MaterialCodeDtoIEnumerableResponse>>;
     public materialCodesGet(onlyActive?: boolean, aPIVersion?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
  
  
         let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         if (onlyActive !== undefined && onlyActive !== null) {
             queryParameters = queryParameters.set('onlyActive', <any>onlyActive);
         }
  
         let headers = this.defaultHeaders;
         if (aPIVersion !== undefined && aPIVersion !== null) {
             headers = headers.set('API-Version', String(aPIVersion));
         }
    
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'text/plain',
             'application/json',
             'text/json'
         ];
         headers = headers.set('Accept', httpHeaderAccepts);
  
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
  
         return this.httpClient.request<MaterialCodeDtoIEnumerableResponse>('get',`${this.basePath}/material-codes`,
             {
                 params: queryParameters,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
  
 }