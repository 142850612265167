<ng-container
  *ngIf="vendorRecoveryData$ | async as vendorRecoveryData; else pageLoader">
  <div class="workflow">
    <div class="bc-header">
      <div class="breadcrumb">
        <span class="path1">
          <a (click)="onNavigateBackToCaseList()">{{ tabName$ | async }}</a>
          /
        </span>
        <span class="path2">Case {{ vendorRecoveryData.caseId }}</span>
      </div>
    </div>

    <div class="header">
      <span class="headerText">Case - {{ vendorRecoveryData.caseId }}</span>
      <mc-icon icon="info-circle"></mc-icon>
    </div>

    <div class="workflow-form">
      <stepper
        [stages]="stages"
        (loadSelectedStage)="stageLoad($event)"></stepper>
      <div class="workflow-tab" *ngIf="currentStageId$ | async">
        <mc-tab-bar
          *ngIf="loadTab"
          (tabchange)="onTabChange($event)"
          [currentindex]="currentTabIndex">
          @for (tab of currentStageItem; track tab; let index = $index) {
            <mc-tab slot="tab" label="{{ tab.label }}"></mc-tab>
          }
        </mc-tab-bar>
        <ng-container *ngTemplateOutlet="formSectionsTemplate"></ng-container>
        <ng-container *ngTemplateOutlet="closeCaseTemplate"></ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #formSectionsTemplate>
  <div class="panel-wrapper">
    @if (tabItems) {
      @for (subTab of tabItems; track subTab) {
        <ng-container>
          @if (subTab.name) {
            <ng-container [ngSwitch]="subTab.name">
              <ng-container *ngSwitchCase="'activityLog'">
                @if (subTab.items) {
                  <activity-log [item]="subTab"></activity-log>
                }
              </ng-container>
              <ng-container *ngSwitchCase="'attachments'">
                @if (subTab.items) {
                  <attachment [item]="subTab"></attachment>
                }
              </ng-container>
              <ng-container *ngSwitchCase="'damage-Details'">
                @if (subTab.items) {
                  <damage-details
                    #damageDetails
                    [item]="subTab"></damage-details>
                }
              </ng-container>
              <ng-container *ngSwitchCase="'liabilityDetails'">
                @if (subTab.items) {
                  <liability-details
                    [workOrders]="workOrders"
                    [item]="subTab"></liability-details>
                }
              </ng-container>
              <ng-container *ngSwitchCase="'overview'">
                @if (subTab.items) {
                  <overview [item]="subTab"></overview>
                }
              </ng-container>
              <ng-container *ngSwitchCase="'work-order-list'">
                @if (subTab.items) {
                  <work-order-list
                    [item]="subTab"
                    (recoverableLineItems)="
                      calculateFinalRecoverableCostUsd($event)
                    "></work-order-list>
                }
              </ng-container>
              <ng-container *ngSwitchCase="'vendor-recoverable-cost'">
                @if (subTab.items) {
                  <vendor-recoverable-cost
                    [item]="subTab"></vendor-recoverable-cost>
                }
              </ng-container>
            </ng-container>
          }
        </ng-container>
      }
    }
  </div>
  <div class="actions" *ngIf="currentTabIndex == 0">
    <ng-container>
      <ng-container
        *ngIf="{
          value: enableNextBtn$ | async
        } as isNextButtonEnabled">
        <ng-container
          *ngIf="{
            value: hideNextBtn$ | async
          } as isNextButtonHidden">
          <mc-button
            appearance="primary"
            fit="medium"
            label="Next"
            [disabled]="!isNextButtonEnabled.value || isSaveOperationInProgress"
            [hidden]="isNextButtonHidden.value"
            (click)="onNextClick()"></mc-button>
        </ng-container>
      </ng-container>
      <mc-button
        *ngIf="currentStageId !== 1"
        fit="medium"
        appearance="neutral"
        label="Back"
        (click)="onBackClick()"></mc-button>
    </ng-container>

    <mc-button
      *ngIf="enableCloseBtn$ | async"
      fit="medium"
      appearance="neutral"
      label="Close Case"
      (click)="closeCase = true"></mc-button>
  </div>
</ng-template>

<ng-template #closeCaseTemplate>
  <ng-container *ngIf="closeCase" [formGroup]="closeCaseForm">
    <mc-modal
      [open]="closeCase"
      escapecloseactiondisabled
      backdropcloseactiondisabled
      heading="Close Case"
      [height]="
        closeCaseForm.get('closeReason')?.value == OTHERS ? '350px' : '240px'
      "
      dimension="small"
      fit="small"
      (closed)="closeCase = false"
      (closing)="closeCase = false">
      <mc-select
        ngDefaultControl
        label="Reason for closing case"
        placeholder="Select a reason"
        formControlName="closeReason"
        optionswidth="trigger">
        <ng-container *ngFor="let reason of cancelReasons">
          <mc-option [value]="reason">{{ reason }}</mc-option>
        </ng-container>
      </mc-select>
      <div
        *ngIf="closeCaseForm.get('closeReason')?.value == OTHERS"
        style="padding-top: 2px">
        <mc-textarea
          ngDefaultControl
          label="Comments"
          formControlName="comments"
          placeholder="Enter a reason for closing the case"></mc-textarea>
      </div>

      <mc-button
        focusstartanchor
        slot="primaryAction"
        appearance="primary"
        (click)="closingCase()">
        Submit
      </mc-button>
      <mc-button
        focusendanchor
        slot="secondaryAction"
        appearance="neutral"
        variant="outlined"
        (click)="closeCase = false">
        Cancel
      </mc-button>
    </mc-modal>
  </ng-container>
</ng-template>

<ng-template #pageLoader>
  <lib-page-skeleton-loader></lib-page-skeleton-loader>
</ng-template>
