<div class="dialog" *ngIf="{ value: openDrawer$| async} as openDrawer">
    <ng-container *ngIf="openDrawer.value">
        <mc-drawer dimension="small" fit="small" (closed)="close()" [open]="openDrawer.value" position="right">
            <span slot="heading">Help</span>
            <div class="mc-drawer-css">
                <div class="font-small d-flex" (click)="openRedirectUrl('User Guide')">
                    <a rel="noreferrer">User Guide</a>
                    <mc-icon icon="square-arrow-up-right">
                    </mc-icon>
                </div>
                <div class="font-small d-flex" (click)="openRedirectUrl('View All')">
                    <a rel="noreferrer">FAQ Troubleshooting</a>
                    <mc-icon icon="square-arrow-up-right">
                    </mc-icon>
                </div>
                <div class="question-section">
                    <h6>Common Questions</h6>
                    <div class="font-small border-line">How should I Login to DCRP?</div>
                    <div class="font-small border-line">Why is my filters not showing the correct data?</div>
                    <div class="font-small border-line">Can I close my case at any stage?
                    </div>
                    <div class="text-center font-small">
                        <a rel="noreferrer" (click)="openRedirectUrl('View All')">View All</a>
                    </div>
                </div>
                <div class="label">
                    <h6>Can we help?</h6>
                </div>
                <div class="button-section gap-15">
                    <div class="report button-report-feedback report-div font-small">
                        <div class="icon-center"><mc-icon icon="question-circle">
                            </mc-icon></div>
                        <div class="d-flex justify-center" (click)="openRedirectUrl('Report an issue')">
                            <a class="text-center" rel="noreferrer">Report an issue</a>
                            <mc-icon icon="square-arrow-up-right">
                            </mc-icon>
                        </div>

                    </div>
                    <div class="feedback button-report-feedback feedback-div font-small">
                        <div class="icon-center"><mc-icon icon="info-circle">
                            </mc-icon> </div>
                        <a class="text-center" href="#" target="_blank" rel="noreferrer">Share Feedback ?</a>
                    </div>
                </div>
            </div>
        </mc-drawer>
    </ng-container>
</div>