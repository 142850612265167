<lib-panel>
  <header>{{ item.label }}</header>
  <content *ngIf="cpInformation$ | async; else sectionloading">
    <div>
      <app-lib-form [items]="items"></app-lib-form>
    </div>
  </content>
  <ng-template #sectionloading>
    <lib-section-skeleton-loader></lib-section-skeleton-loader>
  </ng-template>
</lib-panel>
