/**
 * CC DCRP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpEvent,
  HttpParams,
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { ClaimReferenceDocumentRequest } from '../../models/claimReferenceDocumentRequest';
import { ClaimReferenceDocumentResponse } from '../../models/claimReferenceDocumentResponse';
import { DocumentDeleteRequest } from '../../models/documentDeleteRequest';

import { environment } from '../../../../environments/environment';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { AttachedFileDtoIEnumerableResponse } from '../../models/attachedFileDtoIEnumerableResponse';
import { StorageType } from '../../models/storageType';

@Injectable()
export class CaseDocumentService {
  protected basePath = environment.dcrp_api_base_url;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {}

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param body
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsCaseDocumentDelete(
    body?: DocumentDeleteRequest,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ClaimReferenceDocumentResponse>;
  public customerRecoveryClaimsCaseDocumentDelete(
    body?: DocumentDeleteRequest,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ClaimReferenceDocumentResponse>>;
  public customerRecoveryClaimsCaseDocumentDelete(
    body?: DocumentDeleteRequest,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ClaimReferenceDocumentResponse>>;
  public customerRecoveryClaimsCaseDocumentDelete(
    body?: DocumentDeleteRequest,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<ClaimReferenceDocumentResponse>(
      'delete',
      `${this.basePath}/customer-recovery-claims/case-document`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsCaseDocumentPost(
    body?: ClaimReferenceDocumentRequest,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ClaimReferenceDocumentResponse>;
  public customerRecoveryClaimsCaseDocumentPost(
    body?: ClaimReferenceDocumentRequest,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ClaimReferenceDocumentResponse>>;
  public customerRecoveryClaimsCaseDocumentPost(
    body?: ClaimReferenceDocumentRequest,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ClaimReferenceDocumentResponse>>;
  public customerRecoveryClaimsCaseDocumentPost(
    body?: ClaimReferenceDocumentRequest,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<ClaimReferenceDocumentResponse>(
      'post',
      `${this.basePath}/customer-recovery-claims/case-document`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param caseId
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsCaseIdCaseDocumentGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ClaimReferenceDocumentResponse>;
  public customerRecoveryClaimsCaseIdCaseDocumentGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ClaimReferenceDocumentResponse>>;
  public customerRecoveryClaimsCaseIdCaseDocumentGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ClaimReferenceDocumentResponse>>;
  public customerRecoveryClaimsCaseIdCaseDocumentGet(
    caseId: number,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (caseId === null || caseId === undefined) {
      throw new Error(
        'Required parameter caseId was null or undefined when calling customerRecoveryClaimsCaseIdCaseDocumentGet.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // authentication (Bearer) required
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<ClaimReferenceDocumentResponse>(
      'get',
      `${this.basePath}/customer-recovery-claims/${encodeURIComponent(String(caseId))}/case-document`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Upload documents.
   * This method handles the HTTP POST request to upload a document. It validates the model state and processes the document saving operation.  If the upload is successful, it returns a response with the attached file information. Otherwise, it returns an error response.
   * @param storageType
   * @param files
   * @param aPIVersion
   * @param appName
   * @param subDirectory
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsCaseDocumentUploadPostForm(
    storageType: StorageType,
    files?: File[],
    aPIVersion?: string,
    appName?: string,
    subDirectory?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<AttachedFileDtoIEnumerableResponse>;
  public customerRecoveryClaimsCaseDocumentUploadPostForm(
    storageType: StorageType,
    files?: File[],
    aPIVersion?: string,
    appName?: string,
    subDirectory?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<AttachedFileDtoIEnumerableResponse>>;
  public customerRecoveryClaimsCaseDocumentUploadPostForm(
    storageType: StorageType,
    files?: File[],
    aPIVersion?: string,
    appName?: string,
    subDirectory?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<AttachedFileDtoIEnumerableResponse>>;
  public customerRecoveryClaimsCaseDocumentUploadPostForm(
    storageType: StorageType,
    files?: File[],
    aPIVersion?: string,
    appName?: string,
    subDirectory?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (storageType === null || storageType === undefined) {
      throw new Error(
        'Required parameter storageType was null or undefined when calling customerRecoveryClaimsCaseDocumentUploadPost.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (appName !== undefined && appName !== null) {
      queryParameters = queryParameters.set('AppName', <any>appName);
    }
    if (subDirectory !== undefined && subDirectory !== null) {
      queryParameters = queryParameters.set('SubDirectory', <any>subDirectory);
    }
    if (storageType !== undefined && storageType !== null) {
      queryParameters = queryParameters.set('StorageType', <any>storageType);
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    const formData = new FormData();
    Array.from(files ?? [])?.forEach((file: File) => {
      formData.append('Files', file);
    });

    return this.httpClient.request<AttachedFileDtoIEnumerableResponse>(
      'post',
      `${this.basePath}/customer-recovery-claims/case-document/upload`,
      {
        body: formData,
        params: queryParameters,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
