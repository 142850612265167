<lib-panel>
  <header>{{ item.label }}</header>
  <left-corner-menu>
    <div *ngIf="containerMovesItem && !disable">
      <a (click)="showHideContainerMovesLink()" class="header-link">
        {{
          showContainerMoves ? 'Hide container moves' : 'View container moves'
        }}
      </a>
    </div>
  </left-corner-menu>
  <content>
    <ng-container
      *ngIf="
        { value: shouldShowNotification$ | async } as shouldShowNotification;
        else sectionloading
      ">
      <mc-notification
        *ngIf="shouldShowNotification.value"
        body="System could not fetch booking details automatically, please select a different container move or check CP detail, POL and POD manually"
        appearance="warning">
        <span slot="icon"><mc-icon icon="exclamation-triangle"></mc-icon></span>
      </mc-notification>
      <app-lib-form [items]="items"></app-lib-form>
    </ng-container>
    <div
      [style.display]="showContainerMoves ? 'block' : 'none'"
      class="sub-fields">
      <div class="moves-filter">
        <mc-input-date
          ngDefaultControl
          formControlName="checkin-date"
          label=""
          startofweek="1"
          format="DD/MM/YYYY"
          locale="en-IN"
          nextlabel="Next month"
          previouslabel="Previous month"
          hiddenlabel
          placeholder="DD/MM/YYYY"
          [disabled]="disable"
          fit="small"
          width="232px"
          [value]="selectedFromDate"
          (input)="OnDateSelectHandler('from', $event)"></mc-input-date>

        <mc-input-date
          ngDefaultControl
          formControlName="checkin-date"
          label=""
          startofweek="1"
          format="DD/MM/YYYY"
          locale="en-IN"
          nextlabel="Next month"
          previouslabel="Previous month"
          hiddenlabel
          placeholder="DD/MM/YYYY"
          [disabled]="disable"
          fit="small"
          width="232px"
          [value]="selectedToDate"
          (input)="OnDateSelectHandler('to', $event)"></mc-input-date>

        <mc-button
          label="Update moves"
          fit="small"
          [disabled]="disable"
          (click)="updateMoves()"></mc-button>
        <mc-button
          label="Cancel"
          fit="small"
          appearance="neutral"
          [disabled]="disable"
          (click)="setDateRangeForContainerMoves()"></mc-button>

        <span
          class="errorMessage"
          [style.display]="invalidDateSelected ? 'block' : 'none'"
          style="color: red">
          Invalid date format selected
        </span>
      </div>
      <div class="movesData">
        <container-moves
          [item]="containerMovesItem"
          [fromDate]="containerMovesFromDate!"
          [toDate]="containerMovesToDate!"
          [disable]="disable"
          [containerNumber]="containerNumber"
          (onDataLoad)="onContainerMovesDataFetched($event)"
          (onRowSelected)="OnMovesRowSelectionChanged($event)"
          [loadData]="showContainerMoves"></container-moves>
      </div>
    </div>
  </content>
</lib-panel>

<div *ngIf="updateBookingNumberModal">
  <mc-modal
    [open]="updateBookingNumberModal"
    escapecloseactiondisabled
    backdropcloseactiondisabled
    heading="Update Booking Number"
    dimension="small"
    fit="medium"
    (closed)="updateBookingNumberModal = false; bookingNumberErrorMsg = ''"
    (closing)="updateBookingNumberModal = false; bookingNumberErrorMsg = ''">
    {{ moves_confirmation_msg }}
    <mc-button
      focusstartanchor
      slot="primaryAction"
      appearance="primary"
      [loading]="updateBookingNumberLoader"
      (click)="updateRecoveryInfo()">
      Update
    </mc-button>
    <mc-button
      focusendanchor
      slot="secondaryAction"
      appearance="neutral"
      variant="outlined"
      [disabled]="updateBookingNumberLoader"
      (click)="updateBookingNumberModal = false">
      Cancel
    </mc-button>
  </mc-modal>
</div>

<ng-template #sectionloading>
  <lib-section-skeleton-loader></lib-section-skeleton-loader>
</ng-template>
