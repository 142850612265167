import { Injectable } from '@angular/core';
import { IMenuProvider, Menu } from '@maersk-global/angular-shared-library';
import { CookieService } from 'ngx-cookie-service';
import { AuthHelper } from '../../../helper/auth-helper';
import { HelpAndFaqService } from '../help-and-faq/help-and-faq.service';

@Injectable({
  providedIn: 'root',
})
export class HeaderMenuService implements IMenuProvider {
  private _authHelper: AuthHelper = new AuthHelper(this._cookieService);
  constructor(
    private _cookieService: CookieService,
    private _helpAndFaq: HelpAndFaqService
    // Inject User Auth Service to figure out which menus are accessible to the logged in user
  ) {}

  getMenu(): Menu[] {
    return [
      {
        onClick: () => {
          this._authHelper.logout();
        },
        icon: 'door-arrow-right',
        sequence: 4,
      },
      {
        icon: 'user-circle',
        sequence: 3,
        disabled: true,
      },
      {
        icon: 'bell',
        sequence: 2,
        disabled: true,
      },
      {
        icon: 'question-circle',
        sequence: 1,
        disabled: false,
        subMenus: [
          {
            displayName: 'Help',
            group: 2,
            onClick: () => {
              this._helpAndFaq.openDrawer();
            },
            disabled: false,
          },
          {
            displayName: 'Release Updates',
            trailingIcon: 'square-arrow-up-right',
            disabled: true,
          },
          {
            displayName: 'Training',
            trailingIcon: 'square-arrow-up-right',
            disabled: true,
          },
          {
            displayName: 'Share Feedback',
            group: 3,
            disabled: true,
          },
          {
            displayName: 'Report An Issue',
            trailingIcon: 'square-arrow-up-right',
            group: 3,
            onClick: () => {
              window.open(
                'https://maersk.service-now.com/end_user_portal?id=report_issue&sys_id=e0a0678cc34d46d491873a0c050131ce'
              );
            },
            disabled: false,
          },
        ],
      },
    ];
  }
}
