export default Object.freeze({
  hideColumns: {
    0: ['invoiceStatus'],
    1: [],
    2: ['invoiceStatus'],
  },
  filterConfig: [
    {
      name: 'id',
      filtername: 'caseId',
      type: 'inputText',
      placeholder: 'Type to search the options',
      groupName: 'Recovery Identifiers',
      groupId: 1,
      sequence: 0,
    },
    {
      name: 'recoveryCaseNumber',
      filtername: 'recoveryCaseNumber',
      type: 'inputText',
      placeholder: 'Type to search the options',
      groupName: 'Recovery Identifiers',
      groupId: 1,
      sequence: 1,
    },
    {
      name: 'containerNumber',
      filtername: 'containerNumber',
      type: 'inputText',
      placeholder: 'Type to search the options',
      groupName: 'Recovery Identifiers',
      groupId: 1,
      sequence: 2,
    },
    {
      name: 'woNumber',
      filtername: 'workOrderNumber',
      type: 'inputText',
      placeholder: 'Type to search the options',
      groupName: 'Recovery Identifiers',
      groupId: 1,
      sequence: 3,
    },
    {
      name: 'bookingNumber',
      filtername: 'bookingNumber',
      type: 'inputText',
      placeholder: 'Type to search the options',
      groupName: 'Recovery Identifiers',
      groupId: 1,
      sequence: 4,
    },
    {
      name: 'invoiceNumber',
      filtername: 'invoiceNumber',
      type: 'inputText',
      placeholder: 'Enter the Invoice Number',
      groupName: 'Recovery Identifiers',
      groupId: 1,
      sequence: 5,
    },
    {
      name: 'woMode',
      filtername: 'woMode',
      type: 'multi-select',
      groupName: 'Recovery Identifiers',
      groupId: 1,
      sequence: 6,
    },
    {
      name: 'claimStatusId',
      filtername: 'statusIds',
      type: 'multi-select',
      groupName: 'Status & Priority',
      groupId: 2,
      sequence: 1,
    },
    {
      name: 'aboveCoverageBand',
      filtername: 'aboveCoverageBand',
      type: 'select',
      groupName: 'Status & Priority',
      groupId: 2,
      sequence: 3,
    },
    {
      name: 'workOrderStatusCode',
      filtername: 'workOrderStatusCode',
      type: 'multi-select',
      groupName: 'Status & Priority',
      groupId: 2,
      sequence: 4,
    },
    {
      name: 'podRegion',
      filtername: 'podRegions',
      type: 'multi-select',
      groupName: 'Location',
      groupId: 3,
      sequence: 1,
    },
    {
      name: 'podCluster',
      filtername: 'podClusters',
      type: 'multi-select',
      groupName: 'Location',
      groupId: 3,
      sequence: 2,
    },
    {
      name: 'podCountryName',
      filtername: 'podCountries',
      type: 'multi-select',
      groupName: 'Location',
      groupId: 3,
      sequence: 3,
    },
    {
      name: 'shopCode',
      filtername: 'shopCodes',
      type: 'multi-select',
      groupName: 'Location',
      groupId: 3,
      sequence: 5,
    },
    {
      name: 'woCreatedOn',
      type: 'multi-input-date',
      placeholder: 'DD-MM-YYYY',
      groupName: 'Date Range',
      groupId: 4,
      options: [
        {
          label: 'From',
          name: 'dateFrom',
          value: '',
        },
        {
          label: 'To',
          name: 'dateTo',
          value: '',
        },
      ],
      sequence: 1,
    },
    {
      name: 'importReturn',
      type: 'multi-checkbox',
      options: [
        {
          label: 'Yes',
          name: 'yes',
          value: 'yes',
        },
        {
          label: 'No',
          name: 'no',
          value: 'no',
        },
      ],
      groupName: 'Logistic/Operations',
      groupId: 5,
      filtername: 'importReturn',
      sequence: 2,
    },
    {
      name: 'assignedToName',
      filtername: 'workOrderAssignedUsers',
      type: 'multi-select',
      groupName: 'Status & Priority',
      groupId: 2,
      sequence: 5,
    },
    {
      name: 'shopCountryCode',
      filtername: 'shopCountryCode',
      type: 'multi-select',
      groupName: 'Location',
      groupId: 3,
      sequence: 4,
    },
    {
      name: 'customerGateInLapseDays',
      filtername: 'gateInLapseDaysBand',
      type: 'select',
      groupName: 'Recovery Identifiers',
      groupId: 1,
      sequence: 7,
      options: [
        {
          label: '0-10 days',
          name: '0-10',
          value: '0-10',
        },
        {
          label: '10-20 days',
          name: '10-20',
          value: '10-20',
        },
        {
          label: '20-50 days',
          name: '20-50',
          value: '20-50',
        },
        {
          label: '50-100 days',
          name: '50-100',
          value: '50-100',
        },
        {
          label: '100-200 days',
          name: '100-200',
          value: '100-200',
        },
        {
          label: '200-500 days',
          name: '200-500',
          value: '200-500',
        },
        {
          label: '500-1000 days',
          name: '500-1000',
          value: '500-1000',
        },
        {
          label: '>1000 days',
          name: '1000-99999',
          value: '1000-99999',
        },
      ],
    },
    {
      name: 'imageCoverage',
      filtername: 'imageCoverage',
      type: 'select',
      groupName: 'Recovery Identifiers',
      groupId: 1,
      sequence: 8,
      options: [
        {
          label: 'Yes',
          name: 'available',
          value: '1',
        },
        {
          label: 'No',
          name: 'notAvailable',
          value: '4',
        },
      ],
    },
    {
      name: 'createdBy',
      filtername: 'createdBy',
      type: 'multi-select',
      groupName: 'Status & Priority',
      groupId: 2,
      sequence: 6,
    },
  ],
});
