import {
  Component,
  Input,
  CUSTOM_ELEMENTS_SCHEMA,
  Output,
  EventEmitter,
} from '@angular/core';
import { WorkOrderAndLineItemsService } from '../../../common/services/customer-recovery/work-order-and-line-items.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { CommonModule } from '@angular/common';
import {
  ALIGN,
  GridComponent,
  GridRowData,
  GridCellData,
  GridColumnSchema,
  LibFormComponent,
  PanelComponent,
} from '@maersk-global/angular-shared-library';
import { WorkOrderLineItemDto } from '../../../common/models/workOrderLineItemDto';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { workOrderStatusType } from '../../../common/constants/temporary-constant';
import {
  DOLLAR,
  HYPHEN,
  ZERO_STRING,
} from '../../../common/constants/app.constants';
import { WorkOrderDto } from '../../../common/models/WorkOrderDto';
import { SharedDataService } from '../../../shared-data-service';
import { SharedVendorRecoveryCaseService } from '../../vendor-recovery/shared-vendor-recovery-case.service';

const WORK_ORDER_STATUS_CODE = 'workOrderStatusCode';
const ALL_COST_FIELDS_ARRAY = ['workOrderCostUSD'];

@Component({
  selector: 'work-order-list',
  standalone: true,
  imports: [CommonModule, LibFormComponent, GridComponent, PanelComponent],
  templateUrl: './work-order-list.component.html',
  styleUrl: './work-order-list.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class WorkOrderListComponent {
  @Input() item!: TemplateModel;
  @Output() recoverableLineItems = new EventEmitter<
    { [key: string]: unknown }[]
  >();

  items: TemplateModel[] = [];
  apiVersion = '1.0';
  currentWorkOrder$: Observable<WorkOrderDto | null> | undefined;
  workOrderList$: Observable<WorkOrderDto[] | null> | undefined;
  lineItemsGridSchema: GridColumnSchema[] | undefined = [];
  lineItemsGridData: GridRowData[][] = [];
  showWorkOrderItems: boolean[] = [];
  workOrdersConverted: TemplateModel[][] = [];
  containerNumber: string | undefined;
  workOrderDate: Date | undefined;
  selectedFromDate: string | undefined;
  selectedToDate: string | undefined;
  invalidDateSelected: boolean = false;
  selectedIndex = 0;
  currentWorkOrderNumber: number | undefined;
  parsedFromDate: Date | null | undefined;
  parsedToDate: Date | null | undefined;

  constructor(
    private _workOrderService: WorkOrderAndLineItemsService,
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _sharedDataService: SharedDataService,
    private _sharedVendorRecoveryCaseService: SharedVendorRecoveryCaseService
  ) {}

  /**
   * This method is triggered when the component initializes.
   * It initializes items.
   */

  customerRecoveryData$ =
    this._sharedRecoveryCaseService.recoveryCaseData$.pipe(
      tap((customerData) => {
        this.containerNumber = customerData?.containerNumber;
        this.workOrderDate = customerData?.workOrderDate;
        this.currentWorkOrderNumber = customerData?.workOrderNumber;
        this.setInitialDateRangeForWorkOrders();
        this.initializeItems();
        this.fetchWorkOrders();
      })
    );

  /**
   * Initializes items based on the input data.
   */
  private initializeItems() {
    this.loadLineItemsGridSchema();
    this.items =
      this.item?.items?.filter((o) => o.name == 'workOrders')[0]?.items || [];
  }

  /**
   * Loads line item grid schema as per template
   */
  private loadLineItemsGridSchema() {
    this.lineItemsGridSchema = this.item?.items
      ?.filter((o) => o.name == 'workOrderLineItems')[0]
      .items?.map((y: TemplateModel) => {
        const column = {
          column: y.name,
          displayName: y.label,
          align: ALIGN.LEFT,
          hidden: false,
          sequence: y.sequence,
          columnType: y.valueType?.toUpperCase(),
          disableSort: true,
        } as GridColumnSchema;
        return column;
      });
  }

  /**
   * Fetches work orders from the service.
   */
  private fetchWorkOrders() {
    this.fetchCurrentWorkOrder();
    this.fetchWorkOrderHistory();
  }

  /**
   * Fetches current work order details for selected case
   */
  private fetchCurrentWorkOrder() {
    this.currentWorkOrder$ = this._workOrderService
      .workOrdersGet(
        this.currentWorkOrderNumber,
        undefined,
        undefined,
        undefined,
        this.apiVersion
      )
      .pipe(
        map((response) => (response?.data ? response.data[0] : null)),
        tap((response) => {
          if (!response) return;
          this.mapWorkOrderToAlignWithUI(response, 0);
        })
      );
  }

  /**
   * Fetches work order history based on container number, from date and to date.
   */
  private fetchWorkOrderHistory() {
    this.validateAndSetSearchDateRange();
    this.workOrderList$ = this._workOrderService
      .workOrdersGet(
        undefined,
        this.containerNumber,
        this.parsedFromDate ?? undefined,
        this.parsedToDate ?? undefined,
        this.apiVersion
      )
      .pipe(
        map((response) =>
          response?.data
            ? response.data.filter(
                (wo) => wo.workOrderNumber != this.currentWorkOrderNumber
              )
            : null
        ),
        tap((response) => {
          response?.forEach((workorder, index) => {
            this.mapWorkOrderToAlignWithUI(workorder, index + 1);
          });
        })
      );
  }

  /**
   * Fetches work order history based on search criteria
   * Hides line items for all previous work orders
   */
  searchWorkOrders() {
    this.fetchWorkOrderHistory();

    this.showWorkOrderItems.forEach((showItem, index) => {
      if (index != 0) this.showWorkOrderItems[index] = false;
    });
  }

  /**
   * Updates the values of items based on the provided work order data.
   * @param workOrder The work order data containing item values.
   */
  private updateItemValues(workOrder: WorkOrderDto) {
    // Iterate over each item in the items array
    this.items.forEach((item: TemplateModel) => {
      // Cast the workOrder to a generic object for dynamic property access
      const customerRecoveryKeyValue = workOrder as unknown as {
        [key: string]: unknown;
      };

      // Update item value based on the item's name
      if (item.name == WORK_ORDER_STATUS_CODE) {
        // If the item's name is the work order status code, find the corresponding status
        const workOrderStatus = workOrderStatusType.find(
          (o) => o.value === customerRecoveryKeyValue[item.name]
        );

        // Set item value to the status label or '-' if not found
        item.value = workOrderStatus?.label ?? HYPHEN;
      } else {
        // For other items, set item value directly from work order data
        item.value = customerRecoveryKeyValue[item.name || ''];

        // If item value is falsy, set it to '-' or '0' if it's a number
        if (!item.value) {
          item.value = item.value === 0 ? DOLLAR + ZERO_STRING : HYPHEN;
        } else if (ALL_COST_FIELDS_ARRAY.includes(item.name)) {
          // If item name is in cost fields array, prepend '$' to the value
          item.value =
            DOLLAR + this._sharedDataService.formatDecimal(item.value);
        }
      }
    });
  }

  /**
   * This method transforms an array of line items received from the API response into an array of grid row objects.
   * @param lineItems Array of original objects received from the web API response
   * @returns Array of grid row objects created
   */
  private generateGridDataFromLineItems(
    lineItems: WorkOrderLineItemDto[] | undefined
  ): GridRowData[] {
    if (!lineItems || lineItems.length < 1) return [];
    return lineItems.map((lineItem) => {
      const lineItemKeyValue = lineItem as unknown as {
        [key: string]: unknown;
      };
      const gridRowObject: { [key: string]: GridCellData } = {};
      Object.keys(lineItem).map((key) => {
        if (key == 'isRecoverable') {
          gridRowObject[key] = {
            value: lineItemKeyValue[key] ? 'Y' : 'N',
          } as GridCellData;
        } else {
          gridRowObject[key] = {
            value: lineItemKeyValue[key],
          } as GridCellData;
        }
      });
      return {
        row: gridRowObject,
        isRowSelectionDisabled: false,
      } as GridRowData;
    });
  }

  /**
   * Shows/hides line items for selected work order
   * Binds line item data to grid
   * @param index Index of selected work order
   * @param lineItems List of line items for selected work order
   */
  showHideWorkOrderItemsLink(
    index: number,
    lineItems: WorkOrderLineItemDto[] | undefined
  ) {
    this.showWorkOrderItems[index] = !this.showWorkOrderItems[index];

    if (this.showWorkOrderItems[index]) {
      this.lineItemsGridData[index] =
        this.generateGridDataFromLineItems(lineItems);
    }
  }

  /**
   * Maps work order data to Template model
   * @param workOrder Work order data
   * @param index Index of selected work order
   */
  private mapWorkOrderToAlignWithUI(workOrder: WorkOrderDto, index: number) {
    // Update item values based on workOrder properties
    this.updateItemValues(workOrder);

    // Deep clone and assign updated items to orders
    this.workOrdersConverted[index] = this._sharedDataService.deepClone(
      this.items
    );
  }

  /**
   * Sets default date range filter for fetching work order history
   */
  setInitialDateRangeForWorkOrders() {
    let dateToFetchWorkOrders = new Date();
    const toDatePlusVal = 120;

    if (
      this.workOrderDate &&
      this.workOrderDate.toString() != '0001-01-01T00:00:00'
    ) {
      dateToFetchWorkOrders = new Date(this.workOrderDate);
    }

    this.parsedToDate = new Date(dateToFetchWorkOrders.getTime());
    this.parsedFromDate = new Date(dateToFetchWorkOrders.getTime());

    this.parsedFromDate.setUTCDate(
      this.parsedFromDate.getUTCDate() - toDatePlusVal
    );

    const formatDateOptions = {
      timeZone: 'UTC',
    };

    this.selectedFromDate = this.parsedFromDate.toLocaleDateString(
      'en-GB',
      formatDateOptions
    );
    this.selectedToDate = this.parsedToDate.toLocaleDateString(
      'en-GB',
      formatDateOptions
    );
  }

  /**
   * Sets from/to date based on user selection
   * @param type Type of date - From/To
   * @param event Selected date value
   */
  dateSelected(type: string, event: any): void {
    if (type == 'from') {
      this.selectedFromDate = event.target.value;
    } else if (type == 'to') {
      this.selectedToDate = event.target.value;
    } else {
      return;
    }

    this.invalidDateSelected =
      !this._sharedDataService.getUtcDateFromDDMMYYYY(this.selectedFromDate!) ||
      !this._sharedDataService.getUtcDateFromDDMMYYYY(this.selectedToDate!);
  }

  /**
   * Sets from and to date for work order search
   */
  private validateAndSetSearchDateRange() {
    this.parsedFromDate = this.selectedFromDate
      ? this._sharedDataService.getUtcDateFromDDMMYYYY(this.selectedFromDate)
      : null;
    this.parsedToDate = this.selectedToDate
      ? this._sharedDataService.getUtcDateFromDDMMYYYY(this.selectedToDate)
      : null;

    this.invalidDateSelected =
      !this.parsedFromDate ||
      !this.parsedToDate ||
      this.parsedFromDate > this.parsedToDate;

    if (this.invalidDateSelected || !this.containerNumber) return;

    //Checking that To date should not be greater than current time
    if (this.parsedToDate) {
      this.parsedToDate = this._sharedDataService.changeUtcTime(
        this.parsedToDate,
        23,
        59,
        59
      );
      if (this.parsedToDate > new Date()) {
        this.parsedToDate = new Date();
      }
    }
  }

  /**
   * Sets currently selected work order index and resets other grids.
   * @param event Radio button selection event
   */
  workOrderSelected(event: any) {
    this.selectedIndex = event.target.value;

    //Clearing line item selection for all grids
    this.lineItemsGridData.forEach((lineItem, lineItemIndex) => {
      this.lineItemsGridData[lineItemIndex].forEach((row, rowIndex) => {
        this.lineItemsGridData[lineItemIndex][rowIndex].isRowSelected = false;
      });
      this.lineItemsGridData[lineItemIndex] = [
        ...this.lineItemsGridData[lineItemIndex],
      ];
    });

    //Emitting empty line item list
    this.lineItemSelectionChanged([]);
  }

  /**
   * Emits event containing list of selected work order line items
   * @param gridRows List of selected work order line items
   */
  lineItemSelectionChanged(gridRows: { [key: string]: unknown }[]) {
    this.recoverableLineItems.emit(gridRows);
    this._sharedVendorRecoveryCaseService.updateRecoverableWorkOrderLineItems(
      gridRows
    );
  }
}
