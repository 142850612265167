/**
 * CC DCRP API
 * Connected Claims Damaged Container Recovery API's.
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { ContainerMoveDtoResponse } from '../../models/containerMoveDtoResponse';

@Injectable()
export class ContainersService {
  protected basePath = environment.dcrp_api_base_url;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {}

  /**
   * Retrieves the container move details for a given container number and query parameters.
   * This API endpoint supports HTTP GET requests and is versioned to API version 1.0.  It retrieves the container move details for the specified containerNumber.  The param parameter is used to filter the container move details.  If the model state is not valid, a bad request response is returned.  If an exception occurs during the execution of the method, an internal server error response is returned.
   * @param containerNumber The container number.
   * @param containerNumber
   * @param fromDateTime
   * @param toDateTime
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public containersContainerNumberMovesGet(
    containerNumber: string,
    fromDateTime?: Date,
    toDateTime?: Date,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ContainerMoveDtoResponse>;
  public containersContainerNumberMovesGet(
    containerNumber: string,
    fromDateTime?: Date,
    toDateTime?: Date,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ContainerMoveDtoResponse>>;
  public containersContainerNumberMovesGet(
    containerNumber: string,
    fromDateTime?: Date,
    toDateTime?: Date,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ContainerMoveDtoResponse>>;
  public containersContainerNumberMovesGet(
    containerNumber: string,
    fromDateTime?: Date,
    toDateTime?: Date,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (containerNumber === null || containerNumber === undefined) {
      throw new Error(
        'Required parameter containerNumber was null or undefined when calling containersContainerNumberMovesGet.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (containerNumber !== undefined && containerNumber !== null) {
      queryParameters = queryParameters.set(
        'ContainerNumber',
        <any>containerNumber
      );
    }
    if (fromDateTime !== undefined && fromDateTime !== null) {
      queryParameters = queryParameters.set(
        'FromDateTime',
        <any>fromDateTime.toISOString()
      );
    }
    if (toDateTime !== undefined && toDateTime !== null) {
      queryParameters = queryParameters.set(
        'ToDateTime',
        <any>toDateTime.toISOString()
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    return this.httpClient.request<ContainerMoveDtoResponse>(
      'get',
      `${this.basePath}/containers/${encodeURIComponent(String(containerNumber))}/moves`,
      {
        params: queryParameters,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
