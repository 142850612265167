import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { PanelComponent } from '@maersk-global/angular-shared-library';

@Component({
  selector: 'completed',
  standalone: true,
  imports: [PanelComponent],
  templateUrl: './completed.component.html',
  styleUrl: './completed.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CompletedComponent {}
