import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit } from '@angular/core';
import {
  CellClickEvent,
  GridCellData,
  GridColumnSchema,
  GridComponent,
  GridFilter,
  GridRowData,
  SizeUnit,
  SurveyComponent,
  Toast,
  ToasterService,
} from '@maersk-global/angular-shared-library';
import { CustomerRecoveryClaimService } from '../../common/services/customer-recovery/customer-recovery-claim.service';
import { CommonModule } from '@angular/common';
import { CommonService } from '../../common/services/customer-recovery/common.service';
import { ClaimStatusRequest } from '../../common/models/claim-status-request';
import { ClaimStatus } from '../../common/enum/claim-status';
import { ClaimStatusResponse } from '../../common/models/claim-status-response';
import { StatusDetailModel } from '../../common/models/status-detail';
import { UserClaimsAssignmentRequest } from '../../common/models/user-claims-assignment-request';
import { UserPreferencesService } from '../../common/services/user-preferences/user-preferences.service';
import { WorkflowService } from '../../common/services/customer-recovery/workflow.service';
import { Router } from '@angular/router';
import { Observable, catchError, filter, firstValueFrom, tap } from 'rxjs';
import {
  statusTypeMaster,
  workflowStages,
} from '../../common/constants/temporary-constant';
import { UserClaimsUnassignmentRequest } from '../../common/models/user-claims-unassignment-request';
import { WorkOrderAndLineItemsService } from '../../common/services/customer-recovery/work-order-and-line-items.service';
import { SharedRecoveryCaseService } from '../../shared-recovery-case-service';
import { CreateManualCaseComponent } from '../custom-workflow/create-manual-case/create-manual-case.component';
import { environment } from '../../../environments/environment';
import { SharedDataService } from '../../shared-data-service';
import { VendorRecoveryDataManager } from './vendor-recovery-data-manager';

@Component({
  selector: 'vendor-recovery',
  standalone: true,
  imports: [GridComponent, CommonModule, CreateManualCaseComponent],
  templateUrl: './vendor-recovery.component.html',
  styleUrl: './vendor-recovery.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    CustomerRecoveryClaimService,
    CommonService,
    UserPreferencesService,
  ],
})
export class VendorRecoveryComponent {}
