export const environment = {
  production: false,
  environment: 'cdt',

  //configure API
  url: 'https://wapazewtmlit007rcmeccoreapi.azurewebsites.net/api/Login/GetKeysFromJwkUriToValidateSession',
  dcrp_api_base_url: 'https://api-cdt.maersk.com/ccdcrp',

  //Forgerock config
  consumer_key: 'TpZjSVg2GZDTGM8yKYQQAIFTTq0tSPeR',
  access_token_api: 'https://api-cdt.maersk.com/oauth2/access_token',
  cookie_id_token_store_name: 'rcmlpMaerskUser',
  iam_key: 'al.https://accounts-cdt.maersk.com/thirdparty-cdt.thirdparty.',
  workflow_api_base_url:
    'https://wapazewtmlit016workflowapi.azurewebsites.net/',
  //Application login URL
  login_url: 'https://ccdcrp-cdt.maersk.com',
  isSiteUndermaintenance: false,
  localTemplate: true,
  enableCaseWorkflow: true,
  disableFileUpload: false,
  sessionTimeoutTime: 119 * 60 * 1000, // 15 minutes
  searchIntervalForManualCase: 90,
  searchIntervalForContainerMoves: 15,
  api_key_rum:
    'v74f0gLNCHGfzigDXby50QcD++qTfxgnm3aSLXDQzWRlKLUK83wzbRyXXV7cNA==',
  FAROENVIRONMENT: 'cdt',
  IN_SIGHT_FEEDBACK_ID: '4b8ba5f5-bea7-4b8b-9391-99bca83881e3',
};
