import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, Input } from '@angular/core';
import {
  LibFormComponent,
  ModalNotificationService,
  PanelComponent,
  SectionSkeletonLoaderComponent,
} from '@maersk-global/angular-shared-library';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { DropDownOption } from '@maersk-global/angular-shared-library/lib/models/drop-down';
import { DELETEDAMAGEMSG } from '../../../common/constants/app.constants';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  lastValueFrom,
  map,
  merge,
  shareReplay,
  tap,
} from 'rxjs';
import { CaseDamageDetailDto } from '../../../common/models/caseDamageDetailDto';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { CustomerRecoveryClaimService } from '../../../common/services/customer-recovery/customer-recovery-claim.service';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { Components } from '../../../common/constants/temporary-constant';
import { SharedCustomerRecoveryCaseService } from '../../customer-recovery/shared-customer-recovery-case.service';
import { SharedDataService } from '../../../shared-data-service';
@Component({
  selector: 'damage-details',
  standalone: true,
  imports: [
    CommonModule,
    LibFormComponent,
    PanelComponent,
    SectionSkeletonLoaderComponent,
  ],
  templateUrl: './damage-details.component.html',
  styleUrl: './damage-details.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DamageDetailsComponent {
  @Input() item!: TemplateModel;
  disable: boolean = false;
  items: TemplateModel[] = [];
  damageItemSchema?: TemplateModel;
  defaultLineItem: TemplateModel[] = [];
  additionalLineItem: TemplateModel[] = [];
  apiVersion: string = '1.0';
  /**
   * Subject to hold the latest list of assigned damage details.
   */
  damageLineItemToCaseSubject$$: BehaviorSubject<TemplateModel[] | null> =
    new BehaviorSubject<TemplateModel[] | null>([]);
  /**
   * Initial list of assigned case images which we get from the API.
   */
  lineItemInitial$?: Observable<TemplateModel[] | null>;

  /**
   * Main Observable to hold the latest list of damage detail line item.
   */
  damageData$?: Observable<TemplateModel[] | null>;

  deleteItem!: TemplateModel;
  damageIndex: number = -1;
  totalAmount: number = 0;
  totalAmountUSD: number = 0;
  currencyCode: string = 'USD';
  exchangeRate: number = 1.0;
  caseDetail!: CustomerRecoveryCaseDto | undefined;
  enableAddNewDamageBtn: boolean = false;

  formIndexBeingUpdated: number = 0;
  constructor(
    private _modalService: ModalNotificationService,
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _customerRecoveryService: CustomerRecoveryClaimService,
    private _sharedDataService: SharedDataService,
    private _customerRecoverySharedService: SharedCustomerRecoveryCaseService
  ) {}

  customerRecoveryData$ = combineLatest([
    this._sharedRecoveryCaseService.recoveryCaseData$,
    this._sharedDataService.materialCodesAsDropdownOptions$,
    this._customerRecoverySharedService.disableForm$,
  ]).pipe(
    tap(([recoveryData, materialCodes, disable]) => {
      this.caseDetail = recoveryData;
      this.disable = disable;
      this.initDamageDetails(materialCodes);
      this.loadDamageDetails();
    })
  );

  initDamageDetails(materialCodes: DropDownOption[]) {
    this.item.items?.forEach((element) => {
      if (element.type == 'select') {
        element.options = materialCodes;
      }
    });
    if (
      this.caseDetail?.caseCurrency &&
      this.caseDetail?.exchangeRateUSDCaseCurrency
    ) {
      this.currencyCode = this.caseDetail?.caseCurrency;
      this.exchangeRate = this.caseDetail?.exchangeRateUSDCaseCurrency;
    }
  }

  /**
   * We are fetching already assigned damages details to the current case (if any) and then showing the damages details in the lists.
   * @param changes changes
   */
  loadDamageDetails(): void {
    this.lineItemInitial$ = this._sharedRecoveryCaseService.damageDetails$.pipe(
      map((response) => {
        if (!response || response.length === 0) {
          const damageItem = this.generateDamageDetailsItem(undefined, 0);
          return [damageItem];
        }
        return response.map((damage, index) =>
          this.generateDamageDetailsItem(damage, index)
        );
      }),
      shareReplay(1),
      tap((lineItems) => this.damageLineItemToCaseSubject$$.next(lineItems))
    );

    this.damageData$ = merge(
      this.lineItemInitial$,
      this.damageLineItemToCaseSubject$$.asObservable()
    ).pipe(
      tap((items) => {
        if (items) {
          this.totalAmount = this.getTotalAmount(items);
          this.totalAmountUSD =
            Math.round(this.totalAmount * 100 * this.exchangeRate) / 100;
        }
      })
    );
  }

  generateDamageDetailsItem(
    damage?: CaseDamageDetailDto,
    index?: number
  ): TemplateModel {
    const damageKeyValue = damage as {
      [key: string]: unknown;
    };
    return {
      label: this.item?.label,
      disabled: this.disable,
      type: this.item?.type,
      name: this.item?.name,
      id: damageKeyValue ? damageKeyValue['id'] : null,
      items: this.item?.items?.map((formItem) =>
        this.getFormTemplateItemFromLineItem(formItem, damageKeyValue, index)
      ),
    } as TemplateModel;
  }

  getFormTemplateItemFromLineItem(
    formItem: TemplateModel,
    dataValue: {
      [key: string]: unknown;
    },
    index: number = -1
  ) {
    return {
      label: formItem.label?.replace('Local', this.currencyCode),
      name: formItem.name,
      type: formItem.type,
      disabled: this.disable,
      value: dataValue ? dataValue[formItem.name] : null,
      valueType: formItem.valueType,
      width:
        this.currencyCode === 'USD' ? { size: 33, unit: '%' } : formItem.width,
      hidden:
        formItem.name === 'amount' && this.currencyCode === 'USD'
          ? true
          : formItem.hidden,
      sequence: formItem.sequence,
      hideLabel: index == 0 ? false : true,
      labelPosition: formItem.labelPosition,
      placeHolder: formItem.placeHolder,
      options: formItem.options,
      onValueChanged: (value) =>
        this.onFormFieldValueChanged.call(this, { ...formItem, value: value }),
      isMandatory: formItem.isMandatory,
    } as TemplateModel;
  }

  getTotalAmount(items: TemplateModel[]): number {
    let amount = 0;
    items.forEach((i) => {
      const amountField = i.items?.filter(
        (x) => x.name == 'amountInCaseCurrency'
      )[0];
      amount += amountField?.value ? parseFloat(amountField?.value) : 0;
    });
    return Math.round(amount * 100) / 100;
  }

  addDamageItem() {
    const newDamageItem = this.generateDamageDetailsItem();
    const existingDamageItem = this.damageLineItemToCaseSubject$$.value;
    existingDamageItem?.push(newDamageItem);
    if (existingDamageItem) {
      this.damageLineItemToCaseSubject$$.next(existingDamageItem);
      this.reassignDamageItems(existingDamageItem);
    }
  }

  deleteDamageItem(item: TemplateModel, index: number) {
    this.deleteItem = item;
    this.damageIndex = index;
    let valid: boolean = false;
    item.items?.forEach((i) => {
      //if data is not entered or if line item is empty
      if (!i.hidden && i.value) {
        valid = true;
      }
    });
    if (valid) {
      this._modalService.openModal({
        primaryButtonMessage: 'Delete',
        secondaryButtonMessage: 'Cancel',
        message: DELETEDAMAGEMSG,
        header: 'Delete Damage',
        dimension: 'small',
        onPrimaryBtnClick: this.confirmDelete.bind(this),
      });
    } else {
      this.confirmDelete();
    }
  }

  confirmDelete() {
    let latestItem = null;
    const existingDamageItem = this.damageLineItemToCaseSubject$$.value;

    if (this.deleteItem.id != null) {
      //if lineItem has id or if line item exist already
      latestItem = existingDamageItem?.filter(
        (i) => i.id != this.deleteItem.id
      );
    } else if (this.deleteItem.id == null) {
      // newly created line item
      latestItem = existingDamageItem?.filter(
        (i, index) => index != this.damageIndex
      );
    }
    if (latestItem) {
      this.damageLineItemToCaseSubject$$.next(latestItem);
      this._sharedRecoveryCaseService.updateFormValidationState({
        component: Components.DamageDetailsComponent,
        state: true,
      });
      this.reassignDamageItems(latestItem!);
    }
  }

  reassignDamageItems(items: TemplateModel[] | null) {
    items?.[0].items?.forEach((i) => {
      i.hideLabel = false;
    });
    let isValidItems = true;
    // check if all the items are valid
    items?.forEach((i) => {
      if (i.items && !this.validateDamageLineItem(i.items)) {
        isValidItems = false;
        return;
      }
    });
    this.enableAddNewDamageBtn = this.totalAmount > 0 && isValidItems!;
  }

  onFormFieldValueChanged(item: TemplateModel) {
    const lineItems =
      this.damageLineItemToCaseSubject$$.value?.[this.formIndexBeingUpdated];
    //Handling text changes in case currency amount textbox
    if (item.name == 'amountInCaseCurrency') {
      //Total amounts to be calculated only when either number is entered or textbox is cleared
      if (!item.value || !isNaN(item.value)) {
        if (this.damageLineItemToCaseSubject$$.value)
          this.totalAmount = this.getTotalAmount(
            this.damageLineItemToCaseSubject$$.value
          );
        this.totalAmountUSD =
          Math.round(this.totalAmount * 100 * this.exchangeRate) / 100;
      }

      //Calculating USD amount
      const amountUSD = lineItems?.items?.filter(
        (x: any) => x.name == 'amount'
      )[0];
      if (!amountUSD) return;
      if (item.value && !isNaN(item.value)) {
        amountUSD.value =
          Math.round(item.value * 100 * this.exchangeRate) / 100;
      } else {
        amountUSD.value = null;
        //If non-numeric value is entered, then total amounts will be updated as 0.
        if (isNaN(item.value)) {
          this.totalAmount = 0;
          this.totalAmountUSD = 0;
        }
      }
    }
  }

  /**
   * Validates if the total cost is above 0 or not. Based on that, next button will be enabled/disabled.
   */
  formValidityChanged(isValid: boolean, updatingIndex: number) {
    this.formIndexBeingUpdated = updatingIndex;
    // TODO: tactical fix, This will be removed once we have shared library bug fixed for validation.
    setTimeout(() => {
      this._sharedRecoveryCaseService.updateFormValidationState({
        component: Components.DamageDetailsComponent,
        state: this.totalAmount > 0 && isValid,
      });
      this.enableAddNewDamageBtn = this.totalAmount > 0 && isValid;
    }, 200);
  }

  async saveDamageDetailsToServer() {
    const damageDetailRequest: CaseDamageDetailDto[] = [];
    const currentItems = this.damageLineItemToCaseSubject$$.value;

    currentItems?.forEach((damage: TemplateModel) => {
      if (
        !damage.hidden &&
        damage.items &&
        this.validateDamageLineItem(damage.items)
      )
        damageDetailRequest.push({
          description: damage.items?.filter((i) => i.name == 'description')[0]
            .value,
          amount: damage.items?.filter((i) => i.name == 'amount')[0].value,
          materialCode: damage.items?.filter((i) => i.name == 'materialCode')[0]
            .value,
          id: damage.items?.filter((i) => i.name == 'id')[0].value,
          caseId: this.caseDetail?.caseId,
          amountInCaseCurrency: damage.items?.filter(
            (i) => i.name == 'amountInCaseCurrency'
          )[0].value,
        });
    });

    const saveDamage = await lastValueFrom(
      this._customerRecoveryService
        .customerRecoveryClaimsDamageDetailsSavePost(damageDetailRequest)
        .pipe(map((response) => response))
    );

    if (!saveDamage.data || !saveDamage.data.isDataUpdated) return;

    this._sharedRecoveryCaseService.reloadDamageDetails();
  }

  validateDamageLineItem(items: TemplateModel[]): boolean {
    let valid = false;
    items?.forEach((i: TemplateModel) => {
      //if data is not entered or if line item is empty
      if (!i.hidden && i.value) {
        valid = true;
      }
    });
    return valid;
  }
}
