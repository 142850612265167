/**
 * CC DCRP API
 * Connected Claims Damaged Container Recovery API's.
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { EmailTemplateDtoIEnumerableResponse } from '../../models/emailTemplateDtoIEnumerableResponse';
import { BooleanResponse } from '../../models/booleanResponse';
import { EmailTemplateDtoResponse } from '../../models/emailTemplateDtoResponse';
import { EmailTemplateDto } from '../../models/emailTemplateDto';

@Injectable()
export class EmailTemplateService {
  protected basePath = environment.dcrp_api_base_url;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {}

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Returns all email templates.
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public emailTemplateGet(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<EmailTemplateDtoIEnumerableResponse>;
  public emailTemplateGet(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<EmailTemplateDtoIEnumerableResponse>>;
  public emailTemplateGet(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<EmailTemplateDtoIEnumerableResponse>>;
  public emailTemplateGet(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<EmailTemplateDtoIEnumerableResponse>(
      'get',
      `${this.basePath}/email-template`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Retrieves an email templates by Country Cluster Code.
   * Sample request:        GET country-cluster/{code}    Returns the email template specified by ItemType
   * @param code Country Cluster Code
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public emailTemplateCountryClusterCodeGet(
    code: string,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<EmailTemplateDtoIEnumerableResponse>;
  public emailTemplateCountryClusterCodeGet(
    code: string,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<EmailTemplateDtoIEnumerableResponse>>;
  public emailTemplateCountryClusterCodeGet(
    code: string,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<EmailTemplateDtoIEnumerableResponse>>;
  public emailTemplateCountryClusterCodeGet(
    code: string,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (code === null || code === undefined) {
      throw new Error(
        'Required parameter code was null or undefined when calling emailTemplateCountryClusterCodeGet.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    return this.httpClient.request<EmailTemplateDtoIEnumerableResponse>(
      'get',
      `${this.basePath}/email-template/country-cluster/${encodeURIComponent(String(code))}`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Delete an email template.
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public emailTemplateIdDelete(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BooleanResponse>;
  public emailTemplateIdDelete(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BooleanResponse>>;
  public emailTemplateIdDelete(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BooleanResponse>>;
  public emailTemplateIdDelete(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling emailTemplateIdDelete.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<BooleanResponse>(
      'delete',
      `${this.basePath}/email-template/${encodeURIComponent(String(id))}`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get an email template by Id.
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public emailTemplateIdGet(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<EmailTemplateDtoResponse>;
  public emailTemplateIdGet(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<EmailTemplateDtoResponse>>;
  public emailTemplateIdGet(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<EmailTemplateDtoResponse>>;
  public emailTemplateIdGet(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling emailTemplateIdGet.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<EmailTemplateDtoResponse>(
      'get',
      `${this.basePath}/email-template/${encodeURIComponent(String(id))}`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Create new email template.
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public emailTemplatePost(
    body: EmailTemplateDto,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BooleanResponse>;
  public emailTemplatePost(
    body: EmailTemplateDto,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BooleanResponse>>;
  public emailTemplatePost(
    body: EmailTemplateDto,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BooleanResponse>>;
  public emailTemplatePost(
    body: EmailTemplateDto,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling emailTemplatePost.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<BooleanResponse>(
      'post',
      `${this.basePath}/email-template`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update an email template
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public emailTemplatePut(
    body: EmailTemplateDto,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BooleanResponse>;
  public emailTemplatePut(
    body: EmailTemplateDto,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BooleanResponse>>;
  public emailTemplatePut(
    body: EmailTemplateDto,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BooleanResponse>>;
  public emailTemplatePut(
    body: EmailTemplateDto,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling emailTemplatePut.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<BooleanResponse>(
      'put',
      `${this.basePath}/email-template`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
