import { CUSTOM_ELEMENTS_SCHEMA, Component, ViewChild } from '@angular/core';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { CommonModule } from '@angular/common';
import { AttachmentComponent } from '../../custom-workflow/attachment/attachment.component';
import { OverviewComponent } from '../../custom-workflow/overview/overview.component';
import { BookingInfoComponent } from '../../custom-workflow/booking-info/booking-info.component';
import { ContainerInfoComponent } from '../../custom-workflow/container-info/container-info.component';
import { CargoDetailsComponent } from '../../custom-workflow/cargo-details/cargo-details.component';
import { CustomerRecoveryClaimService } from '../../../common/services/customer-recovery/customer-recovery-claim.service';
import {
  Observable,
  catchError,
  combineLatest,
  firstValueFrom,
  lastValueFrom,
  map,
  of,
  tap,
} from 'rxjs';
import { gcssBookingInfo } from '../../../common/models/gcssBookingInfo';
import { LiabilityDetailsComponent } from '../../custom-workflow/liability-details/liability-details.component';
import { DamageDetailsComponent } from '../../custom-workflow/damage-details/damage-details.component';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { CaseDetailsDto } from '../../../common/models/caseDetailsDto';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { WorkflowService } from '../../../common/services/customer-recovery/workflow.service';
import { CompleteTaskRequestDTO } from '../../../common/models/completeTaskRequestDTO';
import { Router } from '@angular/router';
import { ActivityLogComponent } from '../../custom-workflow/activity-log/activity-log.component';
import { WorkOrdersComponent } from '../../custom-workflow/work-orders/work-orders.component';
import {
  Loader,
  LoaderService,
  PageSkeletonLoaderComponent,
  Toast,
  ToasterService,
} from '@maersk-global/angular-shared-library';
import { LiablePartyDetailsComponent } from '../../custom-workflow/liable-party-details/liable-party-details.component';
import { WorkOrderAndLineItemsDto } from '../../../common/models/workOrderAndLineItemsDto';
import { ClaimStatusRequest } from '../../../common/models/claim-status-request';
import { LiabilityLetterComponent } from '../../custom-workflow/liability-letter/liability-letter.component';
import { LiabilityLettersLogComponent } from '../../custom-workflow/liability-letters-log/liability-letters-log.component';
import { SendMailRequest } from '../../../common/models/sendMailRequest';
import { InvoiceLettersLogComponent } from '../../custom-workflow/invoice-letters-log/invoice-letters-log.component';
import { CompletedComponent } from '../../custom-workflow/completed/completed.component';
import { CaseAssignmentStatusResponse } from '../../../common/models/caseAssignmentStatusResponse';
import { InvoiceSummaryComponent } from '../../custom-workflow/invoice-summary/invoice-summary.component';
import { InvoiceLetterComponent } from '../../custom-workflow/invoice-letter/invoice-letter.component';
import {
  CancelReasonsList,
  CaseTypeEnum,
  workflowStages,
} from '../../../common/constants/temporary-constant';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ClaimStatusResponse } from '../../../common/models/claim-status-response';
import { WorkFlowStage } from '../../../common/models/workflowStage';
import { CaseInvoiceDetailDto } from '../../../common/models/caseInvoiceDetailDto';
import { InvoiceStatusEnum } from '../../../common/models/invoiceStatusEnum';
import { ManualEstimatesComponent } from '../../custom-workflow/manual-estimates/manual-estimates.component';
import { LiabilityDetailsManualEstimatesComponent } from '../../custom-workflow/liability-details-manual-estimates/liability-details-manual-estimates.component';
import { CpInfoComponent } from '../../custom-workflow/cp-info/cp-info.component';
import { AUTO_CLOSE_REASON } from '../../../common/constants/app.constants';
import { CountryDto } from '../../../common/models/countryDto';
import { StepperComponent } from '../../custom-workflow/stepper/stepper.component';
import { CustomerRecoveryOrchestratorWorkflowManager } from './customer-recovery-orchestrator-workflow-manager';
import { recoveryTab } from '../../../common/enum/recovery-tab';
import { SharedCustomerRecoveryCaseService } from '../shared-customer-recovery-case.service';
import { SharedDataService } from '../../../shared-data-service';
@Component({
  selector: 'customer-recovery-orchestrator',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    StepperComponent,
    AttachmentComponent,
    OverviewComponent,
    BookingInfoComponent,
    CpInfoComponent,
    ContainerInfoComponent,
    CargoDetailsComponent,
    LiabilityDetailsComponent,
    DamageDetailsComponent,
    ManualEstimatesComponent,
    ActivityLogComponent,
    WorkOrdersComponent,
    LiablePartyDetailsComponent,
    LiabilityLetterComponent,
    LiabilityLettersLogComponent,
    InvoiceLettersLogComponent,
    CompletedComponent,
    InvoiceLetterComponent,
    InvoiceSummaryComponent,
    LiabilityDetailsManualEstimatesComponent,
    PageSkeletonLoaderComponent,
  ],
  templateUrl: './customer-recovery-orchestrator.component.html',
  styleUrl: './customer-recovery-orchestrator.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
})
export class CustomerRecoveryOrchestratorComponent {
  stages: TemplateModel[] | undefined;
  anchorTaskId?: string;
  anchorStageId: number = 1;
  @ViewChild('letterComponent', { static: false })
  letterComponent!: LiabilityLetterComponent;

  @ViewChild('invoice', { static: false })
  invoiceLetterComponent!: InvoiceLetterComponent;

  @ViewChild('party', { static: false })
  partyComponent!: LiablePartyDetailsComponent;

  @ViewChild('damageDetails', { static: false })
  damageDetailsComponent!: DamageDetailsComponent;

  currentStageId: number = 1;
  bookingCargoDetail!: gcssBookingInfo;
  caseDetails!: CustomerRecoveryCaseDto;
  apiVersion: string = '1.0';
  caseNumber: string = '';
  currentTabIndex: number = 0;
  tabItems?: TemplateModel[];
  workOrders!: WorkOrderAndLineItemsDto[];
  nextStageId: number = 0;
  userId: string = sessionStorage.getItem('userId') || '';
  liabilityEmailContent?: SendMailRequest;
  LIABILITY_STAGE_ID: number = 3;
  DAMAGE_ESTIMATION_STAGE_ID: number = 2;
  NEW_STAGE_ID: number = 1;
  loadTab: boolean = true;
  closeCase: boolean = false;
  cancelReasons: string[] = CancelReasonsList;
  currentStageItem: TemplateModel[] = [];
  closeCaseForm: FormGroup = new FormGroup({
    closeReason: new FormControl('', { updateOn: 'change' }),
    comments: new FormControl('', { updateOn: 'change' }),
  });
  closedStatusId: number = 6;
  isSaveOperationInProgress: boolean = false;
  OTHERS: string = 'Others';
  closeReason: string = '';

  orchestratorWorkFlowManger?: CustomerRecoveryOrchestratorWorkflowManager;
  tabName$: Observable<string> =
    this._sharedRecoveryCaseService.currentTabIndex$.pipe(
      map((index) => recoveryTab[index])
    );

  constructor(
    private _customerRecoveryService: CustomerRecoveryClaimService,
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _workflowService: WorkflowService,
    private _toaster: ToasterService,
    private _loader: LoaderService,
    private _router: Router,
    private _sharedDataService: SharedDataService,
    private _customerRecoverySharedService: SharedCustomerRecoveryCaseService
  ) {}

  hasIssuedLiabilityLetters$: Observable<boolean> =
    this._sharedRecoveryCaseService.liabilityLetters$.pipe(
      map((letters) => !!letters && letters.length > 0)
    );

  disableForm$: Observable<boolean> =
    this._customerRecoverySharedService.disableForm$;
  enableCloseBtn$: Observable<boolean> =
    this._customerRecoverySharedService.enableCloseBtn$;
  reopenCase$: Observable<boolean> =
    this._customerRecoverySharedService.reopenCase$;
  enableNextBtn$: Observable<boolean> =
    this._customerRecoverySharedService.enableNextBtn$;
  hideNextBtn$: Observable<boolean> =
    this._customerRecoverySharedService.hideNextBtn$;

  shouldShowLiabilityDetails$: Observable<boolean> =
    this._sharedRecoveryCaseService.shouldShowLiabilityDetails$;

  shouldShowIssueInvoiceScreen$: Observable<boolean> =
    this._sharedRecoveryCaseService.shouldShowIssueInvoiceScreen$;

  countries: CountryDto[] = [];

  anchorDetails$: Observable<boolean> = combineLatest([
    this._customerRecoverySharedService.anchorFlowTaskId$,
    this._customerRecoverySharedService.anchorStageId$,
    this._customerRecoverySharedService.anchorTaskIdLoaded$,
  ]).pipe(
    tap(([taskId, anchorStageId]) => {
      this.anchorTaskId = taskId;
      this.anchorStageId = anchorStageId;
    }),
    map(([taskId, anchorStageId, loaded]) => (taskId && loaded ? true : false))
  );

  customerRecoveryData$ =
    this._sharedRecoveryCaseService.recoveryCaseData$.pipe(
      tap((recoveryData) => {
        //updating Current StageId on initial load only once.
        this.currentStageId =
          recoveryData?.recoveryStatusId == 6 ||
          recoveryData?.recoveryStatusId == 7
            ? !recoveryData.workFlowStage
              ? 1
              : workflowStages[recoveryData?.workFlowStage]
            : recoveryData?.recoveryStatusId ?? 1;
        this._customerRecoverySharedService.updateCurrentStageId(
          this.currentStageId as number
        );

        this.caseDetails = recoveryData!;
        this.caseNumber = recoveryData?.recoveryCaseNumber ?? '';
        this.closeReason =
          recoveryData?.recoveryStatusId === 7
            ? `Reason: ${AUTO_CLOSE_REASON}`
            : recoveryData?.cancellationReason === this.OTHERS
              ? `Reason: ${recoveryData?.cancellationReason ?? ''} - ${recoveryData.comments ?? ''}`
              : `Reason: ${recoveryData?.cancellationReason ?? ''}`;
      })
    );

  currentStageId$ = this._sharedRecoveryCaseService.currentStageId$.pipe(
    tap((newStageId) => {
      this.currentStageId = newStageId;
      this.orchestratorWorkFlowManger =
        new CustomerRecoveryOrchestratorWorkflowManager(
          this._workflowService,
          this._customerRecoveryService,
          this._sharedRecoveryCaseService,
          this._customerRecoverySharedService,
          this.currentStageId,
          this.caseDetails
        );
      this.loadTabInitialData(this.currentStageId);
    })
  );

  isCountryS4HanaMigrated$: Observable<boolean> = combineLatest([
    this._sharedRecoveryCaseService.recoveryCaseData$,
    this._sharedDataService.countries$,
  ]).pipe(
    map(
      ([customerRecoveryData, countries]) =>
        !!countries.find(
          (country) =>
            country.code === customerRecoveryData?.podCountryCode &&
            country.isS4HanaMigrated
        )
    )
  );

  onNavigateBackToCaseList() {
    this._router.navigate(['customer-recovery']);
  }

  loadTabInitialData(stageId: number): void {
    this.stages = this.orchestratorWorkFlowManger?.stages;
    this.currentTabIndex = 0;
    const caseStageTemplate = this.stages?.filter(
      (i) => i.id === stageId
    )[0] as TemplateModel;
    this.currentStageItem =
      caseStageTemplate.items?.filter((i) => i.name === 'tab')[0].items ?? [];
    if (this.currentStageItem)
      this.tabItems = this.currentStageItem[this.currentTabIndex].items;
    const currentStageSequence =
      this.stages?.filter((i) => i.id == stageId)[0].sequence ?? 0;
    if (
      this.stages &&
      (this.stages[this.stages.length - 1].sequence ?? 0) > currentStageSequence
    )
      this.nextStageId =
        this.stages?.filter((i) => i.sequence == currentStageSequence + 1)[0]
          .id ?? 1;
  }

  async onNextClick() {
    const disableForm = await firstValueFrom(
      this._customerRecoverySharedService.disableForm$
    );
    if (disableForm) {
      this.loadNextStage(false);
      return;
    }

    this.isSaveOperationInProgress = true;
    this._loader.showLoader({
      label: 'Loading',
      size: 'medium',
    } as Loader);

    const shouldShowLiabilityDetails =
      (await firstValueFrom(this.shouldShowLiabilityDetails$)) ?? false;
    const shouldShowIssueInvoiceScreen =
      (await firstValueFrom(this.shouldShowIssueInvoiceScreen$)) ?? false;

    const isCountryS4HanaMigrated =
      (await firstValueFrom(this.isCountryS4HanaMigrated$)) ?? false;

    // Adding this try catch to make sure we will always have the next button enabled if save operation fails.
    // TODO: The error handling needs to be re-looked for all the API calls.
    try {
      if (this.currentStageId === 1) {
        await this.stageOverviewSaveAndSubmit();
      } else if (this.currentStageId === 2) {
        await this.stageDamageEstimationSaveAndSubmit();
      } else if (this.currentStageId === 3 && shouldShowLiabilityDetails) {
        await this.stageLiabilitySave();
      } else if (this.currentStageId === 3 && !shouldShowLiabilityDetails) {
        await this.stageSubmit();
      } else if (this.currentStageId === 4 && !isCountryS4HanaMigrated) {
        await this.stageSubmit();
      } else if (
        this.currentStageId === 4 &&
        shouldShowIssueInvoiceScreen &&
        isCountryS4HanaMigrated
      ) {
        await this.createInvoice();
      } else if (
        this.currentStageId === 4 &&
        !shouldShowIssueInvoiceScreen &&
        isCountryS4HanaMigrated
      ) {
        let latestInvoices = await firstValueFrom(
          this._sharedRecoveryCaseService.invoices$
        );
        latestInvoices = this.sortInvoicesByCreatedDateDesc(latestInvoices);

        if (
          latestInvoices[0].invoiceStatusCode &&
          [
            InvoiceStatusEnum.InvoiceIssued,
            InvoiceStatusEnum.PaymentReceived,
          ].includes(latestInvoices[0].invoiceStatusCode)
        ) {
          await this.stageSubmit();
        } else {
          this._toaster.showToast({
            message:
              'You can only complete the stage if the invoice has been issued or the payment has been received.',
            type: 'warning',
          });
        }
      }
    } catch (error) {
      console.error('Error occurred while saving data');
    } finally {
      this.isSaveOperationInProgress = false;
      this._loader.hideLoader();
    }
  }

  async createInvoice() {
    await this.invoiceLetterComponent.createInvoice();
  }

  async stageOverviewSaveAndSubmit() {
    //overview details update
    const customerRecoveryCaseDto = await firstValueFrom(
      this._sharedRecoveryCaseService.recoveryCaseData$
    );

    if (!customerRecoveryCaseDto) return;

    await this.assignCurrentCaseToTheCurrentUser(customerRecoveryCaseDto);

    const currentItems = this.currentStageItem[this.currentTabIndex];
    const overViewDetails = currentItems.items?.filter(
      (item) => item.name == 'overview'
    )[0];
    const countries = await firstValueFrom(this._sharedDataService.countries$);
    overViewDetails?.items?.forEach((i: TemplateModel) => {
      switch (i.name) {
        case 'dateOfIncident':
          customerRecoveryCaseDto.dateOfIncident =
            this.convert_dd_mm_yyyy_into_dateFormat(i.value);

          break;
        case 'placeOfIncident':
          customerRecoveryCaseDto.placeOfIncident = i.value;
          break;
        case 'exchangerate':
          if (i.value)
            customerRecoveryCaseDto.exchangeRateUSDCaseCurrency = (
              i.value as string
            ).split(' ')[0] as unknown as number;
          break;
        case 'podLocalCurrency':
          if (i.value) customerRecoveryCaseDto.caseCurrency = i.value;
          break;
        case 'podCountryClusterCode':
          const selectedCluster = i.options?.find((c) => c.value == i.value);
          if (selectedCluster) {
            customerRecoveryCaseDto.podCountryClusterCode =
              selectedCluster?.value as string;
            customerRecoveryCaseDto.podCountryClusterName =
              selectedCluster?.label;
          }
          break;
        case 'podCountryCode':
          const selectedCountry = countries?.find((c) => c.code == i.value);
          if (selectedCountry) {
            customerRecoveryCaseDto.podCountryCode = selectedCountry.code;
            customerRecoveryCaseDto.podCountryId = selectedCountry.id;
            customerRecoveryCaseDto.podCountryName = selectedCountry.name;
          }
          break;
        case 'operatorCode':
          customerRecoveryCaseDto.operatorCode = i?.value as string;
          break;
      }
    });
    if (this.caseDetails?.recoveryCaseNumber)
      customerRecoveryCaseDto.recoveryCaseNumber =
        this.caseDetails?.recoveryCaseNumber;
    customerRecoveryCaseDto.workFlowVersion = 1; //temp version hard coded. When we get template this should be removed.
    const caseRequest = {
      customerRecoveryCaseDto: customerRecoveryCaseDto,
    } as CaseDetailsDto;

    const saveSuccess = await firstValueFrom(
      this._customerRecoveryService.customerRecoveryClaimsUpdatePost(
        caseRequest,
        this.apiVersion
      )
    );
    this._sharedRecoveryCaseService.reloadRecoveryCaseData();
    if (this.currentStageId == this.anchorStageId) {
      await this.movingStageNextOrBackWithAnchorFlow(
        {
          IsSubmit: true,
          TaskId: this.anchorTaskId,
        } as CompleteTaskRequestDTO,
        this.nextStageId,
        {
          claimStatusId: this.nextStageId,
          userName: this.userId,
          updatedDttm: new Date(),
          comment: customerRecoveryCaseDto?.comments ?? '',
          cancelationReason: customerRecoveryCaseDto?.cancellationReason ?? '',
          workFlowStage: workflowStages[this.nextStageId],
        } as ClaimStatusRequest
      );
    } else {
      this._sharedRecoveryCaseService.reloadDamageDetails();
      this.loadNextStage(false);
    }
  }

  /**
   * Assign this case to the current user if it is not already assigned.
   * @param customerRecoveryCaseDto CustomerRecoveryCaseDto
   * @returns Promise
   */
  async assignCurrentCaseToTheCurrentUser(
    customerRecoveryCaseDto: CustomerRecoveryCaseDto
  ) {
    if (
      customerRecoveryCaseDto.assignedToUID === this.userId ||
      !customerRecoveryCaseDto.recoveryCaseNumber ||
      !this.caseDetails
    )
      return;

    const cases = [];
    const groupedCases = await lastValueFrom(
      this._customerRecoveryService.customerRecoveryClaimsListByGroupIdPost(
        [customerRecoveryCaseDto.groupId ?? ''],
        this.apiVersion
      )
    );
    if (groupedCases && !!groupedCases.customerRecoveryDetails) {
      cases.push(
        ...groupedCases.customerRecoveryDetails.map(
          (recoveryCase: CustomerRecoveryCaseDto) =>
            recoveryCase.recoveryCaseNumber
        )
      );
    }
    await lastValueFrom(
      this._customerRecoveryService
        .customerRecoveryClaimsCaseAssignmentPost(
          {
            assignedToUid: this.userId,
            recoveryCaseNumbers: cases,
            updatedBy: this.userId,
            updatedDttm: new Date(),
          },
          this.apiVersion
        )
        .pipe(
          tap((response: CaseAssignmentStatusResponse) => {
            if (
              response &&
              response.status?.statusCode == 200 &&
              response.caseAssignmentStatus?.isUpdateSuccessful &&
              this.caseDetails
            ) {
              this.caseDetails.assignedToUID = this.userId;
            }
          }),
          catchError((error: any) => {
            //returning true as we need to proceed other execution flow.
            return of(true);
          })
        )
    );
  }

  async stageDamageEstimationSaveAndSubmit() {
    const cpDetails = await firstValueFrom(
      this._customerRecoverySharedService.cpInformation$
    );
    const recoveryDetails = this.caseDetails;
    if (
      cpDetails &&
      recoveryDetails &&
      cpDetails.cpCoverageCurrency == 'USD' &&
      this._customerRecoverySharedService.caseType ==
        CaseTypeEnum.CaseWithOutWorkOrder
    ) {
      recoveryDetails.aboveCoverageCostUSD = cpDetails.aboveCoverageCostUSD;
      recoveryDetails.recoverableCostUSD = cpDetails.recoverableCostUSD;
      recoveryDetails.withinCoverageCostUSD = cpDetails.withinCoverageCostUSD;
    }

    // If case does not have work order, save the case details as well as case details will have changes in that.
    if (
      this._customerRecoverySharedService.caseType !=
      CaseTypeEnum.CaseWithWorkOrder
    ) {
      await firstValueFrom(
        this._customerRecoveryService.customerRecoveryClaimsUpdatePost(
          {
            customerRecoveryCaseDto: recoveryDetails,
          } as CaseDetailsDto,
          this.apiVersion
        )
      );
      this._sharedRecoveryCaseService.reloadRecoveryCaseData();
    }
    await this.damageDetailsComponent.saveDamageDetailsToServer();
    await this.stageSubmit();
  }

  async stageLiabilitySave() {
    //save liabilityParty after success send mail
    const response = await this.partyComponent.saveLiabilityParties();
    if (response.isSuccess) {
      await this._sharedRecoveryCaseService.reloadLiabilityPartyDetails();
      this.currentStageItem[this.currentTabIndex].items?.forEach(
        (i) => (i.loaded = false)
      );
      await this.letterComponent.sendMail();
    }
  }

  async stageSubmit() {
    const statusReq = {
      claimStatusId: this.nextStageId,
      userName: this.userId,
      comment: this.caseDetails.comments ?? '',
      cancelationReason: this.caseDetails.cancellationReason ?? '',
      updatedDttm: new Date(),
      workFlowStage: workflowStages[this.nextStageId],
    } as ClaimStatusRequest;
    const nextData = {
      IsSubmit: true,
      TaskId: this.anchorTaskId,
    } as CompleteTaskRequestDTO;
    if (this.currentStageId == this.anchorStageId) {
      await this.movingStageNextOrBackWithAnchorFlow(
        nextData,
        this.nextStageId,
        statusReq
      );
    } else {
      this.loadNextStage(false);
    }
  }

  async closingCase() {
    const { closeReason, comments } = this.closeCaseForm.value;
    const closeRequest = {
      userName: this.userId,
      cancelationReason: closeReason,
      comment:
        closeReason != this.OTHERS
          ? this.caseDetails.comments ?? ''
          : comments ?? '',
      claimStatusId: this.closedStatusId,
      updatedDttm: new Date(),
      workFlowStage: this._customerRecoverySharedService.getWorkFlowStageById(
        this.caseDetails.recoveryStatusId ?? 1
      ),
    };
    this.claimStatusUpdate(
      closeRequest,
      true,
      this.caseDetails.recoveryStatusId === 1 || false
    );
  }

  reOpeningCase() {
    const closeRequest = {
      userName: this.userId,
      cancelationReason: '',
      comment: this.caseDetails.comments ?? '',
      claimStatusId: this.caseDetails.workFlowStage
        ? workflowStages[this.caseDetails.workFlowStage]
        : 1,
      updatedDttm: new Date(),
      workFlowStage: this._customerRecoverySharedService.getWorkFlowStageById(
        this.caseDetails.recoveryStatusId ?? 1
      ),
    };
    this.claimStatusUpdate(closeRequest, false, false);
  }

  async claimStatusUpdate(
    request: ClaimStatusRequest,
    close: boolean,
    newCase: boolean
  ) {
    const closedResponse: ClaimStatusResponse = await lastValueFrom(
      this._customerRecoveryService.customerRecoveryClaimsClaimStatusCaseNumberPut(
        request,
        this.caseNumber,
        this.apiVersion
      )
    );
    if (!closedResponse.claimStatusChange.isClaimStatusChangeSuccessful) return;
    this.caseDetails.recoveryStatusId = request.claimStatusId;
    this.caseDetails.comments = request.comment;
    this.caseDetails.cancellationReason = request.cancelationReason;
    this.caseDetails.workFlowStage = request.workFlowStage;
    this._sharedRecoveryCaseService.reloadRecoveryCaseData();
    if (close) {
      this._sharedRecoveryCaseService.updateTabSelected(1);
      if (newCase) {
        //update work_flow_version for new case --
        this.updateWorkFlowVersionForNewCase();
      }
      this._router.navigate(['customer-recovery']);
    }
  }
  updateWorkFlowVersionForNewCase() {
    if (!this.caseDetails) return;
    this.caseDetails.workFlowVersion = 1;
    const caseRequest = {
      customerRecoveryCaseDto: this.caseDetails,
    } as CaseDetailsDto;
    firstValueFrom(
      this._customerRecoveryService.customerRecoveryClaimsUpdatePost(
        caseRequest,
        this.apiVersion
      )
    );
  }

  onWorkOrdersLoaded(workOrders: WorkOrderAndLineItemsDto[]) {
    this.workOrders = workOrders;
  }

  async movingStageNextOrBackWithAnchorFlow(
    request: CompleteTaskRequestDTO,
    stageId: number,
    dbRequest: ClaimStatusRequest
  ) {
    this.loadTab = false;
    //After Save success submit stage in anchor
    const anchorResponse = await lastValueFrom(
      this._workflowService.workflowCompleteTask(request).pipe(
        map((res) => res),
        catchError((_) => {
          this._loader.hideLoader();
          this._toaster.showToast({
            message: 'Failed moving to stage.',
            type: 'error',
          } as Toast);
          return of();
        })
      )
    );
    if (anchorResponse.statusCode == 200 || anchorResponse.statusCode == 204) {
      //After anchor stage is update then update claim status id in db
      await this.updateRecoveryClaimId(dbRequest, stageId);
    }
    this.loadTab = true;
  }

  onTabChange(event: any) {
    this.currentTabIndex = event.detail;
    this.tabItems = this.currentStageItem[event.detail].items;
  }

  onLiabilityPartySelected(event: SendMailRequest | undefined) {
    this.liabilityEmailContent = event;
  }

  onBackClick() {
    this._loader.showLoader({
      label: 'Loading',
      size: 'medium',
    } as Loader);
    this.loadTab = false;
    const currentStageSequence =
      this.stages?.filter((i) => i.id == this.currentStageId)[0].sequence ?? 0;
    const prevStage = this.stages?.filter(
      (i) => i.sequence == currentStageSequence - 1
    )[0];

    if (prevStage) {
      this.currentStageItem[this.currentTabIndex].items?.forEach(
        (i) => (i.loaded = false)
      );
      if (prevStage.id)
        this._customerRecoverySharedService.updateCurrentStageId(prevStage.id);
    }
    this.loadTab = true;
    this._loader.hideLoader();
  }

  async updateRecoveryClaimId(request: ClaimStatusRequest, newStageId: number) {
    const statusResponse = await lastValueFrom(
      this._customerRecoveryService
        .customerRecoveryClaimsClaimStatusCaseNumberPut(
          request,
          this.caseNumber,
          this.apiVersion
        )
        .pipe(map((response) => response))
    );
    if (
      statusResponse.claimStatusChange &&
      statusResponse.claimStatusChange.isClaimStatusChangeSuccessful
    ) {
      this.caseDetails.workFlowStage =
        this._customerRecoverySharedService.getWorkFlowStageById(
          this.nextStageId
        );
      this.caseDetails.recoveryStatusId = request.claimStatusId;
      this._sharedRecoveryCaseService.reloadRecoveryCaseData();
      this.loadNextStage(true);
    }
  }

  getWorkFlowStageById(claimId: number): WorkFlowStage {
    let stageName = WorkFlowStage.New;
    switch (claimId) {
      case 1:
        stageName = WorkFlowStage.New;
        break;
      case 2:
        stageName = WorkFlowStage.DamageEstimation;
        break;
      case 3:
        stageName = WorkFlowStage.Liability;
        break;
      case 4:
        stageName = WorkFlowStage.Invoice;
        break;
      case 5:
        stageName = WorkFlowStage.Complete;
    }
    return stageName as WorkFlowStage;
  }

  loadNextStage(refreshAnchorDetailsFromAPI: boolean) {
    const newStage = this.stages?.filter(
      (i: any) => i.id == this.nextStageId
    )[0];
    if (newStage) {
      this.currentStageItem[this.currentTabIndex].items?.forEach(
        (i) => (i.loaded = false)
      );
      if (refreshAnchorDetailsFromAPI) {
        this._customerRecoverySharedService.updateAnchorLoaded(false);
        this._customerRecoverySharedService.reloadAnchorFlowData();
      }
      this._customerRecoverySharedService.updateCurrentStageId(
        this.nextStageId
      );
    }
  }

  stageLoad($event: any) {
    if (!($event as boolean)) return;
    this._customerRecoverySharedService.updateAnchorLoaded(false);
    this._customerRecoverySharedService.reloadAnchorFlowData();
  }

  /**
   * Converting date to a specified format.
   * @param date date
   * @returns
   */
  private convert_dd_mm_yyyy_into_dateFormat(date: string) {
    if (!date) return undefined;
    const arrDate = date?.split('-');

    return new Date(
      `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}T${'00:00:00.000'}Z`
    );
  }

  private sortInvoicesByCreatedDateDesc(
    caseInvoiceDetails: CaseInvoiceDetailDto[] | undefined
  ) {
    return caseInvoiceDetails
      ? caseInvoiceDetails.sort((a, b) => {
          const dateA = a.createdDate ? new Date(a.createdDate).getTime() : 0;
          const dateB = b.createdDate ? new Date(b.createdDate).getTime() : 0;
          return dateB - dateA;
        })
      : [];
  }
}
