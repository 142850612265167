<ng-container *ngIf="customerRecoveryData$ | async">
  <ng-container *ngIf="workOrders$ | async as workOrders">
    <ng-container *ngFor="let workOrder of workOrders; let i = index">
      <lib-panel>
        <header>WO-{{ workOrder.workOrderNumber }}</header>
        <left-corner-menu>
          <a
            (click)="
              showHideWorkOrderItemsLink(i, workOrder.workOrderLineItemDto)
            "
            class="header-link">
            {{ showWorkOrderItems[i] ? 'Hide line items' : 'View line items' }}
          </a>
        </left-corner-menu>
        <content>
          <mc-notification
            *ngIf="!workOrder.bookingNumber || !workOrder.podCountryName"
            body="System could not fetch booking details automatically, please select a different container move or check CP detail, POL and POD manually"
            appearance="warning">
            <span slot="icon">
              <mc-icon icon="exclamation-triangle"></mc-icon>
            </span>
          </mc-notification>
          <app-lib-form [items]="workOrdersConverted[i]"></app-lib-form>
          <div
            [style.display]="showWorkOrderItems[i] ? 'block' : 'none'"
            class="work-order-line-items">
            <app-grid
              [schema]="lineItemsGridSchema!"
              [data]="lineItemsGridData[i]"
              [showPanel]="false"
              [showPagination]="false"
              [showRowSelector]="true"
              [rowSelectorType]="'checkbox'"
              [enableSorting]="false"></app-grid>
          </div>
        </content>
      </lib-panel>
    </ng-container>
  </ng-container>
</ng-container>
