import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  LibFormComponent,
  PanelComponent,
  SectionSkeletonLoaderComponent,
} from '@maersk-global/angular-shared-library';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { combineLatest, tap } from 'rxjs';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { FIELD_NAME } from '../../../common/constants/app.constants';
import { RepairLineItem } from '../../../common/models/repairLineItem';
import { caseCpInformation } from '../../../common/models/caseCpInformation';
import { ContainerMovesComponent } from '../container-moves/container-moves.component';
import { SharedCustomerRecoveryCaseService } from '../../customer-recovery/shared-customer-recovery-case.service';

@Component({
  selector: 'cp-info',
  standalone: true,
  imports: [
    LibFormComponent,
    CommonModule,
    ContainerMovesComponent,
    PanelComponent,
    SectionSkeletonLoaderComponent,
  ],
  templateUrl: './cp-info.component.html',
  styleUrl: './cp-info.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CpInfoComponent implements OnInit {
  @Input() item!: TemplateModel;
  items: TemplateModel[] = [];
  manualEstimates: RepairLineItem[] | undefined;
  cpDetails: caseCpInformation | undefined;
  disable: boolean = false;

  cpInformation$ = combineLatest([
    this._customerRecoverySharedService.cpInformation$,
    this._customerRecoverySharedService.disableForm$,
  ]).pipe(
    tap(([cpInformation, disable]) => {
      this.disable = disable;
      this.bindValueToItems(cpInformation);
    })
  );

  recoveryData: CustomerRecoveryCaseDto | undefined;

  constructor(
    private _customerRecoverySharedService: SharedCustomerRecoveryCaseService
  ) {}

  ngOnInit() {
    this.items = this.item.items as TemplateModel[];
  }

  bindValueToItems(cpInformation: caseCpInformation | undefined) {
    this.items.forEach((item: TemplateModel) => {
      item.disabled = this.disable;

      if (item.name == FIELD_NAME.COVERAGE_AMOUNT) {
        item.value = '$' + (cpInformation?.cpCoverageAmountUSD ?? 0);
      } else if (item.name == FIELD_NAME.CP_PLAN) {
        item.value = cpInformation?.cpPlan ?? '-';
      } else if (item.name == FIELD_NAME.ABOVE_RECOVERABLE_COST) {
        item.value =
          '$' + this.formatDecimal(cpInformation?.aboveCoverageCostUSD);
      } else if (item.name == FIELD_NAME.RECOVERABLE_COST_USD) {
        item.value =
          '$' + this.formatDecimal(cpInformation?.recoverableCostUSD);
      }
    });
  }

  private formatDecimal(value: number | undefined): number {
    if (!value) return 0;
    return Math.round(value * 100) / 100;
  }
}
