import { lastValueFrom, map } from 'rxjs';
import {
  TEMPLATE_TYPE,
  WORKFLOW_TYPE,
} from '../../../common/constants/app.constants';
import { VersionDetails } from '../../../common/models/version-details';
import { WorkflowService } from '../../../common/services/customer-recovery/workflow.service';
import { StageTemplateResponse } from '../../../common/models/stage-template-response';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import * as template from '../../../../assets/json/dcrp-customer-workflow.json';
import * as manualCaseTemplate from '../../../../assets/json/dcrp-workflow-manual-case.json';
import { environment } from '../../../../environments/environment';
import { CustomerRecoveryClaimService } from '../../../common/services/customer-recovery/customer-recovery-claim.service';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { CaseDetailsDto } from '../../../common/models/caseDetailsDto';
import { CaseTypeEnum } from '../../../common/constants/temporary-constant';
import { SharedCustomerRecoveryCaseService } from '../shared-customer-recovery-case.service';

export class CustomerRecoveryOrchestratorWorkflowManager {
  //Latest Template and workflow version details
  latestWorkFlowVersionDetail?: VersionDetails;
  latestTemplateVersionDetail?: VersionDetails;

  latestTemplate?: any;
  anchorData?: StageTemplateResponse;
  //Session list for processVersion and templateVersionList
  processVersionListSession?: VersionDetails[];
  templateVersionListSession?: any;

  stages: TemplateModel[] = [];
  currentStageId: number = 0;

  localTemplate: any = (template as any).default;
  caseDetails!: CustomerRecoveryCaseDto;
  apiVersion: string = '1.0';

  constructor(
    private _workflowService: WorkflowService,
    private _customerRecoveryClaimService: CustomerRecoveryClaimService,
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _customerRecoverySharedService: SharedCustomerRecoveryCaseService,
    currentStageId: number,
    caseDetails: CustomerRecoveryCaseDto
  ) {
    this.currentStageId = currentStageId;
    this.caseDetails = caseDetails;
    this.initialPropertiesForVersioning();
    this.templateBindingOnStageChange();
  }

  templateBindingOnStageChange() {
    if (environment.localTemplate) {
      //local template for development always latest template
      this.methodUsingLocalTemplate();
    }
  }

  methodUsingLocalTemplate() {
    this.localTemplate =
      this._customerRecoverySharedService.caseType ==
      CaseTypeEnum.CaseWithWorkOrder
        ? (template as any).default
        : (manualCaseTemplate as any).default;

    let locTem = JSON.parse(JSON.stringify(this.localTemplate));
    this.stages = locTem.stages;
  }

  setCurrentStageVariableBasedOnTemplate(stages: any) {
    let filterCurrentStage = stages?.templates?.filter(
      (i: any) => i.CurrentStageTemplate.value.id == this.currentStageId
    )[0];
    this.stages = filterCurrentStage.Stageheaders?.value
      ?.stages as TemplateModel[];
  }

  initialPropertiesForVersioning() {
    this.processVersionListSession = sessionStorage.getItem(
      'dcrp-version-details'
    )
      ? JSON.parse(sessionStorage.getItem('dcrp-version-details') ?? '')
      : null;
    this.templateVersionListSession = sessionStorage.getItem(
      'dcrp-template-version'
    )
      ? JSON.parse(sessionStorage.getItem('dcrp-template-version') ?? '')
      : null;

    if (
      this.processVersionListSession &&
      this.templateVersionListSession &&
      this.templateVersionListSession.length > 0
    ) {
      this.latestTemplate = this.templateVersionListSession?.reduce(
        (item: any, curr: any) => {
          return item.version < curr.version ? curr : item;
        }
      );

      this.latestWorkFlowVersionDetail = this.processVersionListSession
        ?.filter((i: VersionDetails) => i.processType == WORKFLOW_TYPE)
        ?.reduce((item: VersionDetails, curr: VersionDetails) => {
          return item.version ?? 0 < (curr.version ?? 0) ? curr : item;
        });

      this.latestTemplateVersionDetail = this.processVersionListSession
        ?.filter((i: VersionDetails) => i.processType == TEMPLATE_TYPE)
        ?.reduce((item: VersionDetails, curr: VersionDetails) => {
          return item.version ?? 0 < (curr.version ?? 0) ? curr : item;
        });
    }
  }

  async updateClaimDataWithLatestVersion(
    migrateWorkflowVersion: number,
    migrateTemplateVersion: number
  ) {
    const request = this.caseDetails;
    request.workFlowVersion = migrateWorkflowVersion;
    request.templateVersion = migrateTemplateVersion;
    if (
      this.latestTemplateVersionDetail?.version != migrateTemplateVersion ||
      this.latestWorkFlowVersionDetail?.version != migrateWorkflowVersion
    ) {
      request.stopUpgrade = true;
    }
    const caseRequest = {
      customerRecoveryCaseDto: request,
    } as CaseDetailsDto;

    await lastValueFrom(
      this._customerRecoveryClaimService
        .customerRecoveryClaimsUpdatePost(caseRequest, this.apiVersion)
        .pipe(
          map((response) => {
            if (response.data) {
              this._sharedRecoveryCaseService.reloadRecoveryCaseData();
            }
          })
        )
    );
  }

  async templateBindAndExistCheck() {
    let templateFromSession = this.templateVersionListSession?.filter(
      (i: any) => i.version == this.caseDetails.templateVersion
    )[0];
    if (templateFromSession) {
      this.setCurrentStageVariableBasedOnTemplate(
        templateFromSession?.templates
      );
    } else {
      const req = {
        workflowType: 'Reefer_Cargo_Workflow', // TODO need to change once dmn in created for dcrp
        stage: 'Stage',
        TemplateDefinitionIds: `${this.caseDetails.templateVersion} = ${this.processVersionListSession?.filter((i) => i.version == this.caseDetails.templateVersion && i.processType == TEMPLATE_TYPE)[0]?.definitionId} `,
      };
      //TODO need to add new template which is not present in session
      let template = await lastValueFrom(
        this._workflowService.workflowGetAllTemplates(req)
      );
    }
  }

  getSequenceBasedOnStageID(stageId: number) {
    //return sequence of stage id of always latest template
    let headers = this.latestTemplate.templates[0].Stageheaders.value;
    let searchSequence = headers?.stages?.filter((i: any) => i.id == stageId);
    return searchSequence?.length > 0 ? searchSequence[0]?.sequence : null;
  }
}
