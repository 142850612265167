import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';

import { AuthHelper } from './auth-helper';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { NetworkService } from './../common/services/network/network.service';
import { IForgeRockToken } from './auth-helper';

@Injectable()
export class ForgeRockInterceptor implements HttpInterceptor {
  private isRefreshing: boolean = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  private _authHelper: AuthHelper = new AuthHelper(this._cookieService);
  constructor(
    private _cookieService: CookieService,
    private _networkService: NetworkService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<Object>> {
    let authReq: HttpRequest<any> = request;
    authReq = this.addTokenHeader(request);
    let finalReq = this.addApiVersionIfNotPresent(authReq);

    // This is included to avoid CORS issue while downloading contents from blob storage
    try {
      const url = new URL(request.url);
      if (url.host.endsWith('.blob.core.windows.net')) finalReq = request;
    } catch (e) {
      console.error('Invalid URL:', request.url);
    }

    return next.handle(finalReq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(finalReq, next);
        }
        return throwError(() => error);
      })
    );
  }

  addTokenHeader(request: HttpRequest<any>): HttpRequest<any> {
    const authHeader: string =
      'Bearer ' + localStorage.getItem(environment.iam_key + 'access_token');
    request = request.clone({
      setHeaders: {
        Authorization: authHeader,
        'Consumer-Key': environment.consumer_key,
      },
    });
    return request;
  }

  addApiVersionIfNotPresent(request: HttpRequest<any>): HttpRequest<any> {
    if (!request.headers.get('API-Version')) {
      request = request.clone({
        setHeaders: {
          'API-Version': '1.0',
        },
      });
    }

    return request;
  }

  /* istanbul ignore next */
  handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<Object>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const token: string =
        localStorage.getItem(environment.iam_key + 'refresh_token') ?? '';
      const apiInputData = `client_id=thirdparty&grant_type=refresh_token&refresh_token=${token}`;
      if (token != '') {
        this._networkService
          .fetchNewTokensUsingRefreshToken(apiInputData)
          .subscribe(
            (token) => {
              return this.updateTokenDetailForSubsequentAPICalls(
                token,
                next,
                request
              );
            },
            (error) => {
              this.isRefreshing = false;

              this._authHelper.logout();
              return throwError(() => error);
            }
          );
      }
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request)))
    );
  }

  updateTokenDetailForSubsequentAPICalls(
    token: IForgeRockToken,
    next: HttpHandler,
    request: HttpRequest<any>
  ): Observable<HttpEvent<any>> {
    localStorage.setItem(
      environment.iam_key + 'access_token',
      token.access_token
    );
    localStorage.setItem(environment.iam_key + 'id_token', token.id_token);
    localStorage.setItem(
      environment.iam_key + 'refresh_token',
      token.refresh_token
    );

    const curExpireDate: string =
      localStorage.getItem(environment.iam_key + 'expiry_date') ?? '';
    const newExpireDate: number =
      Number(curExpireDate) + Number(token.expires_in) * 1000;
    localStorage.setItem(
      environment.iam_key + 'expiry_date',
      newExpireDate.toString()
    );
    this.isRefreshing = false;
    this.refreshTokenSubject.next(token.access_token);
    return next.handle(this.addTokenHeader(request));
  }
}
