/**
 * CC DCRP API
 * Connected Claims Damaged Container Recovery API's.
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { EmailDetailDtoResponse } from '../../models/emailDetailDtoResponse';
import { BooleanResponse } from '../../models/booleanResponse';
import { SendMailRequest } from '../../models/sendMailRequest';
import { StoredItemType } from '../../models/storedItemType';

@Injectable()
export class EmailService {
  protected basePath = environment.dcrp_api_base_url;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {}

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Get email details by case number and type.
   *
   * @param caseNumber
   * @param type
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public emailCaseNumberTypeGet(
    caseNumber: string,
    type: string,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<EmailDetailDtoResponse>;
  public emailCaseNumberTypeGet(
    caseNumber: string,
    type: string,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<EmailDetailDtoResponse>>;
  public emailCaseNumberTypeGet(
    caseNumber: string,
    type: string,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<EmailDetailDtoResponse>>;
  public emailCaseNumberTypeGet(
    caseNumber: string,
    type: string,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (caseNumber === null || caseNumber === undefined) {
      throw new Error(
        'Required parameter caseNumber was null or undefined when calling emailCaseNumberTypeGet.'
      );
    }

    if (type === null || type === undefined) {
      throw new Error(
        'Required parameter type was null or undefined when calling emailCaseNumberTypeGet.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<EmailDetailDtoResponse>(
      'get',
      `${this.basePath}/email/${encodeURIComponent(String(caseNumber))}/${encodeURIComponent(String(type))}`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Download email document.
   *
   * @param caseNumber Case Number
   * @param type Type of Template
   * @param fileName File Name
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public emailDownloadCaseNumberTypeFileNameGet(
    caseNumber: string,
    type: StoredItemType,
    fileName: string,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Blob>;
  public emailDownloadCaseNumberTypeFileNameGet(
    caseNumber: string,
    type: StoredItemType,
    fileName: string,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public emailDownloadCaseNumberTypeFileNameGet(
    caseNumber: string,
    type: StoredItemType,
    fileName: string,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public emailDownloadCaseNumberTypeFileNameGet(
    caseNumber: string,
    type: StoredItemType,
    fileName: string,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (caseNumber === null || caseNumber === undefined) {
      throw new Error(
        'Required parameter caseNumber was null or undefined when calling emailDownloadCaseNumberTypeFileNameGet.'
      );
    }

    if (type === null || type === undefined) {
      throw new Error(
        'Required parameter type was null or undefined when calling emailDownloadCaseNumberTypeFileNameGet.'
      );
    }

    if (fileName === null || fileName === undefined) {
      throw new Error(
        'Required parameter fileName was null or undefined when calling emailDownloadCaseNumberTypeFileNameGet.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request(
      'get',
      `${this.basePath}/email/download/${encodeURIComponent(String(caseNumber))}/${encodeURIComponent(String(type))}/${encodeURIComponent(String(fileName))}`,
      {
        responseType: 'blob',
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Send an email using SendGrid.
   * Send an email using SendGrid.  Requires SendMailRequest
   * @param body SendMailRequest
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public emailPost(
    body: SendMailRequest,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BooleanResponse>;
  public emailPost(
    body: SendMailRequest,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BooleanResponse>>;
  public emailPost(
    body: SendMailRequest,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BooleanResponse>>;
  public emailPost(
    body: SendMailRequest,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (environment.environment != 'production') {
      body.to = sessionStorage.getItem('email')?.toString() ?? '';
      //body.cc = sessionStorage.getItem('email')?.toString() ?? '';
    }

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling emailPost.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<BooleanResponse>(
      'post',
      `${this.basePath}/email`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Download email document.
   *
   * @param caseNumber Case Number
   * @param type Type of Template
   * @param name
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public emailDownloadCaseNumberStorageTypesTypeFilesNameGet(
    caseNumber: string,
    type: StoredItemType,
    name: string,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Blob>;
  public emailDownloadCaseNumberStorageTypesTypeFilesNameGet(
    caseNumber: string,
    type: StoredItemType,
    name: string,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public emailDownloadCaseNumberStorageTypesTypeFilesNameGet(
    caseNumber: string,
    type: StoredItemType,
    name: string,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public emailDownloadCaseNumberStorageTypesTypeFilesNameGet(
    caseNumber: string,
    type: StoredItemType,
    name: string,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (caseNumber === null || caseNumber === undefined) {
      throw new Error(
        'Required parameter caseNumber was null or undefined when calling emailDownloadCaseNumberStorageTypesTypeFilesNameGet.'
      );
    }

    if (type === null || type === undefined) {
      throw new Error(
        'Required parameter type was null or undefined when calling emailDownloadCaseNumberStorageTypesTypeFilesNameGet.'
      );
    }

    if (name === null || name === undefined) {
      throw new Error(
        'Required parameter name was null or undefined when calling emailDownloadCaseNumberStorageTypesTypeFilesNameGet.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request(
      'get',
      `${this.basePath}/email/download/${encodeURIComponent(String(caseNumber))}/storage-types/${encodeURIComponent(String(type))}/files/${encodeURIComponent(String(name))}`,
      {
        responseType: 'blob',
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
