import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';

@Component({
  selector: 'div-skeleton-loader',
  standalone: true,
  template: `
    <div class="body">
      @for (item of [1, 2]; track $index) {
        <span class="section-1">
          <div></div>
          <div></div>
          <div></div>
        </span>
      }
    </div>
  `,
  styles: `
    @keyframes moving-gradient {
      0% {
        background-position: -250px 0;
      }
      100% {
        background-position: 250px 0;
      }
    }
    .body div {
      height: 20px;
      background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
      animation-name: moving-gradient;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      border-radius: 12px;
      width: 300px;
    }

    .body {
      display: flex;
      flex-direction: column;
      gap: 20px;
      height: 130px;
      justify-content: center;

      .section-1 {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 120px;

        div {
          width: 30%;
        }
      }
    }
  `,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class divisionLoaderComponent {}
