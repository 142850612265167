import { Routes } from '@angular/router';
import { CustomerRecoveryComponent } from './components/customer-recovery/customer-recovery.component';
import { EmailTemplateEditComponent } from './admin/email-template/email-template-edit.component';
import { CustomerRecoveryOrchestratorComponent } from './components/customer-recovery/customer-recovery-orchestrator/customer-recovery-orchestrator.component';
import { VendorRecoveryOrchestratorComponent } from './components/vendor-recovery/vendor-recovery-orchestrator/vendor-recovery-orchestrator.component';
import { VendorRecoveryComponent } from './components/vendor-recovery/vendor-recovery.component';

export const routes: Routes = [
  { path: '', redirectTo: 'customer-recovery', pathMatch: 'full' },
  { path: 'customer-recovery', component: CustomerRecoveryComponent },
  { path: 'vendor-recovery', component: VendorRecoveryComponent },
  {
    path: 'customer-recovery/workflow',
    component: CustomerRecoveryOrchestratorComponent,
  },
  {
    path: 'vendor-recovery/workflow',
    component: VendorRecoveryOrchestratorComponent,
  },
  {
    path: 'admin/email-template/edit',
    pathMatch: 'full',
    component: EmailTemplateEditComponent,
  },
  {
    path: 'help-faq',
    loadChildren: () =>
      import('./components/help-faq/help-faq.routes').then(
        (x) => x.HelpFaqRoutes
      ),
  },
];
