export default Object.freeze({
  vendorNotification: {
    downloadNotification: 'Vendor notification downloaded successfully!',
    downloadNotificationError:
      'Error occurred while downloading vendor notification!',
    downloadNotificationErrorNoData:
      'No data found for the selected vendor notification!',
  },
  liability: {
    downloadLetter: 'Liability letter downloaded successfully!',
    downloadLetterError: 'Error occurred while downloading liability letter!',
    downloadLetterErrorNoData:
      'No data found for the selected liability letter!',
  },
  invoicing: {
    createNewInvoice: 'Invoice requested successfully!',
    createNewInvoiceError:
      'Error occurred while submitting the invoice details!',
  },
});
