import { ApplicationConfig } from '@angular/core';
import { provideRouter, withHashLocation } from '@angular/router';
import { routes } from './app.routes';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ForgeRockInterceptor } from './helper/forge-rock.interceptor';
import {
  EmailModalService,
  HEADER_MENU_PROVIDER,
  ModalNotificationService,
  SIDE_NAV_PROVIDER,
  ToasterService,
} from '@maersk-global/angular-shared-library';
import { SideNavMenuService } from './common/services/side-nav-menu/side-nav-menu.service';
import { HeaderMenuService } from './common/services/header-menu/header-menu.service';
import { SharedRecoveryCaseService } from './shared-recovery-case-service';
import { CommonService } from './common/services/customer-recovery/common.service';
import { ApplicationConfigService } from './common/services/common/applicationConfig.service';
import { CustomerRecoveryClaimService } from './common/services/customer-recovery/customer-recovery-claim.service';
import { CaseService } from './common/services/case/case.service';
import { WorkflowService } from './common/services/customer-recovery/workflow.service';
import { MaterialCodeService } from './common/services/common/materialCode.service';
import { HelpAndFaqService } from './common/services/help-and-faq/help-and-faq.service';
import { SharedCustomerRecoveryCaseService } from './components/customer-recovery/shared-customer-recovery-case.service';
import { SharedVendorRecoveryCaseService } from './components/vendor-recovery/shared-vendor-recovery-case.service';
import { WorkOrderAndLineItemsService } from './common/services/customer-recovery/work-order-and-line-items.service';
import { SharedDataService } from './shared-data-service';
import { SessionTimeoutService } from './common/services/common/session-time-out-service';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withHashLocation()),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ForgeRockInterceptor,
      multi: true,
    },
    { provide: SIDE_NAV_PROVIDER, useClass: SideNavMenuService },
    { provide: HEADER_MENU_PROVIDER, useClass: HeaderMenuService },
    CookieService,
    ModalNotificationService,
    ToasterService,
    EmailModalService,
    ApplicationConfigService,
    CommonService,
    CustomerRecoveryClaimService,
    CaseService,
    MaterialCodeService,
    SharedCustomerRecoveryCaseService,
    SharedVendorRecoveryCaseService,
    WorkOrderAndLineItemsService,
    SharedDataService,
    SharedRecoveryCaseService,
    WorkflowService,
    HelpAndFaqService,
    SessionTimeoutService,
  ],
};
