/**
 * CC DCRP API
 * Connected Claims Damaged Container Recovery API's.
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';
import { WorkOrder } from '../../models/workOrder';
import { WorkOrderListResponse } from '../../models/workOrderListResponse';
import { environment } from '../../../../environments/environment';
import { CaseResponse } from '../../models/caseResponse';
import { BooleanResponse } from '../../models/booleanResponse';
import { RepairLineItemRequest } from '../../models/repairLineItemRequest';
import { RepairLineItemListResponse } from '../../models/repairLineItemListResponse';

@Injectable()
export class CaseService {
  protected basePath = environment.dcrp_api_base_url;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {}

  /**
   *
   *
   * @param containerNumber The container number to search for work orders.
   * @param fromDateTime
   * @param toDateTime
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsContainerNumberWorkOrdersGet(
    containerNumber: string,
    fromDateTime?: Date,
    toDateTime?: Date,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<WorkOrderListResponse>;
  public customerRecoveryClaimsContainerNumberWorkOrdersGet(
    containerNumber: string,
    fromDateTime?: Date,
    toDateTime?: Date,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<WorkOrderListResponse>>;
  public customerRecoveryClaimsContainerNumberWorkOrdersGet(
    containerNumber: string,
    fromDateTime?: Date,
    toDateTime?: Date,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<WorkOrderListResponse>>;
  public customerRecoveryClaimsContainerNumberWorkOrdersGet(
    containerNumber: string,
    fromDateTime?: Date,
    toDateTime?: Date,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (containerNumber === null || containerNumber === undefined) {
      throw new Error(
        'Required parameter containerNumber was null or undefined when calling customerRecoveryClaimsContainerNumberWorkOrdersGet.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (fromDateTime !== undefined && fromDateTime !== null) {
      queryParameters = queryParameters.set(
        'FromDateTime',
        <any>fromDateTime.toISOString()
      );
    }
    if (toDateTime !== undefined && toDateTime !== null) {
      queryParameters = queryParameters.set(
        'ToDateTime',
        <any>toDateTime.toISOString()
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // authentication (Bearer) required
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];

    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<WorkOrderListResponse>(
      'get',
      `${this.basePath}/customer-recovery-claims/${encodeURIComponent(String(containerNumber))}/work-orders`,
      {
        params: queryParameters,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Creates a recovery case manually.
   *
   * @param body The details of the work order to be created.
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsRecoveryCasePost(
    body?: WorkOrder,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CaseResponse>;
  public customerRecoveryClaimsRecoveryCasePost(
    body?: WorkOrder,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CaseResponse>>;
  public customerRecoveryClaimsRecoveryCasePost(
    body?: WorkOrder,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CaseResponse>>;
  public customerRecoveryClaimsRecoveryCasePost(
    body?: WorkOrder,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<CaseResponse>(
      'post',
      `${this.basePath}/customer-recovery-claims/recovery-cases`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Inserts repair line item from manual case creation screen.
   *
   * @param caseId The recovery case identifier
   * @param body repair line item
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsCaseIdRepairLineItemPost(
    caseId: number,
    body?: RepairLineItemRequest,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BooleanResponse>;
  public customerRecoveryClaimsCaseIdRepairLineItemPost(
    caseId: number,
    body?: RepairLineItemRequest,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BooleanResponse>>;
  public customerRecoveryClaimsCaseIdRepairLineItemPost(
    caseId: number,
    body?: RepairLineItemRequest,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BooleanResponse>>;
  public customerRecoveryClaimsCaseIdRepairLineItemPost(
    caseId: number,
    body?: RepairLineItemRequest,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (caseId === null || caseId === undefined) {
      throw new Error(
        'Required parameter caseId was null or undefined when calling customerRecoveryClaimsCaseIdRepairLineItemPost.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<BooleanResponse>(
      'post',
      `${this.basePath}/customer-recovery-claims/${encodeURIComponent(String(caseId))}/repair-line-item`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Retrieves repair line items for manual case creation screen.
   *
   * @param caseId The recovery case identifier
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsCaseIdRepairLineItemsGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<RepairLineItemListResponse>;
  public customerRecoveryClaimsCaseIdRepairLineItemsGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<RepairLineItemListResponse>>;
  public customerRecoveryClaimsCaseIdRepairLineItemsGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<RepairLineItemListResponse>>;
  public customerRecoveryClaimsCaseIdRepairLineItemsGet(
    caseId: number,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (caseId === null || caseId === undefined) {
      throw new Error(
        'Required parameter caseId was null or undefined when calling customerRecoveryClaimsCaseIdRepairLineItemsGet.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<RepairLineItemListResponse>(
      'get',
      `${this.basePath}/customer-recovery-claims/${encodeURIComponent(String(caseId))}/repair-line-items`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Retrieves manual work orders for a specified container number within a given date range.
   *
   * @param caseId The recovery case identifier
   * @param repairLineItemId Repair Line Item identifier
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsCaseIdRepairLineItemsRepairLineItemIdDelete(
    caseId: number,
    repairLineItemId: number,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BooleanResponse>;
  public customerRecoveryClaimsCaseIdRepairLineItemsRepairLineItemIdDelete(
    caseId: number,
    repairLineItemId: number,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BooleanResponse>>;
  public customerRecoveryClaimsCaseIdRepairLineItemsRepairLineItemIdDelete(
    caseId: number,
    repairLineItemId: number,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BooleanResponse>>;
  public customerRecoveryClaimsCaseIdRepairLineItemsRepairLineItemIdDelete(
    caseId: number,
    repairLineItemId: number,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (caseId === null || caseId === undefined) {
      throw new Error(
        'Required parameter caseId was null or undefined when calling customerRecoveryClaimsCaseIdRepairLineItemsRepairLineItemIdDelete.'
      );
    }

    if (repairLineItemId === null || repairLineItemId === undefined) {
      throw new Error(
        'Required parameter repairLineItemId was null or undefined when calling customerRecoveryClaimsCaseIdRepairLineItemsRepairLineItemIdDelete.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<BooleanResponse>(
      'delete',
      `${this.basePath}/customer-recovery-claims/${encodeURIComponent(String(caseId))}/repair-line-items/${encodeURIComponent(String(repairLineItemId))}`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
