import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import {
  ALIGN,
  EmailModalService,
  GridCellData,
  GridColumnSchema,
  GridComponent,
  GridRowData,
  Image,
  ImageListerComponent,
  LibFormComponent,
  ModalNotificationService,
  PanelComponent,
  ToasterService,
} from '@maersk-global/angular-shared-library';
import {
  BehaviorSubject,
  Observable,
  catchError,
  combineLatest,
  firstValueFrom,
  lastValueFrom,
  map,
  merge,
  of,
  shareReplay,
  skip,
  switchMap,
  tap,
} from 'rxjs';
import { CustomerRecoveryClaimService } from '../../../common/services/customer-recovery/customer-recovery-claim.service';
import { Inspection } from '../../../common/models/inspection';
import { ActivatedRoute } from '@angular/router';
import { ClaimReferenceDocumentDto } from '../../../common/models/claimReferenceDocumentDto';
import { CaseDocumentService } from '../../../common/services/case-document/case-document.service';
import { InspectionImage } from '../../../common/models/inspection-image';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { StorageType } from '../../../common/models/storageType';
import { logger } from '../../../logger';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { loggerType } from '../../../common/models/logger-type';
import {
  ATTACHMENT_LOGGER,
  IA_BOTH_AVAILABLE,
  IA_EIR_AVAILABLE,
  IA_NOT_AVAILABLE,
  IA_VENDOR_AVAILABLE,
  Image_Source,
  VENDOR_EMAIL_SUBJECT,
} from '../../../common/constants/app.constants';
import { EmailService } from '../../../common/services/email/email.service';
import { EmailDetailDto } from '../../../common/models/emailDetailDto';
import { BlobsService } from '../../../common/services/email/blobs.service';
import { SaveMailDataDto } from '../../../common/models/saveMailDataDto';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { InspectionDetailsDTO } from '../../../common/models/inspectionDetailsDTO';
import { SendMailRequest } from '../../../common/models/sendMailRequest';
import { ItemType } from '../../../common/models/itemType';
import { environment } from '../../../../environments/environment';
import toastServiceMessages from '../../../common/toast-service-messages';
import { EmailTemplatePlaceholderService } from '../../../email-template-placeholder.service';
import { Components } from '../../../common/constants/temporary-constant';
import { SharedCustomerRecoveryCaseService } from '../../customer-recovery/shared-customer-recovery-case.service';
import { SharedDataService } from '../../../shared-data-service';

@Component({
  selector: 'attachment',
  standalone: true,
  imports: [
    CommonModule,
    GridComponent,
    ImageListerComponent,
    PanelComponent,
    LibFormComponent,
  ],
  templateUrl: './attachment.component.html',
  styleUrl: './attachment.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [CaseDocumentService, EmailService, BlobsService],
})
export class AttachmentComponent implements OnInit {
  @ViewChild('fileUploader') fileUploader?: ElementRef;
  @ViewChild('attachmentDiv') attachmentDiv?: ElementRef;
  @Input({ required: true }) item?: TemplateModel;

  disable: boolean = false;
  apiVersion: string = '1.0';
  userId: string = sessionStorage.getItem('userId') || '';
  caseNumber: string = '';
  errorMessage: string = '';
  validFormats: string = '.jpg,.png,.pdf,.jpeg,.gif,.zip';
  isUploadingInProgress: boolean = false;
  uploadedFileCount: number = 0;
  logger = new logger();
  openDetailedEmailModal: boolean = false;
  currentViewedEmailNotification = 0;
  inspectionRecordsLength = 0;
  emailsSentToVendorsLength = 0;
  emailBody?: SafeHtml;
  areEirImagesLoading: boolean = false;
  eirLoggerGridModel: loggerType = {
    moduleName: `Eir's Grid`,
  };
  isTimeDifferenceValidForEirExecution: boolean = true;
  disableEirCheck: boolean = false;
  disableFileUpload: boolean = true;
  /**
   * This flag is used to not update EIR availability when inspections are loaded for the first time.
   */
  isInspectionAlreadyLoaded: boolean = false;
  /**
   * This are properties for RUM
   */
  noOfSelectOnImage: number = 0;
  noOfDeselectOnImage: number = 0;
  noOfClickOnEirSelect: number = 0;
  noOfManualUpload: number = 0;
  caseDetails?: CustomerRecoveryCaseDto;
  toastMessages = toastServiceMessages;

  customerRecoveryData$: Observable<CustomerRecoveryCaseDto | undefined> =
    combineLatest([
      this._sharedRecoveryCaseService.recoveryCaseData$,
      this._customerRecoverySharedService.disableForm$,
      this._sharedRecoveryCaseService.eirImageLastFetchDate$,
    ]).pipe(
      tap(([recoveryData, disable, eirImageLastCheckedOn]) => {
        this.caseDetails = recoveryData!;
        this.eirImageLastCheckedOn =
          eirImageLastCheckedOn ?? this.caseDetails.eirImageLastCheckedOn;
        this.disableFileUpload = this.disable;
        this.disable = disable;
        this.disableEirCheck = !this.isTimeDifferenceGreaterThanThreshold(
          eirImageLastCheckedOn,
          1
        );
      }),
      map(([res]) => res)
    );

  /**
   * EIR(Inspection) grid Schema.
   */
  eirInspectionGridSchema: GridColumnSchema[] = [];
  /**
   * This observable contains list of countries which are excluded from vendor email notification.
   */
  isPodCountryExcludedForVendorEmailNotification$: Observable<boolean> =
    combineLatest([
      this.customerRecoveryData$,
      this._sharedDataService.vendorEmailExclusionCountries$,
    ]).pipe(
      map(([customerRecoveryDto, countries]) => {
        return !!countries?.find(
          (country) =>
            country.countryCode === customerRecoveryDto?.podCountryCode &&
            !country.isExcluded
        );
      })
    );

  /**
   * Subject to hold the latest list of assigned case images.
   */
  imagesAssignedToCaseSubject$$: BehaviorSubject<ClaimReferenceDocumentDto | null> =
    new BehaviorSubject<ClaimReferenceDocumentDto | null>({});

  /**
   * Initial list of assigned case images which we get from the API.
   */
  initialImagesAssignedToCase$: Observable<ClaimReferenceDocumentDto | null> =
    this.customerRecoveryData$.pipe(
      switchMap((customerRecoveryDto) =>
        this._caseDocumentService.customerRecoveryClaimsCaseIdCaseDocumentGet(
          customerRecoveryDto?.caseId ?? 0,
          this.apiVersion
        )
      ),
      map((response) => {
        if (!response || !response.claimReferenceDocuments) return null;
        return response.claimReferenceDocuments;
      }),
      shareReplay(1),
      tap((claimRefDocument) => {
        this.imagesAssignedToCaseSubject$$.next(claimRefDocument);
      })
    );
  /**
   * Main Observable to hold the latest list of assigned case images.
   */
  imagesAssignedToCase$: Observable<ClaimReferenceDocumentDto | null> = merge(
    this.initialImagesAssignedToCase$,
    this.imagesAssignedToCaseSubject$$.asObservable()
  );
  /**
   * EIR(Inspection) images assigned to the case till now.
   */
  selectedImagesForCurrentCase$: Observable<Image[]> =
    this.imagesAssignedToCase$.pipe(
      map((claimDocumentDto) => {
        const caseImages = claimDocumentDto?.eirs?.flatMap(
          (eir) => eir.documentMetadata
        );
        if (!caseImages) return [];
        return caseImages.map((caseImage) => {
          return {
            id: caseImage.eirImageId?.toString(),
            path: caseImage.uri,
            isSelected: false,
            label: caseImage.damageType,
            showCloseButton: true,
            showSelector: false,
            header: 'eEIR',
            subHeader: `Damage Code - ${caseImage.damageType}`,
            allowDownload: true,
            type: caseImage.name
              .substring(caseImage.name.lastIndexOf('.') + 1)
              .toLowerCase(),
            sizeInBytes: caseImage.size,
          } as Image;
        });
      })
    );

  private shouldReloadVendorNotifications$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  /**
   * This observable works on the subject above and emits the list of emails sent to vendors.
   */
  emailsSentToVendors$: Observable<Array<SaveMailDataDto> | undefined> =
    combineLatest([
      this._route.queryParamMap,
      this.shouldReloadVendorNotifications$$.asObservable(),
    ]).pipe(
      switchMap(([params, _]) => {
        const caseNumber = params.get('caseNumber') as string;
        return this._blobsService
          .blobsNameGet(caseNumber)
          .pipe(catchError((_) => of(undefined)));
      }),
      map((result) =>
        result?.data?.saveMailDataDtos?.sort(
          (email1, email2) =>
            new Date(email2.sentOn ?? '').getTime() -
            new Date(email1.sentOn ?? '').getTime()
        )
      )
    );

  /**
   * This is the subject holding the latest list of emails sent to vendors as template items of `lib-form`.
   */
  emailsSentToVendors$$: BehaviorSubject<Array<SaveMailDataDto> | undefined> =
    new BehaviorSubject<Array<SaveMailDataDto> | undefined>(undefined);
  /**
   * This observable works on the subject above and emits the list of emails sent to vendors as template items of `lib-form`.
   */
  emailsSentToVendorsAsTemplate$: Observable<
    Array<Array<TemplateModel>> | undefined
  > = merge(
    this.emailsSentToVendors$$.asObservable().pipe(
      // Skipping first 2 emissions as first value will be `undefined` and 2nd will be the same API result.
      skip(2)
    ),
    this.emailsSentToVendors$.pipe(
      tap((emails) => this.emailsSentToVendors$$.next(emails))
    )
  ).pipe(
    map((saveMailDataDtos) =>
      saveMailDataDtos?.map((savedEmail) => {
        return this.prepareTemplateModelsForVendorEmails(savedEmail);
      })
    ),
    tap((emails) => (this.emailsSentToVendorsLength = emails?.length ?? 0))
  );

  /**
   * This observable will be used to reload the list of inspections.
   */
  shouldReloadInspections$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  /**
   * EIR(Inspection) records and all associated images with that which we receive from API.
   */
  inspections$: Observable<Inspection[] | null> = this.logger.apiLogger(
    combineLatest([
      this._route.queryParamMap,
      this._customerRecoverySharedService.incidentDate$,
      this.shouldReloadInspections$$.asObservable(),
    ]).pipe(
      switchMap(([params, incidentDate, _]) => {
        this.areEirImagesLoading = true;
        this.caseNumber = params.get('caseNumber') as string;
        const incidentDateReq = incidentDate ? new Date(incidentDate) : null;

        if (
          incidentDate &&
          this.caseDetails &&
          !this._sharedDataService.compareDatesIgnoringTime(
            incidentDate,
            this.caseDetails?.dateOfIncident ?? null
          )
        ) {
          this.caseDetails.dateOfIncident = incidentDate;
        }
        return this._customerRecoveryClaimService
          .customerRecoveryClaimsCaseNumberInspectionsGet(
            this.caseNumber,
            incidentDateReq,
            this.apiVersion
          )
          .pipe(
            map((response) => {
              if (!response || !response.inspections) return null;
              return response.inspections;
            }),
            catchError((error) => {
              this.areEirImagesLoading = false;
              this.updateEirAvailability([]);
              //Currently keeping console logs, later will move it to grafana logs
              console.log('error while fetching eEir Image ', error);
              return of(null);
            })
          );
      }),
      tap((inspections) => {
        this.areEirImagesLoading = false;
        this.inspectionRecordsLength = inspections?.length ?? 0;
        if (this.isInspectionAlreadyLoaded)
          this.updateEirAvailability(inspections);
        this.isInspectionAlreadyLoaded = true;
      }),
      shareReplay(1)
    ),
    this.eirLoggerGridModel
  );
  /**
   * This is the current selected EIR(Inspection id) which is selected in the grid.
   */
  currentSelectedInspectionIdSubject$$: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);

  /**
   * This is the current selected EIR(Inspection id) which is selected in the grid.
   */
  currentSelectedInspectionId$: Observable<number> = merge(
    this.inspections$.pipe(
      map((inspections) => {
        if (!inspections || inspections.length === 0) return 0;
        return inspections[0].inspectionId;
      }),
      tap((inspectionId) =>
        this.currentSelectedInspectionIdSubject$$.next(inspectionId)
      )
    ),
    this.currentSelectedInspectionIdSubject$$.asObservable().pipe(skip(2))
  );

  /**
   * Inspection grid data
   */
  eirInspectionGridData$: Observable<GridRowData[] | null> =
    this.inspections$.pipe(
      map((inspections) => {
        if (!inspections || inspections.length === 0) return null;
        const inspectionGridData = inspections?.map((inspection) =>
          this.generateGridDataFromInspection(inspection)
        );
        inspectionGridData[0].isRowSelected = true;
        return inspectionGridData;
      })
    );
  /**
   * EIR(Inspection) images uploaded manually for the current case.
   */
  uploadedImagesForTheCurrentCase$?: Observable<Image[]> =
    this.imagesAssignedToCase$.pipe(
      map((claimDocumentDto) => {
        const caseUploadedImages = claimDocumentDto?.attachments;
        if (!caseUploadedImages) return [];
        return caseUploadedImages
          ?.sort(
            (image1, image2) =>
              new Date(image2.createdDateTime ?? '').getTime() -
              new Date(image1.createdDateTime ?? '').getTime()
          )
          .map((caseUploadedImage) => {
            return {
              id: caseUploadedImage.id?.toString(),
              path: caseUploadedImage.uri,
              label: caseUploadedImage.name.substring(
                caseUploadedImage.name.indexOf('-') + 1
              ),
              header: 'eEIR',
              showCloseButton: true,
              type: caseUploadedImage.name
                .substring(caseUploadedImage.name.lastIndexOf('.') + 1)
                .toLowerCase(),
              subHeader: `Damage Code - ${caseUploadedImage.damageType}`,
              allowDownload: true,
              sizeInBytes: caseUploadedImage.size,
            } as Image;
          });
      })
    );
  /**
   * EIR(Inspection) images for the currently selected EIR record from the grid.
   * This list does not have images which are already assigned to the case
   */
  unselectedImagesFromCurrentSelectedEir$?: Observable<Image[]> = combineLatest(
    [
      this.inspections$,
      this.imagesAssignedToCase$,
      this.currentSelectedInspectionId$,
    ]
  ).pipe(
    map(([inspections, claimReferenceDocument, currentInspection]) => {
      this.errorMessage = '';
      const matchingInspection = inspections?.find(
        (inspection) => inspection.inspectionId === currentInspection
      );

      if (!matchingInspection || !matchingInspection.damages) {
        return [];
      }
      this.allImagesFromCurrentSelectedEir = matchingInspection.damages.flatMap(
        (damage) => damage.images ?? []
      );

      const imagesAssignedToCurrentEir = claimReferenceDocument?.eirs?.find(
        (eir) => eir.eirId === currentInspection
      );

      if (!matchingInspection || !matchingInspection.damages) return [];

      return matchingInspection?.damages?.reduce((group, currentDamage) => {
        if (currentDamage.images) {
          currentDamage.images.forEach((image) => {
            if (
              !image ||
              !image.imageId ||
              imagesAssignedToCurrentEir?.documentMetadata
                .map((doc) => doc.eirImageId)
                .includes(image.imageId)
            )
              return;
            group.push({
              id: image.imageId?.toString(),
              path: image?.imageUrl,
              showSelector: true,
              label: currentDamage.damageType,
              header: 'eEIR',
              subHeader: `Damage Code - ${currentDamage.damageType}`,
              allowDownload: true,
              type: image.imageName
                .substring(image.imageName.lastIndexOf('.') + 1)
                .toLowerCase() as any,
            });
          });
        }
        return group;
      }, [] as Image[]);
    })
  );

  /**
   * All EIR(Inspection) images (assigned to the case as well as unassigned) for the currently selected EIR record from the grid.
   */
  allImagesFromCurrentSelectedEir?: InspectionImage[] = [];
  eirImageLastCheckedOn: Date | undefined;

  constructor(
    private _customerRecoveryClaimService: CustomerRecoveryClaimService,
    private _route: ActivatedRoute,
    private _caseDocumentService: CaseDocumentService,
    private _notificationModalService: ModalNotificationService,
    private _toasterService: ToasterService,
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _emailService: EmailService,
    private _emailModalService: EmailModalService,
    private _blobsService: BlobsService,
    protected _sanitizer: DomSanitizer,
    private _emailTemplatePlaceholderService: EmailTemplatePlaceholderService,
    private _customerRecoverySharedService: SharedCustomerRecoveryCaseService,
    private _sharedDataService: SharedDataService
  ) {}

  /**
   * We are creating a schema for the Inspection grid.
   * @returns nothing
   */
  ngOnInit(): void {
    if (!this.item || !this.item.items) return;
    this.eirInspectionGridSchema = this.item.items.map((y: TemplateModel) => {
      const column = {
        column: y.name,
        displayName: y.label,
        align: ALIGN.LEFT,
        hidden: false,
        sequence: y.sequence,
        columnType: y.valueType?.toUpperCase(),
        disableSort: true,
      } as GridColumnSchema;
      return column;
    });

    this.disableFileUpload = environment.disableFileUpload;
    if (this.disable) this.disableFileUpload = this.disable;
  }
  /**
   * This method does a transformation on the api response object and creates grid row object from it.
   * @param inspection original object received from web-api response
   * @returns grid row object created
   */
  private generateGridDataFromInspection(
    customerInspection: Inspection
  ): GridRowData {
    const customerRecoveryKeyValue = customerInspection as unknown as {
      [key: string]: unknown;
    };
    const gridRowObject: { [key: string]: GridCellData } = {};
    Object.keys(customerInspection).map((key) => {
      gridRowObject[key] = {
        disabled: this.disable,
        value: customerRecoveryKeyValue[key],
      } as GridCellData;
    });
    return {
      row: gridRowObject,
    } as GridRowData;
  }
  /**
   * This method is triggered when Inspection grid has new record selected in it.
   * @param gridRows selected list of rows
   * @returns
   */
  async rowSelectionChanged(gridRows: { [key: string]: unknown }[]) {
    this.currentSelectedInspectionIdSubject$$.next(
      (gridRows[0]['inspectionId'] as GridCellData).value as number
    );
  }

  /**
   * UI hooked method fires when EIR availability is checked.
   */
  async onEirAvailabilityChecked() {
    this.shouldReloadInspections$$.next(true);
  }

  /**
   * This method will be called every time the inspections are loaded (except on initial load), to set EIR availability.
   * @param inspections List of inspection
   * @returns
   */
  async updateEirAvailability(inspections: Inspection[] | null) {
    this.logTheEirRequestActionClicked();
    this.disableEirCheck = true;
    this._sharedRecoveryCaseService.updateEirImageLastFetchOn(new Date());

    let imageSource = IA_NOT_AVAILABLE;
    const eirSourcedImages = inspections?.find(
      (o) => o.sourceId === Image_Source.EIR_MOBILE_APP
    );
    const vendorEmailSourcedImages = inspections?.find(
      (o) => o.sourceId === Image_Source.VENDOR_EMAIL
    );
    if (eirSourcedImages && vendorEmailSourcedImages)
      imageSource = IA_BOTH_AVAILABLE;
    else if (eirSourcedImages) imageSource = IA_EIR_AVAILABLE;
    else if (vendorEmailSourcedImages) imageSource = IA_VENDOR_AVAILABLE;
    else imageSource = IA_NOT_AVAILABLE;
    this.saveImageAvailabilityStatus(this.caseNumber, imageSource)
      .then(() => {})
      .catch((_) => {
        this._toasterService.showToast({
          message: 'Unable to update image availability status',
          type: 'error',
        });
      });
  }

  /**
   * Saves the image availability status for a given recovery case number.
   * @param caseNumber - The recovery case number.
   * @returns A promise that resolves after the status is saved.
   */
  async saveImageAvailabilityStatus(
    caseNumber: string,
    imageAvailabilityStatus: number
  ) {
    const inspectionInfo: InspectionDetailsDTO = {
      caseNumber: caseNumber,
      damageProofStatus: imageAvailabilityStatus,
      updatedBy: sessionStorage.getItem('userId')?.toString(),
    };
    return await lastValueFrom(
      this._customerRecoveryClaimService.customerRecoveryClaimsCaseNumberImageAvailabilityPut(
        inspectionInfo,
        caseNumber,
        this.apiVersion
      )
    );
  }

  /**
   * This method logs the eir image request action clicked
   * @returns
   */
  logTheEirRequestActionClicked() {
    this.logger.actionLogger({
      message:
        `EIR on-demand requested by user for the recovery case Number - ` +
        this.caseNumber,
      type: 'EIRImageAvailabilityRequest',
    } as loggerType);
  }

  /**
   * This method removes the image from the case assigned image list.
   * @param removedImage image
   * @returns
   */
  async onEirRelatedImageRemoved(removedImage: Image) {
    const imagesAssignedToCases = this.imagesAssignedToCaseSubject$$.value;

    if (
      !imagesAssignedToCases ||
      !imagesAssignedToCases.eirs ||
      imagesAssignedToCases.eirs.length === 0
    )
      return;

    ++this.noOfDeselectOnImage;

    const removedDocumentImage = imagesAssignedToCases.eirs
      ?.flatMap((eir) => eir.documentMetadata)
      .find((image) => image.eirImageId?.toString() === removedImage.id);

    imagesAssignedToCases.eirs.forEach((eir) => {
      eir.documentMetadata = eir.documentMetadata.filter(
        (image) => image.eirImageId?.toString() !== removedImage.id
      );
    });

    await this.removeImagesForCurrentCase([removedDocumentImage?.id ?? 0]);

    this.imagesAssignedToCaseSubject$$.next(imagesAssignedToCases);
  }
  /**
   * This method adds the images to the case assigned image list.
   * @param selectedImages selected images
   */
  async onEirRelatedImageSelected(selectedImages: Image[]) {
    let imagesAssignedToCases = this.imagesAssignedToCaseSubject$$.value;
    //If we don't have any EIR inspection record for the case images then create one.
    if (
      !imagesAssignedToCases ||
      (imagesAssignedToCases.eirs?.length === 1 &&
        imagesAssignedToCases.eirs[0].eirId === 0)
    )
      imagesAssignedToCases = {
        caseId: this.caseDetails?.caseId ?? 0,
        caseNumber: this.caseNumber,
        eirs: [
          {
            eirId: this.currentSelectedInspectionIdSubject$$.value,
            documentMetadata: [],
          },
        ],
      };

    let imagesAssignedToCurrentEir = imagesAssignedToCases?.eirs?.find(
      (eir) => eir.eirId === this.currentSelectedInspectionIdSubject$$.value
    );

    //If there is no record for current EIR inspection then create one.
    if (!imagesAssignedToCurrentEir) {
      imagesAssignedToCases?.eirs?.push({
        eirId: this.currentSelectedInspectionIdSubject$$.value,
        documentMetadata: [],
      });
      imagesAssignedToCurrentEir = imagesAssignedToCases?.eirs?.find(
        (eir) => eir.eirId === this.currentSelectedInspectionIdSubject$$.value
      );
    }

    selectedImages.forEach((selectedImage) => {
      const originalInspectionImage =
        this.allImagesFromCurrentSelectedEir?.find(
          (images) => images.imageId?.toString() === selectedImage.id
        );
      if (!originalInspectionImage) return;
      imagesAssignedToCurrentEir?.documentMetadata.push({
        name: originalInspectionImage.imageName,
        uri: originalInspectionImage.imageUrl,
        eirImageId: originalInspectionImage.imageId,
        uploadedDateTime: originalInspectionImage.uploadedDate,
        createdDateTime: new Date(),
        createdUserId: this.userId,
        updatedDateTime: new Date(),
        updatedUserId: this.userId,
        damageType: selectedImage.label,
      });
    });
    ++this.noOfSelectOnImage;
    await this.saveImagesForCurrentCase(imagesAssignedToCases);
  }
  /**
   * This event fires when we bring files over file uploader div.
   * This is required as without it, drop event doesn't work.
   * @param event drag event
   */
  onFileDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    (this.attachmentDiv?.nativeElement as HTMLElement).classList.add(
      'drag-over'
    );
  }
  /**
   * This event fires when we drop files on file uploader div.
   * @param event drag event
   */
  async onFilesDropped(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    (this.attachmentDiv?.nativeElement as HTMLElement).classList.remove(
      'drag-over'
    );
    await this.uploadFilesOnServer(event.dataTransfer?.files);
  }
  /**
   * We are removing the class we had applied to the element on which we are dragging the files.
   * @param _ drag event
   */
  onDragLeave(_: DragEvent) {
    (this.attachmentDiv?.nativeElement as HTMLElement).classList.remove(
      'drag-over'
    );
  }
  /**
   * This event fires when we manually select files to upload.
   * @param event drag event
   */
  async onFileManuallySelected(event: Event) {
    const input = event.target as HTMLInputElement;
    await this.uploadFilesOnServer(input?.files);
  }
  /**
   * This method gets the list of files (images in our case) and upload them to server.
   * @param files uploaded files
   * @returns
   */
  async uploadFilesOnServer(files: FileList | null | undefined) {
    const validFiles = this.getValidFiles(files);
    if (!validFiles) return;
    this.isUploadingInProgress = true;
    this._sharedRecoveryCaseService.updateFormValidationState({
      component: Components.AttachmentComponent,
      state: false,
    });
    this.uploadedFileCount = validFiles.length;
    const startTime: Date = new Date();
    let concatenatedFileNames: string = '';
    let imagesAssignedToCases = this.imagesAssignedToCaseSubject$$.value;
    //If we don't have any record for the case images then create one.
    if (
      !imagesAssignedToCases ||
      Object.keys(imagesAssignedToCases).length === 0
    )
      imagesAssignedToCases = {
        caseId: this.caseDetails?.caseId ?? 0,
        attachments: [],
      };
    let failedFileUploads = 0;
    let successfulFileUploads = 0;
    // With following code we are creating multiple API calls which are in fact various observables.
    // These observables (i.e. API calls) will keep on emitting responses whenever they complete with the `merge` operator.
    // We are tapping on those responses and changing the UI loader state with every new file upload.
    await lastValueFrom(
      merge(
        ...Array.from(validFiles).map((file) =>
          this._caseDocumentService
            .customerRecoveryClaimsCaseDocumentUploadPostForm(
              StorageType.NUMBER_1,
              [file],
              this.apiVersion,
              'DCRP',
              this.caseNumber
            )
            .pipe(
              catchError((errorResponse) => {
                this._toasterService.showToast({
                  message: `file ${file.name} upload failed - ${errorResponse?.error?.statusText ?? errorResponse.message}`,
                  type: 'error',
                });
                return of(undefined);
              })
            )
        )
      ).pipe(
        tap(async (uploadedFile) => {
          this.uploadedFileCount--;
          if (!uploadedFile || !uploadedFile.isSuccess || !uploadedFile.data) {
            failedFileUploads++;
            return;
          }
          successfulFileUploads++;
          concatenatedFileNames += uploadedFile.data[0].fileName;
          imagesAssignedToCases!.attachments = [
            {
              name: uploadedFile.data[0].fileName,
              uri: uploadedFile.data[0].uri ?? '',
              uploadedDateTime: new Date(),
              createdDateTime: new Date(),
              createdUserId: this.userId,
              updatedDateTime: new Date(),
              updatedUserId: this.userId,
            },
          ];
          await this.saveImagesForCurrentCase(imagesAssignedToCases!);
        })
      )
    );

    ++this.noOfManualUpload;
    const totalTime = (new Date().valueOf() - startTime.valueOf()) / 1000;
    this.logger.actionLogger({
      message: `Time Taken to upload  ${concatenatedFileNames} for case ${this.caseNumber}`,
      count: totalTime,
    } as loggerType);

    if (failedFileUploads > 0)
      this._toasterService.showToast({
        message: `Failure while uploading ${failedFileUploads} file(s).`,
        type: 'error',
      });

    if (successfulFileUploads > 0)
      this._toasterService.showToast({
        message: `${successfulFileUploads} File(s) uploaded successfully!`,
        type: 'success',
      });

    if (this.fileUploader) this.fileUploader.nativeElement.value = '';
    this.isUploadingInProgress = false;
    this._sharedRecoveryCaseService.updateFormValidationState({
      component: Components.AttachmentComponent,
      state: true,
    });
  }

  /**
   * This method is called when manually uploaded image is removed.
   * @param removedImage removed image
   */
  async onUploadedImagesRemoved(removedImage: Image) {
    this._notificationModalService.openModal({
      header: 'Delete image',
      message:
        'The file will be deleted permanently. Are you sure you want to delete the file?',
      primaryButtonMessage: 'Delete',
      secondaryButtonMessage: 'Cancel',
      dimension: 'small',
      onPrimaryBtnClick: async () => {
        const imagesAssignedToCases = this.imagesAssignedToCaseSubject$$.value;
        if (!imagesAssignedToCases || !imagesAssignedToCases.attachments)
          return;
        imagesAssignedToCases.attachments =
          imagesAssignedToCases?.attachments?.filter(
            (x) => (x.id ?? '') != removedImage.id
          );
        await this.removeImagesForCurrentCase([parseInt(removedImage?.id)]);
        this.imagesAssignedToCaseSubject$$.next(imagesAssignedToCases);
      },
    });
  }
  /**
   * This method will be called when there are no records available for EIR or DERT.
   * Then users will send an email to notify the vendors about image proofs via EIR or DERT record.
   */
  async onSendEmailButtonClicked() {
    //TODO: We have hardcoded the template and to, from email ids, We need to change them once the genuine data is available from EIR.
    const emailAttributes = await lastValueFrom(
      this._emailService
        .emailCaseNumberTypeGet(this.caseNumber, ItemType.VendorEmails)
        .pipe(map((response) => response.data))
    );
    const subject = await this.getVendorEmailSubject({
      to: emailAttributes!.to,
      cc: emailAttributes!.cc,
      from: emailAttributes!.from,
      caseNumber: emailAttributes?.caseNumber ?? '',
      emailType: ItemType.VendorEmails,
      shopCode: emailAttributes?.shopCode,
      workOrderNumber: emailAttributes?.workOrderNumber ?? 0,
    } as SendMailRequest);
    this.openEmailDialog(emailAttributes, subject);
  }

  openEmailDialog(email?: EmailDetailDto, subject?: string, body?: string) {
    this._emailModalService.showEmailDialog({
      header: 'Send Notification to Vendor',
      subHeader:
        'You can send a notification to the vendor for images/files to attach with the case',
      cc: email?.cc?.split(';'),
      to: email?.to?.split(';'),
      from: [email?.from ?? ''],
      subject: subject,
      body: body,
      mode: 'write',
      isCCMandatory: false,
      isSubjectMandatory: true,
      toValidationMessage:
        'We need to who to send this to. Please enter at least one email',
      subjectValidationMessage: 'Please enter the subject',
      onSendButtonClick: async (updatedEmail) => {
        const request = {
          to: updatedEmail.to?.join(';'),
          cc: updatedEmail.cc?.join(';'),
          from: updatedEmail.from?.join(';'),
          body: updatedEmail.body,
          subject: updatedEmail.subject,
          caseNumber: email?.caseNumber ?? '',
          emailType: ItemType.VendorEmails,
          shopCode: email?.shopCode,
          workOrderNumber: email?.workOrderNumber ?? 0,
        } as SendMailRequest;
        await this.sendEmail.call(this, request);
      },
    });
  }

  async getVendorEmailSubject(emailRequest: SendMailRequest) {
    return await firstValueFrom(
      this._emailTemplatePlaceholderService.getPlaceholderPopulatedTemplate(
        VENDOR_EMAIL_SUBJECT,
        emailRequest
      )
    );
  }

  /**
   * This method sends email by calling email API. This method will be called as a callback method
   * when the send email button in email popup is clicked.
   * @param email email details
   */
  async sendEmail(request: SendMailRequest | undefined) {
    await lastValueFrom(
      this._emailService.emailPost(request!, this.apiVersion)
    );
    this._toasterService.showToast({
      message: 'Notification sent successfully!',
      type: 'success',
    });
    this.shouldReloadVendorNotifications$$.next(true);
  }

  prepareTemplateModelsForVendorEmails(savedEmail: SaveMailDataDto) {
    return [
      {
        label: 'Date Sent',
        value: savedEmail.sentOn?.toString(),
        type: 'label',
        width: { size: 25, unit: '%' },
        name: 'dateSent',
        valueType: 'date',
      } as TemplateModel,
      {
        label: 'Addressed To',
        value: savedEmail.to,
        type: 'label',
        width: { size: 30, unit: '%' },
        name: 'addressedTo',
      } as TemplateModel,
      {
        label: 'Work Order',
        value: savedEmail.workOrderNumber || '-',
        type: 'label',
        width: { size: 25, unit: '%' },
        name: 'workOrder',
      } as TemplateModel,
      {
        label: 'Shop Code',
        value: savedEmail.shopCode || '-',
        type: 'label',
        width: { size: 20, unit: '%' },
        name: 'shopCode',
      } as TemplateModel,
    ];
  }

  async viewEmailNotificationClicked(index: number) {
    this.currentViewedEmailNotification = index;
    this.openDetailedEmailModal = true;

    if (this.emailsSentToVendors$$.value) {
      this.emailBody = this._sanitizer.bypassSecurityTrustHtml(
        this.emailsSentToVendors$$.value[index].body ?? ''
      );
    }
  }

  resendEmailNotificationClicked(index: number) {
    const emailNotifications = this.emailsSentToVendors$$.value;
    if (!emailNotifications) return;
    const emailToResend = emailNotifications[index];
    this.openEmailDialog(
      emailToResend,
      emailToResend.subject,
      emailToResend.body
    );
  }
  /**
   * This method calls the API to save the documents (images) to server.
   * @param imagesAssignedToCases latest document object to be saved on the server.
   */
  async saveImagesForCurrentCase(
    imagesAssignedToCases: ClaimReferenceDocumentDto
  ) {
    this.errorMessage = '';
    const savedResponse = await firstValueFrom(
      this._caseDocumentService.customerRecoveryClaimsCaseDocumentPost(
        { claimReferenceDocumentDto: imagesAssignedToCases },
        this.apiVersion
      )
    );

    if (savedResponse.claimReferenceDocuments)
      this.imagesAssignedToCaseSubject$$.next(
        savedResponse.claimReferenceDocuments
      );
  }
  /**
   * This method calls the API to delete the documents provided.
   * @param documentsToRemove document ids to delete
   */
  async removeImagesForCurrentCase(documentsToRemove: number[]) {
    this.errorMessage = '';
    await firstValueFrom(
      this._caseDocumentService.customerRecoveryClaimsCaseDocumentDelete(
        {
          documentIds: documentsToRemove,
        },
        this.apiVersion
      )
    );
  }

  getUTCTime(date: Date | undefined): string {
    if (!date) return '--:--:--';

    return this._sharedDataService.getUTCDateAndTime(date);
  }

  /**
   * Checks if the time difference between the current time and the given time is greater than the specified threshold in hours.
   *
   * @param lastRecordedTime - The Date object representing the last recorded time.
   * @param thresholdHour - The threshold in hours to compare the time difference against.
   * @returns true if the time difference is greater than the threshold, false otherwise.
   */
  isTimeDifferenceGreaterThanThreshold(
    lastRecordedTime: Date | undefined,
    thresholdHour: number
  ): boolean {
    if (!lastRecordedTime) return true;
    const currentTime = new Date();
    const lastRecordedTimeDate = new Date(lastRecordedTime);
    // Calculate the difference in milliseconds between the current time and the last recorded time
    const timeDifferenceInMs =
      currentTime.getTime() - lastRecordedTimeDate.getTime();

    // Convert the time difference from milliseconds to hours
    const timeDifferenceInHours = timeDifferenceInMs / (1000 * 60 * 60);

    this.isTimeDifferenceValidForEirExecution =
      timeDifferenceInHours > thresholdHour;

    // Return true if the time difference in hours is greater than the threshold, false otherwise
    return timeDifferenceInHours > thresholdHour;
  }

  /**
   * Calculates the remaining time in minutes between the current time and the given time
   *
   * @param lastRecordedTime - The Date object representing the last recorded time.
   * @returns String with remaining time in hours, minutes, and seconds if the time difference is less than the threshold,
   *          otherwise null.
   */
  getRemainingMinutes(lastRecordedTime: Date, thresholdHour: number): string {
    const currentTime = new Date();
    const lastRecordedTimeDate = new Date(lastRecordedTime);

    // Calculate the difference in milliseconds between the current time and the last recorded time
    const timeDifferenceInMs =
      currentTime.getTime() - lastRecordedTimeDate.getTime();

    // Convert the time difference from milliseconds to hours
    const timeDifferenceInHours = timeDifferenceInMs / (1000 * 60 * 60);

    // If the time difference is greater than the threshold, return null
    if (timeDifferenceInHours > thresholdHour) {
      return '00h-00m-00s';
    }

    // Calculate remaining time in hours, minutes, and seconds
    const remainingTimeInMs =
      thresholdHour * 60 * 60 * 1000 - timeDifferenceInMs;
    const remainingMinutes = Math.floor(
      (remainingTimeInMs % (1000 * 60 * 60)) / (1000 * 60)
    );

    return remainingMinutes + '';
  }

  private getValidFiles(files: FileList | null | undefined) {
    if (!files) return;

    Array.from(files).some((file) => {
      const extension = file.name
        .toLowerCase()
        .substring(file.name.lastIndexOf('.') + 1);
      this.errorMessage = !this.validFormats.includes(extension)
        ? 'One of the uploaded files has incorrect file-format'
        : ['zip', 'pdf'].includes(extension) && file.size > 5 * 1024 * 1024
          ? 'One of the uploaded zip or pdf files exceeds permitted size of 5MB'
          : !['zip', 'pdf'].includes(extension) && file.size > 2 * 1024 * 1024
            ? 'One of the uploaded images exceeds permitted size of 2MB'
            : '';
      // If the following condition is `true`, loop will be broken and first erroneous file is captured.
      return this.errorMessage !== '';
    });

    // Returning `undefined` if any error occurs.
    return this.errorMessage !== '' ? undefined : files;
  }

  async onDownloadButtonClicked(index: number) {
    if (
      this.emailsSentToVendors$$.value &&
      this.emailsSentToVendors$$.value[index]
    ) {
      const email = this.emailsSentToVendors$$.value[index];
      const emailFileName = email.blobName + '.eml';
      const response = await lastValueFrom(
        this._emailService.emailDownloadCaseNumberStorageTypesTypeFilesNameGet(
          email.caseNumber,
          ItemType.VendorEmails,
          emailFileName
        )
      );
      if (!response) {
        this._toasterService.showToast({
          message:
            this.toastMessages.vendorNotification
              .downloadNotificationErrorNoData,
          type: 'error',
        });
        return;
      }
      if (this._sharedDataService.downloadFile(response, emailFileName)) {
        this._toasterService.showToast({
          message: this.toastMessages.vendorNotification.downloadNotification,
          type: 'success',
        });
      } else {
        this._toasterService.showToast({
          message:
            this.toastMessages.vendorNotification.downloadNotificationError,
          type: 'error',
        });
      }
    }
  }

  ngOnDestroy() {
    this.logger.actionLogger({
      message: ATTACHMENT_LOGGER.EIRSLECT,
      count: this.noOfClickOnEirSelect,
    } as loggerType);

    this.logger.actionLogger({
      message: ATTACHMENT_LOGGER.UPLOAD,
      count: this.noOfManualUpload,
    } as loggerType);

    this.logger.actionLogger({
      message: ATTACHMENT_LOGGER.IMAGESELECT,
      count: this.noOfSelectOnImage,
    } as loggerType);

    this.logger.actionLogger({
      message: ATTACHMENT_LOGGER.IMGAEDESELECT,
      count: this.noOfDeselectOnImage,
    } as loggerType);
  }
}
