<ng-container *ngIf="vendorData$ | async">
  <div *ngIf="vendorWorkOrderLineItems$ | async"></div>
  <app-grid
    [schema]="gridSchema"
    [data]="costData"
    [footerData]="costFooter"
    [footerSchema]="footerSchema"
    [showPanel]="false"
    [showHeader]="true"
    [showFooter]="true"
    [showRowSelector]="false"
    [showPagination]="false"></app-grid>
</ng-container>
