import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  DropDownOption,
  PanelComponent,
  ToasterService,
} from '@maersk-global/angular-shared-library';
import {
  catchError,
  combineLatest,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  switchMap,
  tap,
} from 'rxjs';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { CaseInvoiceDetailPostDto } from '../../../common/models/caseInvoiceDetailPostDto';
import { CollectionOfficeDto } from '../../../common/models/collectionOfficeDto';
import { CustomerRecoveryClaimService } from '../../../common/services/customer-recovery/customer-recovery-claim.service';
import * as toastMessagesTemplate from '../../../common/toast-service-messages';
import { Components } from '../../../common/constants/temporary-constant';
import { SharedDataService } from '../../../shared-data-service';

@Component({
  selector: 'invoice-letter',
  standalone: true,
  imports: [CommonModule, PanelComponent, ReactiveFormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './invoice-letter.component.html',
  styleUrl: './invoice-letter.component.scss',
})
export class InvoiceLetterComponent implements OnInit {
  apiVersion: string = '1.0';
  userId: string = sessionStorage.getItem('userId') || '';
  collectionOfficeCode: string = '';
  closeCase: boolean = false;
  invoiceForm!: FormGroup;
  toastMessages = toastMessagesTemplate.default;
  ngOnInit() {
    this._sharedRecoveryCaseService.updateFormValidationState({
      component: Components.InvoiceLetterComponent,
      state: false,
    });
  }

  customerRecoveryData$ =
    this._sharedRecoveryCaseService.recoveryCaseData$.pipe(
      tap((recoveryData) => {
        this.closeCase =
          recoveryData?.recoveryStatusId === 6 ||
          recoveryData?.recoveryStatusId === 7;
        this.invoiceForm = new FormGroup({
          remarks: new FormControl({ value: '', disabled: this.closeCase }),
          collectionOffice: new FormControl(
            { value: '', disabled: this.closeCase },
            [Validators.required]
          ),
        });
      })
    );

  /**
   *Main observable Collection Office
   */
  collectionOffices$?: Observable<CollectionOfficeDto[] | undefined> =
    this._sharedDataService.commonCollectionOffices$;

  /**
   * observable holding dropdown value of templates
   */
  collectionOfficeDropDown$?: Observable<DropDownOption[] | null> =
    combineLatest([
      this._sharedDataService.commonCollectionOffices$,
      this._sharedDataService.carrierCodes$,
      this._sharedRecoveryCaseService.recoveryCaseData$,
    ]).pipe(
      switchMap(async ([collectionOffices, carrierCodes, recoveryData]) => {
        if (!collectionOffices) return null;

        const offices = collectionOffices.filter((siteOffices) => {
          return (
            siteOffices.coCountryCode == recoveryData?.podCountryCode &&
            siteOffices.coOperator == recoveryData?.operatorCode
          );
        });

        const carrierCode = carrierCodes.find(
          (carrierCode) =>
            carrierCode.gcssCarrierCode == recoveryData?.operatorCode
        );

        if (carrierCode) {
          offices.push(
            ...collectionOffices.filter((siteOffices) => {
              if (!siteOffices) return [];
              return (
                siteOffices.coCountryCode == recoveryData?.podCountryCode &&
                siteOffices.coOperator == carrierCode.operatorCode
              );
            })
          );
        }

        return offices?.map((office) => {
          return {
            label: office.coName,
            value: office.coNumber,
          } as DropDownOption;
        });
      })
    );

  factCode$: Observable<string | null> =
    this._sharedRecoveryCaseService.LiablePartyData$.pipe(
      map((liabilityParty) => {
        if (!liabilityParty) return '-';
        return liabilityParty.customBookingParty?.factCustomerCode ?? '-';
      })
    );

  constructor(
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _toasterService: ToasterService,
    private _customerRecoveryService: CustomerRecoveryClaimService,
    private _sharedDataService: SharedDataService
  ) {}

  onDrpDwnCollectionOfficeSelected(event: any) {
    this.collectionOfficeCode = event.detail.value;
    this._sharedRecoveryCaseService.updateFormValidationState({
      component: Components.InvoiceLetterComponent,
      state: true,
    });
  }

  onCollectionOfficeTextChange(event: Event) {
    if (!((event as InputEvent).target as HTMLInputElement).value)
      this._sharedRecoveryCaseService.updateFormValidationState({
        component: Components.InvoiceLetterComponent,
        state: false,
      });
  }

  async createInvoice() {
    const factCode = await firstValueFrom(this.factCode$);
    const customerRecoveryData = await firstValueFrom(
      this._sharedRecoveryCaseService.recoveryCaseData$
    );
    const invoiceReq = {
      factCode: factCode,
      factReferenceNumber: '',
      remarks: this.invoiceForm?.get('remarks')?.value,
      collectionOfficeCode: this.collectionOfficeCode,
      version: await this.getNewInvoiceVersion(),
      userId: this.userId,
    } as CaseInvoiceDetailPostDto;

    const invoiceDetailsPostResponse = await lastValueFrom(
      this._customerRecoveryService
        .customerRecoveryClaimsCaseIdInvoiceDetailsPost(
          customerRecoveryData?.caseId ?? 0,
          invoiceReq,
          this.apiVersion
        )
        .pipe(
          catchError((error) => {
            this._toasterService.showToast({
              message: this.toastMessages.invoicing.createNewInvoice,
              type: 'error',
            });
            throw error;
          })
        )
    );
    this._sharedRecoveryCaseService.updateFormValidationState({
      component: Components.InvoiceLetterComponent,
      state: false,
    });
    this._toasterService.showToast({
      message: this.toastMessages.invoicing.createNewInvoice,
      type: 'success',
    });
    this._sharedRecoveryCaseService.reloadInvoices();
    this._sharedRecoveryCaseService.updateIssueInvoiceVisibility(false);
    return invoiceDetailsPostResponse;
  }

  async getNewInvoiceVersion() {
    const previousInvoices = await firstValueFrom(
      this._sharedRecoveryCaseService.invoices$
    );
    if (!previousInvoices || previousInvoices.length === 0) return '001';
    previousInvoices.sort(
      (letter1, letter2) =>
        new Date(letter2.createdDate ?? '').getTime() -
        new Date(letter1.createdDate ?? '').getTime()
    );
    const lastInvoice = previousInvoices[0];
    const newInvoice = Number(lastInvoice.version) + 1;
    return ('00' + newInvoice).slice(-3);
  }
}
