import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  map,
  scan,
  shareReplay,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { CaseTypeEnum } from '../../common/constants/temporary-constant';
import { CaseDamageDetailDto } from '../../common/models/caseDamageDetailDto';
import { ContainerMoveDto } from '../../common/models/containerMoveDto';
import { CustomerRecoveryCaseDto } from '../../common/models/customerRecoveryCaseDto';
import { FormValidation } from '../../common/models/formValidation';
import { WorkFlowStage } from '../../common/models/workflowStage';
import { CustomerRecoveryClaimService } from '../../common/services/customer-recovery/customer-recovery-claim.service';
import { ISharedRecoveryCaseService } from '../../shared-recovery-case-service';

@Injectable({
  providedIn: 'root',
})
export class SharedVendorRecoveryCaseService
  implements ISharedRecoveryCaseService
{
  private apiVersion: string = '1.0';
  caseType: CaseTypeEnum | undefined;

  constructor(
    private _route: ActivatedRoute,
    private _customerRecoveryClaimService: CustomerRecoveryClaimService
  ) {}

  private shouldShowLiabilityDetailsSubject$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private tabIndex$$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private reloadDamageDetails$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private currentStageId$$: BehaviorSubject<number> =
    new BehaviorSubject<number>(1);

  private reloadVendorRecoveryData$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private eirImageLastCheckedOn$$: BehaviorSubject<Date | undefined> =
    new BehaviorSubject<Date | undefined>(undefined);

  private formValidity$$: BehaviorSubject<FormValidation | null> =
    new BehaviorSubject<FormValidation | null>(null);

  private containerDetails$$: BehaviorSubject<
    ContainerMoveDto | null | undefined
  > = new BehaviorSubject<ContainerMoveDto | null | undefined>(undefined);

  private recoverableWorkOrderLineItems$$: BehaviorSubject<
    { [key: string]: unknown }[]
  > = new BehaviorSubject<{ [key: string]: unknown }[]>([]);

  vendorRecoveryCaseData$: Observable<CustomerRecoveryCaseDto | undefined> =
    combineLatest([
      this._route.queryParamMap,
      this.reloadVendorRecoveryData$$.asObservable(),
    ]).pipe(
      switchMap(([params]) => {
        const caseNumber = params.get('caseNumber') as string;
        const containerNumber = params.get('containerNumber') as string;
        return this._customerRecoveryClaimService
          .customerRecoveryClaimsCaseNumberGet(caseNumber, containerNumber)
          .pipe(map((response) => response.data?.customerRecoveryCaseDto));
      }),
      tap((recoveryData) => {
        this.resetCaseContext();
        if (!recoveryData) return;
        //set case type as case with WO or with out WO |Used for manual
        // case workflow
        this.updateCaseType(recoveryData);
      }),
      shareReplay(1)
    );

  currentStageId$: Observable<number> = this.currentStageId$$.asObservable();

  vendorRecoveryEirImageLastFetchDate$: Observable<Date | undefined> =
    this.eirImageLastCheckedOn$$.asObservable();

  vendorRecoveryContainerMovesDetails$: Observable<
    ContainerMoveDto | null | undefined
  > = this.containerDetails$$.asObservable();

  currentVendorRecoveryTabIndex$: Observable<number | 0> =
    this.tabIndex$$.asObservable();

  vendorRecoverableWorkOrderLineItem$: Observable<
    { [key: string]: unknown }[]
  > = this.recoverableWorkOrderLineItems$$.asObservable();

  enableCloseBtn$: Observable<boolean> = combineLatest([
    this.vendorRecoveryCaseData$,
    this.currentStageId$,
  ]).pipe(
    map(
      ([recoveryData, stageId]) =>
        !(
          recoveryData?.recoveryStatusId == 6 ||
          recoveryData?.recoveryStatusId == 7 ||
          stageId == 5
        )
    )
  );

  reopenCase$: Observable<boolean> = this.vendorRecoveryCaseData$.pipe(
    map((recoveryData) => {
      return (
        recoveryData?.recoveryStatusId == 6 ||
        recoveryData?.recoveryStatusId == 7
      );
    })
  );

  disableForm$: Observable<boolean> = combineLatest([
    this.vendorRecoveryCaseData$,
    this.currentStageId$,
  ]).pipe(
    map(([recoveryData]) => {
      return (
        recoveryData?.recoveryStatusId == 6 ||
        recoveryData?.recoveryStatusId == 7
      );
    })
  );

  /**
   * We are calculating the accumulated state of the form validity. For e.g. If out of 2 forms 1 is valid and 1 is invalid,
   * the accumulated state would be invalid.
   */
  private collectiveFormValidation$ = this.formValidity$$.asObservable().pipe(
    withLatestFrom(this.currentStageId$, this.vendorRecoveryCaseData$),
    map(([formValidation, currentStageId, vendorRecoveryData]) =>
      formValidation
        ? {
            ...formValidation,
            stage: currentStageId,
            caseId: vendorRecoveryData?.caseId,
          }
        : null
    ),
    scan((formValidations, currentState) => {
      if (!currentState) return [];
      const matchingState = formValidations.find(
        (state) =>
          state.component === currentState.component &&
          state.stage === currentState.stage &&
          state.caseId === currentState.caseId
      );
      if (matchingState) matchingState.state = currentState.state;
      else formValidations.push(currentState);
      return formValidations;
    }, [] as FormValidation[])
  );

  private formValidityForCurrentStage$ = combineLatest([
    this.currentStageId$,
    this.collectiveFormValidation$,
    this.vendorRecoveryCaseData$,
  ]).pipe(
    map(([currentStageId, formValidations, vendorRecoveryData]) =>
      formValidations
        .filter(
          (validations) =>
            validations.stage === currentStageId &&
            validations.caseId === vendorRecoveryData?.caseId
        )
        .every((validation) => validation.state)
    )
  );

  enableNextBtn$: Observable<boolean> = combineLatest([
    this.vendorRecoveryCaseData$,
    this.currentStageId$,
    this.formValidityForCurrentStage$,
  ]).pipe(
    map(([recoveryData, stageId, formValid]) => {
      let enableFlag: boolean = true;
      if (
        recoveryData?.recoveryStatusId === 6 ||
        recoveryData?.recoveryStatusId === 7
      )
        enableFlag = enableFlag ? false : true;
      if (stageId === 5) enableFlag = false;
      return enableFlag && formValid;
    })
  );

  hideNextBtn$: Observable<boolean> = combineLatest([
    this.currentStageId$,
  ]).pipe(
    map(([stageId]) => {
      let hiddenFlag: boolean = false;
      if (stageId === 4) hiddenFlag = true;
      return hiddenFlag;
    })
  );

  vendorRecoveryDamageDetails$: Observable<CaseDamageDetailDto[] | undefined> =
    combineLatest([
      this.vendorRecoveryCaseData$,
      this.reloadDamageDetails$$.asObservable(),
    ]).pipe(
      switchMap(([vendorRecoveryData]) => {
        return this._customerRecoveryClaimService
          .customerRecoveryClaimsDamageDetailCaseIdGet(
            vendorRecoveryData?.caseId ?? 0,
            this.apiVersion
          )
          .pipe(map((response) => response.data));
      }),
      shareReplay(1)
    );

  resetCaseContext() {
    this.currentStageId$$.next(1);
    this.updateContainerDetails(undefined);
  }

  updateFormValidationState(state: FormValidation) {
    this.formValidity$$.next(state);
  }

  reloadDamageDetails(): void {
    this.reloadDamageDetails$$.next(true);
  }

  updateLiabilityDetailsVisibility(value: boolean) {
    this.shouldShowLiabilityDetailsSubject$$.next(value);
  }

  updateEirImageLastFetchOn(date: Date | undefined) {
    this.eirImageLastCheckedOn$$.next(date);
  }

  reloadRecoveryCaseData(): void {
    this.reloadVendorRecoveryData$$.next(true);
  }

  updateCurrentStageId(stageId: number): void {
    this.currentStageId$$.next(stageId);
  }

  updateTabSelected(tabIndex: number) {
    this.tabIndex$$.next(tabIndex);
  }

  updateContainerDetails(
    containerDetails: ContainerMoveDto | null | undefined
  ) {
    this.containerDetails$$.next(containerDetails);
  }

  updateRecoverableWorkOrderLineItems(lineItems: { [key: string]: unknown }[]) {
    this.recoverableWorkOrderLineItems$$.next(lineItems);
  }

  updateCaseType(vendorRecoveryCase: CustomerRecoveryCaseDto) {
    if (vendorRecoveryCase.createdBy) {
      this.caseType =
        !vendorRecoveryCase.workOrderNumber &&
        !['sys', 'system'].includes(
          vendorRecoveryCase.createdBy.toLocaleLowerCase()
        )
          ? CaseTypeEnum.CaseWithOutWorkOrder
          : CaseTypeEnum.CaseWithWorkOrder;
    } else {
      this.caseType = CaseTypeEnum.CaseWithWorkOrder;
    }
  }

  getWorkFlowStageById(claimId: number): WorkFlowStage {
    let stageName = WorkFlowStage.New;
    switch (claimId) {
      case 1:
        stageName = WorkFlowStage.New;
        break;
      case 2:
        stageName = WorkFlowStage.DamageEstimation;
        break;
      case 3:
        stageName = WorkFlowStage.Liability;
        break;
      case 4:
        stageName = WorkFlowStage.Invoice;
        break;
      case 5:
        stageName = WorkFlowStage.Complete;
    }
    return stageName as WorkFlowStage;
  }

  //Extra functions added as required by interface
  reloadLiabilityPartyDetails() {}
  reloadLiabilityLetters() {}
  reloadInvoices() {}
  updateIssueInvoiceVisibility() {}
  updateCustomerData() {}
}
