<div class="customer-recovery">
  <div class="container">
    <div class="survey" *ngIf="false">
      <app-survey
        [emailId]="emailID"
        [feedbackId]="feedBackId"
        [message]="'Survey ends on 11th October 2024'"></app-survey>
    </div>
    <div class="header">
      <span class="page-title">
        <h3>Customer Recovery Cases</h3>
        <p></p>
      </span>
      <span>
        <mc-button type="button" (click)="IsNewCaseModalVisible = true">
          New Case
        </mc-button>
      </span>
    </div>

    <mc-tab-bar
      (tabchange)="onCaseTabChange($event)"
      [currentindex]="tabIndex$ | async">
      <!-- tab 0: -->
      <mc-tab slot="tab" label="All cases"></mc-tab>
      <div class="tab-panel" slot="panel">
        <mc-button
          label="Assign to me"
          variant="filled"
          appearance="neutral"
          fit="small"
          padding="default"
          [ngStyle]="{
            display: !(selectedRowsOfGrid && selectedRowsOfGrid.length > 0)
              ? 'none'
              : ''
          }"
          (click)="onAssignCasesToMeClicked()"></mc-button>
      </div>
      <!-- tab 1: -->
      <mc-tab slot="tab" label="My cases"></mc-tab>
      <div class="tab-panel" slot="panel">
        <mc-button
          label="{{
            selectedRowsOfGrid && selectedRowsOfGrid.length > 1
              ? 'Unassign cases'
              : 'Unassign case'
          }}"
          variant="filled"
          appearance="neutral"
          fit="small"
          padding="default"
          [ngStyle]="{
            display: !(selectedRowsOfGrid && selectedRowsOfGrid.length > 0)
              ? 'none'
              : ''
          }"
          (click)="onUnassignCasesClicked()"></mc-button>
      </div>
      <!-- tab 2: -->
      <mc-tab slot="tab" label="My completed"></mc-tab>
      <div class="tab-panel" slot="panel"></div>
    </mc-tab-bar>

    <ng-container>
      <app-grid
        [schema]="gridSchema$ | async"
        [data]="gridData$ | async"
        [showPanel]="true"
        [showRowSelector]="showRowSelector"
        [showLoader]="showLoader"
        [showPagination]="true"
        [totalRecords]="totalRecords$ | async"
        [height]="height"
        [gridFilter]="gridFilter"
        [showPagination]="true"
        [enableSorting]="true"
        (gridFilterChange)="onGridFilterChanged($event)"
        (rowSelectionChange)="onGridRowSelected($event)"
        (schemaChange)="onGridSchemaChanged($event)"></app-grid>
    </ng-container>

    <div *ngIf="IsStatusOrCommentModalVisible">
      <dcrp-status-modal
        (updatedData)="updateStatusAndCommentData($event)"
        (onModalCancel)="IsStatusOrCommentModalVisible = false"
        [statusDetails]="statusDetails"></dcrp-status-modal>
    </div>

    <div *ngIf="IsNewCaseModalVisible">
      <create-manual-case
        (onModalCancel)="IsNewCaseModalVisible = false"></create-manual-case>
    </div>
  </div>
</div>
