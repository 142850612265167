import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  ALIGN,
  GridColumnSchema,
  GridComponent,
  GridRowData,
} from '@maersk-global/angular-shared-library';
import { FooterRowData } from '@maersk-global/angular-shared-library/lib/models/footer-row-data';
import { FooterSchema } from '@maersk-global/angular-shared-library/lib/models/footer-schema';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { VendorRecoveryOrchestratorComponent } from '../../vendor-recovery/vendor-recovery-orchestrator/vendor-recovery-orchestrator.component';
import { SharedVendorRecoveryCaseService } from '../../vendor-recovery/shared-vendor-recovery-case.service';
import { Observable, tap } from 'rxjs';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';

@Component({
  selector: 'vendor-recoverable-cost',
  standalone: true,
  imports: [CommonModule, GridComponent],
  templateUrl: './vendor-recoverable-cost.component.html',
  styleUrl: './vendor-recoverable-cost.component.scss',
})
export class VendorRecoverableCostComponent {
  @Input({ required: true }) item!: TemplateModel;
  items: TemplateModel[] = [];
  costData: GridRowData[] = [];
  gridSchema: GridColumnSchema[] = [];
  costFooter: FooterRowData | null = null;
  footerSchema: FooterSchema[] = [];
  currencyCode = 'USD';
  vendorData?: CustomerRecoveryCaseDto;
  exchangeRate = 1.0;
  constructor(
    private _sharedVendorCaseService: SharedVendorRecoveryCaseService,
    private _sharedRecoveryCaseService: SharedRecoveryCaseService
  ) {}

  vendorData$ = this._sharedRecoveryCaseService.recoveryCaseData$.pipe(
    tap((vendorData) => {
      this.vendorData = vendorData;
      //Fetching currency and exchange rate
      if (vendorData?.caseCurrency && vendorData?.exchangeRateUSDCaseCurrency) {
        this.currencyCode = vendorData.caseCurrency as string;
        this.exchangeRate = vendorData.exchangeRateUSDCaseCurrency as number;
      }
      //Binding schema
      this.bindSchema();
      this.bindGridData();
    })
  );

  vendorWorkOrderLineItems$ =
    this._sharedVendorCaseService.vendorRecoverableWorkOrderLineItem$.pipe(
      tap((res) => console.log(res))
    );

  bindSchema() {
    //Binding grid schema
    if (this.item && this.item.items && this.item.items[0].items) {
      this.gridSchema = this.item.items[0].items.map((y: TemplateModel) => {
        const column = {
          column: y.name,
          displayName: y.label?.replace('Local', this.currencyCode),
          align: y.valueType === 'numeric' ? ALIGN.RIGHT : ALIGN.LEFT,
          hidden: y.name === 'costLocal' && this.currencyCode === 'USD',
          sequence: y.sequence,
          columnType: y.valueType?.toUpperCase(),
        } as GridColumnSchema;
        return column;
      });
    }
    //Binding footer schema
    if (this.item && this.item.items && this.item.items[1].items) {
      this.footerSchema = this.item.items[1].items.map((y: TemplateModel) => {
        const column = {
          column: y.name,
          align: y.valueType === 'numeric' ? ALIGN.RIGHT : ALIGN.LEFT,
          hidden: y.name === 'costLocal' && this.currencyCode === 'USD',
          sequence: y.sequence,
          columnType: y.valueType?.toUpperCase(),
          colSpan: y.colspan,
        } as FooterSchema;
        return column;
      });
    }
  }
  bindGridData() {
    // Calculate costs for all work orders
    //const cost = this.calculateCost(this.workOrders);

    // Filter items which are required for grid data and footer data
    this.items = this.item.items?.filter((o) => o.sequence) as TemplateModel[];

    // Iterate through items and update values
    this.items.forEach((item: TemplateModel) => {
      switch (item.name) {
        case 'woCostUSD':
          item.value = 0;
          break;
        case 'withinCoverageCostUSD':
          item.value = 0;
          break;
        case 'recoverableCostUSD':
          item.value = 0;
          break;
        case 'aboveCoverageCostUSD':
          const aboveCoverageCost = 0;
          item.value = aboveCoverageCost;
          break;
        case 'handlingDamageCostUSD':
          item.value = 0;
          break;
        default:
          break;
      }
    });

    // Populate grid data excluding aboveCoverageCostUSD
    this.costData = this.items
      .filter((o) => o.name !== 'aboveCoverageCostUSD')
      .map((item: TemplateModel) => ({
        row: {
          costType: { value: item.label },
          costLocal: {
            value: this.formatDecimal(item.value / this.exchangeRate),
          },
          costUSD: { value: item.value },
        },
        isMasterRow: false,
        showChildRowData: false,
      })) as GridRowData[];

    // Populate footer data for aboveCoverageCostUSD
    const aboveCoverageItem = this.items.find(
      (o) => o.name === 'aboveCoverageCostUSD'
    );
    if (aboveCoverageItem) {
      this.costFooter = {
        row: {
          costType: { value: aboveCoverageItem.label },
          costLocal: {
            value: this.formatDecimal(
              aboveCoverageItem.value / this.exchangeRate
            ),
          },
          costUSD: { value: aboveCoverageItem.value },
        },
      } as FooterRowData;
    }
  }
  private formatDecimal(value: number): number {
    return Math.round(value * 100) / 100;
  }
}
