/**
 * CC DCRP API
 * Connected Claims Damaged Container Recovery API's.
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ContainerProtectDtoIListResponse } from '../../models/containerProtectDtoIListResponse';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ContainerProtectService {
  protected basePath = environment.dcrp_api_base_url;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {}

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Gets the container protection details for a given booking number and container number.
   *
   * @param bookingNumber The booking number.
   * @param containerNumber The container number.
   * @param operatorCode
   * @param countryCode
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public containerProtectContainersContainerNumberGet(
    containerNumber: string,
    operatorCode: string,
    countryCode: string,
    bookingNumber?: string,
    bolNumber?: string,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ContainerProtectDtoIListResponse>;
  public containerProtectContainersContainerNumberGet(
    containerNumber: string,
    operatorCode: string,
    countryCode: string,
    bookingNumber?: string,
    bolNumber?: string,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ContainerProtectDtoIListResponse>>;
  public containerProtectContainersContainerNumberGet(
    containerNumber: string,
    operatorCode: string,
    countryCode: string,
    bookingNumber?: string,
    bolNumber?: string,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ContainerProtectDtoIListResponse>>;
  public containerProtectContainersContainerNumberGet(
    containerNumber: string,
    operatorCode: string,
    countryCode: string,
    bookingNumber?: string,
    bolNumber?: string,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (containerNumber === null || containerNumber === undefined) {
      throw new Error(
        'Required parameter containerNumber was null or undefined when calling containerProtectContainersContainerNumberGet.'
      );
    }

    if (operatorCode === null || operatorCode === undefined) {
      throw new Error(
        'Required parameter operatorCode was null or undefined when calling containerProtectContainersContainerNumberGet.'
      );
    }

    if (countryCode === null || countryCode === undefined) {
      throw new Error(
        'Required parameter countryCode was null or undefined when calling containerProtectContainersContainerNumberGet.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (operatorCode !== undefined && operatorCode !== null) {
      queryParameters = queryParameters.set('OperatorCode', <any>operatorCode);
    }
    if (countryCode !== undefined && countryCode !== null) {
      queryParameters = queryParameters.set('CountryCode', <any>countryCode);
    }
    if (bookingNumber !== undefined && bookingNumber !== null) {
      queryParameters = queryParameters.set(
        'BookingNumber',
        <any>bookingNumber
      );
    }
    if (bolNumber !== undefined && bolNumber !== null) {
      queryParameters = queryParameters.set('BolNumber', <any>bolNumber);
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ContainerProtectDtoIListResponse>(
      'get',
      `${this.basePath}/container-protect/containers/${encodeURIComponent(String(containerNumber))}`,
      {
        params: queryParameters,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
