import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  CustomFormValidators,
  Image,
  ImageListerComponent,
  LibFormComponent,
  PanelComponent,
  TemplateModel,
  ToasterService,
} from '@maersk-global/angular-shared-library';
import { CustomerRecoveryClaimService } from '../../../common/services/customer-recovery/customer-recovery-claim.service';
import {
  Observable,
  catchError,
  firstValueFrom,
  lastValueFrom,
  map,
  tap,
} from 'rxjs';
import { LiabilityLetterDto } from '../../../common/models/liabilityLetterDto';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { LiabilityLetterStatus } from '../../../common/models/liabilityLetterStatus';
import { SendMailRequest } from '../../../common/models/sendMailRequest';
import { EmailService } from '../../../common/services/email/email.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ItemType } from '../../../common/models/itemType';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { InvoicingStatus } from '../../../common/models/invoicingStatus';
import toastServiceMessages from '../../../common/toast-service-messages';
import { Components } from '../../../common/constants/temporary-constant';
import { SharedDataService } from '../../../shared-data-service';

type LiabilityLetterTemplateExtended = { template: Array<TemplateModel> } & {
  letter: LiabilityLetterDto;
  showResendEmailPanel: boolean;
  showViewEmailPanel: boolean;
};

@Component({
  selector: 'liability-letters-log',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PanelComponent,
    ImageListerComponent,
    LibFormComponent,
  ],
  templateUrl: './liability-letters-log.component.html',
  styleUrl: './liability-letters-log.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [EmailService],
})
export class LiabilityLettersLogComponent {
  loggedUserEmail: string = sessionStorage.getItem('email') ?? '';
  userId: string = sessionStorage.getItem('userId') || '';
  apiVersion: string = '1.0';
  showLiabilityLetterResponseCapturingModal: boolean = false;
  selectedReason: string = '';
  selectedResponse: string = 'ACCEPTED';
  selectedLetterId: number = 0;
  recoveryCaseId: number = 0;
  resendLiabilityLetterForm?: FormGroup;
  images?: Image[];
  emailBody?: SafeHtml;
  enableIssueNewLetterButton?: boolean;
  currencyCode = 'USD';
  closedCase: boolean = false;
  toastMessages = toastServiceMessages;

  caseDetail!: CustomerRecoveryCaseDto;
  customerRecoveryData$: Observable<CustomerRecoveryCaseDto | undefined> =
    this._sharedRecoveryCaseService.recoveryCaseData$.pipe(
      tap((recoveryData) => {
        this.caseDetail = recoveryData!;
        this.closedCase =
          recoveryData?.recoveryStatusId === 6 ||
          recoveryData?.recoveryStatusId === 7;
        this.currencyCode = this.caseDetail['caseCurrency'] as string;
      })
    );

  liabilityLetters$: Observable<
    Array<LiabilityLetterTemplateExtended> | undefined
  > = this._sharedRecoveryCaseService.liabilityLetters$.pipe(
    tap((liabilityLetters) => {
      if (liabilityLetters && liabilityLetters.length > 0)
        this.recoveryCaseId = liabilityLetters[0].recoveryCaseId ?? 0;
    }),
    map((liabilityLetters) =>
      liabilityLetters
        ?.sort(
          (letter1, letter2) =>
            new Date(letter2.createdDate ?? '').getTime() -
            new Date(letter1.createdDate ?? '').getTime()
        )
        .map((liabilityLetter) => {
          return {
            template:
              this.prepareTemplateModelsForLiabilityLetter(liabilityLetter),
            letter: liabilityLetter,
            showResendEmailPanel: false,
            showViewEmailPanel: false,
          } as LiabilityLetterTemplateExtended;
        })
    ),
    tap((extendedLetters) => {
      if (extendedLetters && extendedLetters.length > 0) {
        this.enableIssueNewLetterButton =
          extendedLetters[0].letter.liabilityLetterStatus ===
          LiabilityLetterStatus.REJECTED
            ? true
            : false;
      } else {
        this.enableIssueNewLetterButton = false;
      }
    })
  );

  caseInvoiceStatus$: Observable<InvoicingStatus & { displayMessage: string }> =
    this._sharedRecoveryCaseService.invoiceCreationPermission$.pipe(
      tap((invoiceStatus) => {
        this._sharedRecoveryCaseService.updateFormValidationState({
          component: Components.LiabilityLettersLogComponent,
          state: invoiceStatus.isReadyToInvoice ?? false,
        });
      })
    );

  constructor(
    private _customerRecoveryClaimService: CustomerRecoveryClaimService,
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _toasterService: ToasterService,
    private _emailService: EmailService,
    private _sanitizer: DomSanitizer,
    private _route: ActivatedRoute,
    private _sharedDataService: SharedDataService
  ) {}

  prepareTemplateModelsForLiabilityLetter(letter: LiabilityLetterDto) {
    return [
      {
        label: 'Created By',
        value: letter.createdBy,
        type: 'label',
        width: { size: 12, unit: '%' },
        name: 'createdBy',
      } as TemplateModel,
      {
        label: 'Date Sent',
        value: letter.createdDate,
        type: 'label',
        width: { size: 19, unit: '%' },
        name: 'dateSent',
        valueType: 'date',
      } as TemplateModel,
      {
        label: 'Addressed To',
        value: letter.to,
        type: 'label',
        width: { size: 28, unit: '%' },
        name: 'addressedTo',
      } as TemplateModel,
      {
        label: 'Liable Party',
        value: letter.liabilityPartyName,
        type: 'label',
        width: { size: 24, unit: '%' },
        name: 'liableParty',
      } as TemplateModel,
      {
        label: `Liability Amount (${this.currencyCode})`,
        value: letter.liabilityAmount,
        type: 'label',
        width: { size: 17, unit: '%' },
        name: 'liabilityAmount',
      } as TemplateModel,
      {
        label: 'Comment',
        value: letter.liabilityLetterResponseReason,
        type: 'label',
        width: { size: 100, unit: '%' },
        hidden: !letter.liabilityLetterResponseReason,
        name: 'comment',
        disableValueTextOverflow: true,
      } as TemplateModel,
    ];
  }

  getLiabilityStatusEnumText(value: string) {
    return value === 'ACCEPTED'
      ? 'Accepted'
      : value === 'REJECTED'
        ? 'Rejected'
        : value === 'NO_RESPONSE'
          ? 'No Response'
          : value === 'RESENT'
            ? 'Resent'
            : '-';
  }

  issueNewLetterClicked() {
    this._sharedRecoveryCaseService.updateFormValidationState({
      component: Components.LiabilityLettersLogComponent,
      state: true,
    });
    this._sharedRecoveryCaseService.updateLiabilityDetailsVisibility(true);
  }

  onCaptureResponseButtonClicked(letterId: number) {
    this.selectedResponse = 'ACCEPTED';
    this.selectedLetterId = letterId;
    this.showLiabilityLetterResponseCapturingModal = true;
  }

  async onDownloadButtonClicked(
    letterExtended: LiabilityLetterTemplateExtended
  ) {
    const emailBlobPath = letterExtended.letter.emailBlobPath;
    if (!emailBlobPath) return;
    const fileName = emailBlobPath!
      .substring(emailBlobPath!.lastIndexOf('/') + 1)
      .split('?')[0];
    const response = await lastValueFrom(
      this._emailService.emailDownloadCaseNumberStorageTypesTypeFilesNameGet(
        this.caseDetail!.recoveryCaseNumber!,
        ItemType.LiabilityLetter,
        fileName
      )
    );
    if (!response) {
      this._toasterService.showToast({
        message: this.toastMessages.liability.downloadLetterErrorNoData,
        type: 'error',
      });
      return;
    }
    if (this._sharedDataService.downloadFile(response, fileName)) {
      this._toasterService.showToast({
        message: this.toastMessages.liability.downloadLetter,
        type: 'success',
      });
    } else {
      this._toasterService.showToast({
        message: this.toastMessages.liability.downloadLetterError,
        type: 'error',
      });
    }
  }

  responseStatusChanged(event: Event) {
    this.selectedResponse = (event as CustomEvent).detail;
  }

  responseReasonChanged(event: Event) {
    this.selectedReason = (
      (event as InputEvent).target as HTMLInputElement
    ).value;
  }

  viewLetterOptionSelected(letterExtended: LiabilityLetterTemplateExtended) {
    letterExtended.showResendEmailPanel = false;
    letterExtended.showViewEmailPanel = true;
    this.images = letterExtended.letter.emailAttachment?.fileInfo?.map(
      (fileInfo, index) => {
        return {
          id: `liability_attachment_${index}`,
          label: fileInfo.fileName.substring(
            fileInfo.fileName.indexOf('-') + 1
          ),
          path: fileInfo.fileNameWithPath,
        } as Image;
      }
    );
    this.emailBody = this._sanitizer.bypassSecurityTrustHtml(
      letterExtended.letter.body ?? ''
    );
  }

  resendLetterOptionSelected(
    letterExtended: LiabilityLetterTemplateExtended,
    letterId: number
  ) {
    letterExtended.showViewEmailPanel = false;
    letterExtended.showResendEmailPanel = true;
    this.selectedLetterId = letterId;
    this.createResendEmailForm(letterExtended.letter);
  }

  closeResendAndViewPanels(letterExtended: LiabilityLetterTemplateExtended) {
    letterExtended.showResendEmailPanel = false;
    letterExtended.showViewEmailPanel = false;
    this.resendLiabilityLetterForm = undefined;
    this.images = undefined;
    this.emailBody = undefined;
  }

  private createResendEmailForm(letter: LiabilityLetterDto) {
    this.resendLiabilityLetterForm = new FormGroup({
      to: new FormControl(letter.to, [
        Validators.required,
        CustomFormValidators.multipleEmailValidator,
      ]),
      cc: new FormControl(letter.cc, [
        CustomFormValidators.multipleEmailValidator,
      ]),
      subject: new FormControl(letter.subject, [Validators.required]),
    });
  }

  private async prepareSendEmailRequest(letter: LiabilityLetterDto) {
    const caseNumber = await firstValueFrom(
      this._route.queryParamMap.pipe(
        map((params: ParamMap) => params.get('caseNumber') as string)
      )
    );
    return {
      from: this.loggedUserEmail,
      to: this.resendLiabilityLetterForm?.get('to')?.value,
      cc: this.resendLiabilityLetterForm?.get('cc')?.value,
      subject: this.resendLiabilityLetterForm?.get('subject')?.value,
      body: letter.body,

      caseNumber: caseNumber,
      sendDateTime: new Date(),
      emailType: ItemType.LiabilityLetter,
      liabilityLetter: {
        createdBy: this.userId,
        createdDate: new Date(),
        liabilityAmount: letter.liabilityAmount,
        liabilityPartyName: letter.liabilityPartyName,
        recoveryCaseId: letter.recoveryCaseId,
      },
      emailAttachment: letter.emailAttachment,
    } as SendMailRequest;
  }

  async onResendEmailClicked(letter: LiabilityLetterDto) {
    if (!letter.liabilityLetterStatus)
      await this.sendLetterResponse(LiabilityLetterStatus.RESENT);
    const request = await this.prepareSendEmailRequest(letter);
    await lastValueFrom(
      this._emailService.emailPost(request).pipe(
        catchError((error) => {
          this._toasterService.showToast({
            message: 'Error occurred while re-sending liability letter',
            type: 'error',
          });
          throw error;
        })
      )
    );
    this._toasterService.showToast({
      message: 'Liability letter resent successfully!',
      type: 'success',
    });
    this._sharedRecoveryCaseService.reloadLiabilityLetters();
  }

  async onSaveResponseClicked() {
    await this.sendLetterResponse(
      this.selectedResponse as LiabilityLetterStatus
    );
    this._toasterService.showToast({
      message: 'Liability letter response updated successfully!',
      type: 'success',
    });
    this._sharedRecoveryCaseService.reloadLiabilityLetters();
  }

  async sendLetterResponse(liabilityLetterStatus: LiabilityLetterStatus) {
    await lastValueFrom(
      this._customerRecoveryClaimService
        .customerRecoveryClaimsCaseIdLiabilityLetterLiabilityLetterIdPut(
          this.recoveryCaseId,
          this.selectedLetterId,
          {
            reason: this.selectedReason,
            status: liabilityLetterStatus,
            updatedBy: this.userId,
            updatedDate: new Date(),
          }
        )
        .pipe(
          catchError((error) => {
            this._toasterService.showToast({
              message:
                'Error occurred while updating liability letter response',
              type: 'error',
            });
            throw error;
          })
        )
    );
  }
}
