<div *ngIf="!authorized">
  <p>You are not authorized to perform actions!</p>
</div>
<div [formGroup]="formGroup" *ngIf="authorized">
  <div class="editor-header">
    <div class="dropdown-holder">
      <mc-select
        ngDefaultControl
        formControlName="cluster"
        label="Country Cluster"
        placeholder="Select Cluster"
        [loading]="loadingClusters"
        (optionselected)="clusterSelected($event)">
        @for (clusters of countryClusters; track clusters) {
          <mc-option [value]="clusters.clusterCode">
            {{ clusters.clusterName }}
          </mc-option>
        }
      </mc-select>
      <mc-select
        ngDefaultControl
        formControlName="template"
        label="Letter Template"
        placeholder="Select template"
        [loading]="loadingTemplates"
        (optionselected)="templateSelected($event)">
        @for (templates of emailTemplates; track templates) {
          <mc-option [value]="templates.id">
            {{ templates.templateName }}
          </mc-option>
        }
      </mc-select>
      <mc-button
        ngDefaultControl
        formControlName="renameButton"
        (click)="toggleModal()">
        Rename Template
      </mc-button>
    </div>
    <div class="action-button-section">
      <mc-button
        ngDefaultControl
        formControlName="defaultButton"
        label="Reset"
        appearance="neutral"
        (click)="ResetContentToDefault()"></mc-button>

      <mc-button
        ngDefaultControl
        formControlName="saveButton"
        label="Update Template"
        appearance="primary"
        [loading]="updatingTemplate"
        (click)="SaveTemplateContent()"></mc-button>
    </div>
  </div>
  <div class="editor-switch">
    <mc-switch
      ngDefaultControl
      formControlName="editorMode"
      label="Edit in Rich Text Mode"
      name="editorMode"
      [checked]="useRichTextEditor"
      (change)="changeEditorMode($event)"></mc-switch>
  </div>
  <div class="editor-subject">
    <mc-input
      ngDefaultControl
      formControlName="subject"
      label="Subject"
      placeholder="Email Subject"></mc-input>
  </div>
  <div class="editor">
    <div class="empty-message-placeholder" *ngIf="!selectedTemplate">
      <p>No Template selected</p>
    </div>
    <ng-container *ngIf="!useRichTextEditor">
      <div
        *ngIf="selectedTemplate"
        class="mds-text--medium-normal email-template-container"
        [contentEditable]="true"
        #emailBodyContainer
        [innerHTML]="selectedTemplate.templateContentForDisplay"></div>
    </ng-container>
    <ng-container *ngIf="useRichTextEditor">
      <angular-editor
        *ngIf="selectedTemplate"
        formControlName="htmlContent"
        [config]="editorConfig"></angular-editor>
    </ng-container>
  </div>
</div>

<mc-modal
  [open]="openModal"
  escapecloseactiondisabled
  backdropcloseactiondisabled
  (closed)="onModelClose($event)"
  (closing)="openModal = false">
  <span slot="heading" class="header-body">Rename selected template</span>
  <div>
    <mc-notification
      class="margin-bottom-15"
      *ngIf="showNotification"
      icon="info-circle"
      [appearance]="notificationType">
      {{ notificationMessage }}
    </mc-notification>
    <form [formGroup]="frmTemplateName">
      <mc-label class="margin-bottom-15">
        Current template name:
        <strong>{{ selectedTemplate?.templateName }}</strong>
      </mc-label>
      <mc-input
        class="margin-bottom-15"
        ngDefaultControl
        [invalid]="frmTemplateNameError"
        [errormessage]="frmTemplateNameErrorMessage"
        formControlName="newTemplateName"
        label="New Template Name"
        placeholder="Enter new template name"></mc-input>
    </form>
  </div>
  <mc-button
    slot="primaryAction"
    appearance="primary"
    [loading]="updatingTemplateName"
    (click)="renameTemplate()">
    Save changes
  </mc-button>
  <mc-button
    slot="secondaryAction"
    appearance="neutral"
    variant="outlined"
    (click)="onModelClose($event)">
    Cancel
  </mc-button>
</mc-modal>
