import { CommonModule, DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, Input } from '@angular/core';
import {
  LibFormComponent,
  PanelComponent,
  SectionSkeletonLoaderComponent,
} from '@maersk-global/angular-shared-library';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import {
  firstValueFrom,
  combineLatest,
  lastValueFrom,
  map,
  Observable,
  tap,
  shareReplay,
  catchError,
  of,
} from 'rxjs';
import { DropDownOption } from '@maersk-global/angular-shared-library/lib/models/drop-down';
import { CurrencyAndExchangeRateDto } from '../../../common/models/currencyAndExchangeRateDto';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { CommonService } from '../../../common/services/customer-recovery/common.service';
import {
  CaseTypeEnum,
  Components,
} from '../../../common/constants/temporary-constant';
import { SharedDataService } from '../../../shared-data-service';
import { SharedCustomerRecoveryCaseService } from '../../customer-recovery/shared-customer-recovery-case.service';
const DATE_OF_INCIDENT = 'dateOfIncident';
const POD_LOCAL_CURRENCY: string = 'podLocalCurrency';
const EXCHANGE_RATE: string = 'exchangerate';
const POD_COUNTRY_CODE: string = 'podCountryCode';
const POD_CLUSTER_CODE: string = 'podCountryClusterCode';
const OPERATOR_CODE: string = 'operatorCode';
@Component({
  selector: 'overview',
  standalone: true,
  imports: [
    CommonModule,
    LibFormComponent,
    PanelComponent,
    SectionSkeletonLoaderComponent,
  ],
  templateUrl: './overview.component.html',
  styleUrl: './overview.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [DatePipe],
})
export class OverviewComponent {
  @Input() item!: TemplateModel;
  disable: boolean = false;
  items: TemplateModel[] = [];
  currencyAndExchangeRate!: CurrencyAndExchangeRateDto[];
  caseDetail?: CustomerRecoveryCaseDto;
  apiVersion: string = '1.0';
  ignoreCurrencyUpdate: boolean = false;
  changedIncidentDate: Date | undefined | null;
  hasIncidentDateChanged: boolean = false;

  constructor(
    private _datePipe: DatePipe,
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _commonService: CommonService,
    private _sharedDataService: SharedDataService,
    private _customerRecoverySharedService: SharedCustomerRecoveryCaseService
  ) {}

  customerRecoveryData$ = combineLatest([
    this._sharedRecoveryCaseService.recoveryCaseData$,
    this._customerRecoverySharedService.disableForm$,
  ]).pipe(
    tap(([recoveryData, disable]) => {
      this.caseDetail = recoveryData;
      this.disable = disable;
      this.loadOverviewData();
    })
  );

  loadOverviewData() {
    this.item.loaded = false;
    this.items = this.item.items as TemplateModel[];
    this.items?.forEach(async (overview: TemplateModel) => {
      overview.disabled = this.disable;
      if (
        overview.type == 'select' &&
        overview.name &&
        overview.name !== POD_COUNTRY_CODE
      ) {
        overview.options = await this.getFilterOptionsByColumn(overview.name);
        if (overview.value && overview.name == POD_LOCAL_CURRENCY) {
          this.bindExchangesRateValue(overview.value);
        }
      }
    });
    this.bindOverViewData();
  }

  loadExchangeRateData() {
    this.item.loaded = false;
    this.items = this.item.items as TemplateModel[];
    this.items?.forEach(async (overview: TemplateModel) => {
      overview.disabled = this.disable;
      if (
        overview.type == 'select' &&
        overview.name &&
        overview.name == POD_LOCAL_CURRENCY
      ) {
        overview.options = await this.getFilterOptionsByColumn(overview.name);
        if (overview.value) {
          this.bindExchangesRateValue(overview.value);
        }
      }
    });
    this.bindExchangeData();
  }

  bindOverViewData() {
    if (this.item.loaded) return;
    const keyValueCustomerDetail = this.caseDetail as unknown as {
      [key: string]: unknown;
    };
    this.items.forEach((item: TemplateModel) => {
      if (item.name == POD_LOCAL_CURRENCY) {
        item.value =
          this.caseDetail?.caseCurrency ??
          this.caseDetail?.podLocalCurrency ??
          'USD';
        item.onValueChanged = (value) => {
          this.bindExchangesRateValue.call(this, value);
        };
      } else if (item.name == POD_CLUSTER_CODE) {
        item.value = keyValueCustomerDetail[item.name as string];
        item.onValueChanged = (value) => {
          this.getCountriesBasedOnCluster.call(this, value);
        };
      } else if (item.name == DATE_OF_INCIDENT) {
        if (
          !item.value &&
          !this.hasIncidentDateChanged &&
          keyValueCustomerDetail[item.name]
        ) {
          item.value = keyValueCustomerDetail[item.name] as Date;
        }

        item.onValueChanged = (value) => {
          this.onIncidentDateChange.call(this, value);
        };
      } else {
        item.value = keyValueCustomerDetail[item.name as string];
      }
    });
    this.item.loaded = true;
  }

  bindExchangeData() {
    if (this.item.loaded) return;
    this.items.forEach((item: TemplateModel) => {
      if (item.name == EXCHANGE_RATE) {
        const currency = this.items.filter(
          (o) => o.name == POD_LOCAL_CURRENCY
        )[0].value;
        this.bindExchangesRateValue(currency);
      }
    });
    this.item.loaded = true;
  }

  async getCountriesBasedOnCluster(selectedCluster: string) {
    const countries = await firstValueFrom(this._sharedDataService.countries$);
    let dropDownCountries: DropDownOption[];
    if (selectedCluster) {
      const filteredCountries = countries.filter(
        (i) => i.clusterCode == selectedCluster
      );
      dropDownCountries = filteredCountries.map((country) => ({
        label: country.name,
        value: country.code,
      }));
    }
    this.items?.filter((item: TemplateModel) => {
      if (item.name == POD_COUNTRY_CODE) {
        const ifCountryExistInDropDown = dropDownCountries?.filter(
          (i) => i.value == item.value
        )[0];
        if (!ifCountryExistInDropDown) {
          item.value = null;
        }

        item.options = dropDownCountries ?? [];
      }
    });
  }

  async onIncidentDateChange(value: string) {
    if (
      this._customerRecoverySharedService.caseType ==
      CaseTypeEnum.CaseWithWorkOrder
    )
      return;

    this.ignoreCurrencyUpdate = true;
    this.hasIncidentDateChanged = true;
    if (value?.trim() === '') {
      this._customerRecoverySharedService.updateIncidentDate(null);
      this.changedIncidentDate = null;
    } else if (value) {
      const dt = this._sharedDataService.convertDDMMYYYYIntoYYYYMMDD(value);
      this._customerRecoverySharedService.updateIncidentDate(new Date(dt));
      this.changedIncidentDate = new Date(dt);
      this.loadExchangeRateData();
    }
  }

  bindExchangesRateValue(value: string) {
    //Checking if the dataset is populated
    if (!this.currencyAndExchangeRate) {
      return;
    }

    const exchangeRateObj = this.currencyAndExchangeRate.find(
      (currency) => currency.quotationCurrency == value
    );
    this.items.filter((item: TemplateModel) => {
      if (item.name == EXCHANGE_RATE) {
        const transformDate = exchangeRateObj?.quotationDateTime
          ? this._datePipe.transform(
              new Date(exchangeRateObj?.quotationDateTime),
              'd MMM y'
            )
          : '';
        item.value = `${exchangeRateObj?.exchangeRatePerUnit ?? `-`}  ${transformDate}`;
      }
    });
  }
  /**
   * Get filter options by column name.
   * @param itemName column name
   * @returns
   *
   *  Promise<ClaimStatusResponse>
   */
  private async getFilterOptionsByColumn(
    columnName: string
  ): Promise<DropDownOption[]> {
    let dropdownOptions = [] as DropDownOption[];
    if (columnName == OPERATOR_CODE) {
      dropdownOptions = await this.mapTypeToDropDownOptions(
        this._sharedDataService.carrierCodes$,
        'operatorName',
        'operatorCode'
      );
    } else if (columnName == POD_CLUSTER_CODE) {
      dropdownOptions = await this.mapTypeToDropDownOptions(
        this._sharedDataService.countryClusters$,
        'clusterName',
        'clusterCode'
      );
    } else if (columnName == POD_LOCAL_CURRENCY) {
      let reqDate =
        !!this.caseDetail?.workOrderDate &&
        Date.parse(new Date(this.caseDetail?.workOrderDate).toISOString()) > 0
          ? new Date(this.caseDetail?.workOrderDate).toISOString()
          : !!this.caseDetail?.dateOfIncident &&
              Date.parse(
                new Date(this.caseDetail?.dateOfIncident).toISOString()
              ) > 0
            ? new Date(this.caseDetail?.dateOfIncident).toISOString()
            : new Date(this.caseDetail!.createdDate!).toISOString();

      reqDate = this.changedIncidentDate
        ? new Date(this.changedIncidentDate).toISOString()
        : reqDate;

      const currencyAndExchangeRate$ = this._commonService
        .commonCurrencyAndExchangeRatesForQuotationDateGet(
          reqDate,
          this.apiVersion
        )
        .pipe(
          map(
            (response) =>
              (response?.data
                ? response.data
                : null) as CurrencyAndExchangeRateDto[]
          ),
          tap((data) => {
            this.currencyAndExchangeRate = data;
            this.changedIncidentDate = undefined;
          }),
          shareReplay(1),
          catchError((_) => {
            this.changedIncidentDate = undefined;
            return of();
          })
        );

      dropdownOptions = await this.mapTypeToDropDownOptions(
        currencyAndExchangeRate$,
        'quotationCurrency',
        'quotationCurrency'
      );
    }
    return dropdownOptions;
  }

  async mapTypeToDropDownOptions<T>(
    list$: Observable<T[]>,
    labelProperty: string,
    valueProperty: string
  ) {
    return (await lastValueFrom(
      list$.pipe(
        map((list) =>
          list.map((entry: T) => ({
            label: (entry as { [key: string]: string })[labelProperty],
            value: (entry as { [key: string]: string })[valueProperty],
          }))
        )
      )
    )) as DropDownOption[];
  }

  formValidityChanged(isValid: boolean) {
    this._sharedRecoveryCaseService.updateFormValidationState({
      component: Components.OverviewComponent,
      state: isValid,
    });
  }
}
