import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import {
  ALIGN,
  DropDownOption,
  FooterRowData,
  GridCellData,
  GridColumnSchema,
  GridComponent,
  GridRowData,
  PanelComponent,
  TemplateModel,
} from '@maersk-global/angular-shared-library';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { combineLatest, map, Observable, tap } from 'rxjs';
import { CaseDamageDetailDto } from '../../../common/models/caseDamageDetailDto';
import { FooterSchema } from '@maersk-global/angular-shared-library/lib/models/footer-schema';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { SharedDataService } from '../../../shared-data-service';

@Component({
  selector: 'invoice-summary',
  standalone: true,
  imports: [CommonModule, GridComponent, PanelComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './invoice-summary.component.html',
  styleUrl: './invoice-summary.component.scss',
})
export class InvoiceSummaryComponent {
  @Input({ required: true }) item?: TemplateModel;
  /**
   * invoice summary grid Schema.
   */
  invoiceGridSchema: GridColumnSchema[] = [];
  costFooter: FooterRowData | null = null;
  footerSchema: FooterSchema[] = [];
  constructor(
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _sharedDataService: SharedDataService
  ) {}

  customerRecoveryData$ =
    this._sharedRecoveryCaseService.recoveryCaseData$.pipe(
      tap((recoveryData) => {
        this.onLoad(recoveryData);
      })
    );

  /**
   * We are creating a schema for the Inspection grid.
   * @returns nothing
   */
  onLoad(customerRecoveryCaseDto: CustomerRecoveryCaseDto | undefined): void {
    if (!this.item || !this.item.items || !customerRecoveryCaseDto) return;
    this.invoiceGridSchema = this.item.items.map(
      (columnSchema: TemplateModel) => {
        const column = {
          column: columnSchema.name,
          displayName:
            columnSchema.name == 'amount'
              ? `${columnSchema.label} (${customerRecoveryCaseDto.caseCurrency ?? 'USD'})`
              : columnSchema.label,
          align: columnSchema.name == 'amount' ? ALIGN.RIGHT : ALIGN.LEFT,
          hidden: false,
          sequence: columnSchema.sequence,
          columnType: columnSchema.valueType?.toUpperCase(),
          disableSort: true,
        } as GridColumnSchema;
        return column;
      }
    );
    this.footerSchema = this.item.items.map((columnSchema: TemplateModel) => {
      const column = {
        column: columnSchema.name,
        align: columnSchema.valueType === 'numeric' ? ALIGN.RIGHT : ALIGN.LEFT,
        sequence: columnSchema.sequence,
        columnType: columnSchema.valueType?.toUpperCase(),
      } as FooterSchema;
      return column;
    });
  }

  /**
   * Inspection grid data
   */
  invoiceSummaryGridData$: Observable<GridRowData[] | null> = combineLatest([
    this._sharedRecoveryCaseService.damageDetails$,
    this._sharedDataService.materialCodesAsDropdownOptions$,
  ]).pipe(
    map(([damages, materialCodes]) => {
      if (!damages || damages.length === 0) return [];
      return damages?.map((damage) =>
        this.generateGridDataFromInspection(damage, materialCodes)
      );
    })
  );

  private generateGridDataFromInspection(
    damageDetail: CaseDamageDetailDto,
    materialCodes: DropDownOption[]
  ): GridRowData {
    const damageDetailKeyValue = damageDetail as unknown as {
      [key: string]: unknown;
    };
    const gridRowObject: { [key: string]: GridCellData } = {};
    Object.keys(damageDetail).map((key) => {
      gridRowObject[key] = {
        value:
          key == 'materialCode'
            ? materialCodes.filter(
                (i) => i.value == damageDetailKeyValue[key]
              )[0].label
            : key == 'amount' && damageDetailKeyValue['amountInCaseCurrency']
              ? damageDetailKeyValue['amountInCaseCurrency']
              : damageDetailKeyValue[key],
      } as GridCellData;
    });
    return {
      row: gridRowObject,
    } as GridRowData;
  }
}
