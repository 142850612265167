<lib-panel>
  <header>{{ item?.label }}</header>
  <content>
    <div *ngIf="customerRecoveryData$ | async">
      <div [formGroup]="emailForm">
        <div class="email-container">
          <div class="liability-div">
            <label>Liability Amount ({{ currencyCode }})</label>
            <div class="amt-div">
              {{ totalLiabilityAmount$ | async }}
            </div>
          </div>
          <div>
            <mc-input
              ngDefaultControl
              label="To"
              fit="medium"
              formControlName="to"
              name="to"
              (input)="validateControl()"
              [invalid]="
                emailForm.get('to')?.touched && emailForm.get('to')?.errors
              "></mc-input>
            <mc-error
              *ngIf="
                emailForm.get('to')?.touched &&
                emailForm.get('to')?.errors &&
                emailForm.get('to')?.errors?.['emailInvalid']
              "
              invalid
              errormessage="Invalid Email(s), Email(s) must be semi-colon separated valid email(s)."></mc-error>
            <mc-error
              *ngIf="
                emailForm.get('to')?.touched &&
                emailForm.get('to')?.errors &&
                emailForm.get('to')?.errors?.['required']
              "
              invalid
              errormessage="We need to know who to send this to. Please enter at least one email."></mc-error>
          </div>
          <div>
            <mc-input
              ngDefaultControl
              label="CC (Optional)"
              fit="medium"
              formControlName="cc"
              name="cc"
              (input)="validateControl()"
              placeholder="Enter email"
              [invalid]="
                emailForm.get('cc')?.touched &&
                emailForm.get('cc')?.errors &&
                emailForm.get('cc')?.errors?.['emailInvalid']
              "></mc-input>
            <mc-error
              *ngIf="
                emailForm.get('cc')?.touched &&
                emailForm.get('cc')?.errors &&
                emailForm.get('cc')?.errors?.['emailInvalid']
              "
              invalid
              errormessage="Invalid Email(s), Email(s) must be semi-colon separated valid email(s)."></mc-error>
          </div>
          <div *ngIf="emailForm.get('subject')?.value">
            <mc-input
              ngDefaultControl
              label="Subject"
              fit="medium"
              (input)="validateControl()"
              formControlName="subject"
              name="subject"
              [invalid]="
                emailForm.get('subject')?.touched &&
                emailForm.get('subject')?.errors
              "></mc-input>
            <mc-error
              *ngIf="
                emailForm.get('subject')?.touched &&
                emailForm.get('subject')?.errors &&
                emailForm.get('subject')?.errors?.['required']
              "
              invalid
              errormessage="Please enter the subject"></mc-error>
          </div>
          <ng-container *ngIf="maxEmailSize$ | async as maxEmailSize">
            <lib-panel
              *ngIf="attachments$ | async as images"
              [hasError]="attachmentError">
              <header>Attachments</header>
              <left-corner-menu>
                <span [ngClass]="{ 'custom-error': attachmentWarning }">
                  {{ attachmentSize }}/{{ maxEmailSize }}MB
                </span>
              </left-corner-menu>
              <content>
                <div class="email-content">
                  <div *ngIf="attachmentError">
                    <mc-notification
                      appearance="error"
                      icon="exclamation-octagon"
                      body="Attachments
                          size
                          exceeds
                          the
                          permitted
                          limit
                          of
                          {{ maxEmailSize }}MB
                          Please
                          deselect
                          a
                          few
                          files
                          to
                          proceed
                          closable"></mc-notification>
                  </div>
                  <div>
                    <mc-checkbox
                      fit="small"
                      label="Select All"
                      (change)="selectAllAttachment($event)"></mc-checkbox>
                  </div>

                  <div>
                    <lib-image-lister
                      [images]="images"
                      (imagesSelected)="
                        attachmentSelected($event)
                      "></lib-image-lister>
                  </div>
                </div>
              </content>
            </lib-panel>
          </ng-container>
          <div
            style="width: 360px"
            *ngIf="templateDropDown$ | async as emailTemplates">
            <mc-select
              ngDefaultControl
              formControlName="template"
              label="Letter Template"
              placeholder="Select template"
              (optionselected)="templateSelected($event)">
              @for (templates of emailTemplates; track templates) {
                <mc-option [value]="templates.value">
                  {{ templates.label }}
                </mc-option>
              }
            </mc-select>
          </div>
          <div class="email-content" *ngIf="emailBody">
            <ng-container *ngIf="sendMailError">
              <mc-notification
                appearance="error"
                icon="exclamation-octagon"
                body="Letter could not be sent due to technical issues. Please try again"
                width="600px"
                (close)="closeMailErrorNotification()"
                closable></mc-notification>
            </ng-container>
            <div
              class="mds-text--medium-normal email-template-container"
              #emailBodyContainer
              [innerHTML]="emailBody"></div>
          </div>
        </div>
      </div>
    </div>
  </content>
</lib-panel>
