import { Injectable } from '@angular/core';
import {
  Subject,
  Subscription,
  debounceTime,
  fromEvent,
  merge,
  takeUntil,
  timer,
} from 'rxjs';
import { AuthHelper } from '../../../helper/auth-helper';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment';
const TIMEOUT_DURATION: number = environment.sessionTimeoutTime;

@Injectable({
  providedIn: 'root',
})
export class SessionTimeoutService {
  private _authHelper: AuthHelper = new AuthHelper(this._cookieService);
  private destroy$: Subject<void> = new Subject<void>();
  constructor(private _cookieService: CookieService) {
    this.resetTimeout();
  }

  resetTimeout() {
    timer(TIMEOUT_DURATION)
      .pipe(takeUntil(this.destroy$))
      .subscribe((o) => {
        this._authHelper.logout();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
